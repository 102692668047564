import React from 'react';
import styled from 'styled-components';
import LoadingSpiner from 'ui/atoms/LoadingSpinner/LoadingSpiner';

export type LoadingAnimationWrapperProps = {
    fullpage?: boolean;
    loading: boolean;
    children: React.ReactNode;
};

const LoadingAnimationWrapper = ({ loading, children, fullpage }: LoadingAnimationWrapperProps): JSX.Element => {
    if (!loading) return <>{children}</>;
    if (fullpage)
        return (
            <Fullpage>
                <Div>
                    <LoadingSpiner />
                </Div>
            </Fullpage>
        );
    return (
        <Div>
            <LoadingSpiner />
        </Div>
    );
};

export default LoadingAnimationWrapper;

const Div = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Fullpage = styled.div`
    display: flex;
    min-height: 100vh;
`;
