import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useAuth } from 'contexts/AuthContext';

import { useHistory } from 'react-router-dom';
import { Title } from 'ui/atoms/Title';
import { Button } from 'ui/atoms/Button';
import { TextConstant } from '../../../config/text-constant';

const Home: React.FC = () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const [showSowing, setShowSowing] = useState<boolean>(false);
    const [showPickup, setShowPickup] = useState<boolean>(false);

    return (
        <HomeWrapper>
            <ImageContainer>
                <Logo src={process.env.PUBLIC_URL + '/ztocka.jpg'} />
            </ImageContainer>
            <Title text="Dobrodošli" subtext="v applikaciji BlockIS"></Title>
            {currentUser.userType === 'PRIDELOVALEC' && (
                <>
                    <Text>
                        <NavigationButton className="sowing-button" onClick={() => history.push('/sowing')}>
                            <big>{TextConstant.CREATE_SOWING}</big>
                            <br></br>
                            <small>{TextConstant.CREATE_SOWING_DESCRIPTION}</small>
                        </NavigationButton>
                    </Text>
                    <Text>
                        <NavigationButton className="sowing-button" onClick={() => history.push('/sowingupdate')}>
                            <big>{TextConstant.ADD_SOWING_ACTIVITY}</big>
                            <br></br>
                            <small>{TextConstant.ADD_SOWING_ACTIVITY_DESC}</small>
                        </NavigationButton>
                    </Text>
                    <Text>
                        <NavigationButton className="harvest-button" onClick={() => history.push('/harvest')}>
                            <big>Spravilo</big>
                            <br></br>
                            <small>
                                Vnos aktualnega spravila pridelka, spravilo je možno samo za aktivne pridelke.
                            </small>
                        </NavigationButton>
                    </Text>
                    <Text>
                        <NavigationButton className="pickup-button" onClick={() => history.push('/pickup/create')}>
                            <big>Kreiraj dobavnico</big>
                            <br></br>
                            <small>Vnos podatkov za kreiranje dobavnice iz obstoječih spravil.</small>
                        </NavigationButton>
                    </Text>
                    <Text>
                        <NavigationButton className="pickup-button" onClick={() => history.push('/pickup/get')}>
                            <big>Pripravljene dobavnice</big>
                            <br></br>
                            <small>Pregled vseh pripravljenih dobavnic za prevzem.</small>
                        </NavigationButton>
                    </Text>
                    <Title text="Zgodovina" />
                    <Text>
                        <NavigationButton className="sowing-button" onClick={() => history.push('/sowingfinished')}>
                            <big>{TextConstant.COMPLETED_SOWINGS}</big>
                            <br></br>
                            <small>{TextConstant.COMPLETED_SOWING_DESCRIPTION}</small>
                        </NavigationButton>
                    </Text>
                    <Text>
                        <NavigationButton className="pickup-button" onClick={() => history.push('/pickup/overview')}>
                            <big>Izvedene dobavnice</big>
                            <br></br>
                            <small>Pregled zaključenih dobavnic (podatki od prevzema).</small>
                        </NavigationButton>
                    </Text>
                </>
            )}
        </HomeWrapper>
    );
};

export default Home;

const NavigationButton = styled.button`
    height: 50px;
    width: 17rem;
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    margin: 0px 5px 0px 0px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.1rem 0.5rem;
    line-height: 1;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-transform: none;

    &:hover:enabled {
        background-color: #2a1ab9;
        border-color: #2819ae;
    }

    &:active {
        background-color: #2819ae;
        border-color: #2517a3;
    }

    &:focus {
        background-color: #2a1ab9;
        border-color: #2819ae;
        box-shadow: 0 0 0 0.2rem rgb(81 65 224 / 50%);
    }

    &:disabled {
        opacity: 0.65;
    }

    &.sowing-button {
        background-color: #6555ed;
    }

    &.harvest-button {
        background-color: #442fff;
    }

    &.pickup-button {
        background-color: #10009b;
    }
`;

const Text = styled.div`
    display: flex;
    justify-content: center;
    font-size: 12pt;
    font-weight: 600;
    padding: 0 0 10px 0;
    @media screen and (max-width: 425px) {
        font-size: 8pt;
    }
    @media screen and (max-width: 768px) {
        font-size: 10pt;
    }
`;

const SubMenu = styled.div`
    padding-left: 40px;
`;

const HomeWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
    @media screen and (max-width: 425px) {
        margin: 10px;
    }
`;

const StepWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const ImageContainer = styled.div`
    display: flex;
    flex: 0 0 56px;
    align-items: center;
    justify-content: center;
`;

const Logo = styled.img`
    width: 25%;
    margin-right: 1px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        width: 50%;
    }
`;
