import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    color: #768192;
    background-color: #fff;
    border: 1px solid #d8dbe0;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        color: #768192;
        background-color: #fff;
        border-color: #958bef;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(var(--primary-rgb) / 25%);
    }
    &:disabled {
        background-color: #e8e6e6;
        opacity: 10;
    }
`;

export default StyledInput;
