import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Footer = (): ReactElement => {
    return (
        <FooterWrapper>
            {/* <StyledLink href="https://blockchain-lab.um.si/?lang=en" target="_blank" rel="noopener noreferrer">
                Blockchain Lab:UM
            </StyledLink>
            <StyledSpan>© 2021</StyledSpan> */}
        </FooterWrapper>
    );
};

const FooterWrapper = styled.footer``;

const StyledLink = styled.a`
    text-decoration: none;
    background-color: transparent;
    color: var(--primary);
`;

const StyledSpan = styled.span`
    margin-left: 0.25rem;
`;

export default Footer;
