import axios from 'axios';
import React, { useState, useEffect, ReactElement } from 'react';
import { FetchedVCDataMulti } from 'types/Types';
import { API_ADDRESS } from 'config/config';
import styled from 'styled-components';
import { Button } from 'ui/atoms/Button';
import { Generator } from 'ui/organisms/QRCode';
import { ListPickupPrepared } from 'ui/organisms/List/PickupPrepared';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { useAuth } from 'contexts/AuthContext';
import { Title } from 'ui/atoms/Title';

const PickupPrepared = (): ReactElement => {
    const { currentUser } = useAuth();
    const [fetchedVCData, setFetchedVCData] = useState<FetchedVCDataMulti[] | null>(null);
    const [selectedFetchedVCData, setSelectedFetchedVCData] = useState<string | null>(null);
    const [showQR, setShowQR] = useState(false);
    const { loading, error, setLoading, setError } = useApiState();

    const getVCData = () => {
        setLoading(true);
        axios
            .get(`${API_ADDRESS}/vc/fetch/vc2`, { headers: { Authorization: `Bearer ${currentUser.token}` } })
            .then((res) => {
                // next two lines defines sorting by descending
                const fetchedData: FetchedVCDataMulti[] = res.data;
                fetchedData?.sort((a, b) => b.data.timestamp.valueOf() - a.data.timestamp.valueOf());
                setFetchedVCData(fetchedData);
            })
            .catch((err) => console.error(err))
            .then(() => setLoading(false));
    };

    const toggleQR = () => {
        setShowQR(!showQR);
    };

    useEffect(() => {
        getVCData();
    }, []);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <PickupPreparedWrapper>
                <Title text="Pregled pripravljenih dobavnic"></Title>
                <ContainerMain>
                    {!showQR && fetchedVCData && (
                        <ListPickupPrepared
                            fetchedPickupData={fetchedVCData}
                            selected={selectedFetchedVCData}
                            onSelectionChanged={setSelectedFetchedVCData}
                        />
                    )}
                    {showQR && selectedFetchedVCData && <Generator ipfsPath={selectedFetchedVCData} />}
                </ContainerMain>
            </PickupPreparedWrapper>
        </LoadingAnimationWrapper>
    );
};

export default PickupPrepared;

const PickupPreparedWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;
