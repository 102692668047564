import { API_ADDRESS, API_ADDRESS_VASCO } from 'config/config';
import axios from 'axios';
import { Crop, FetchedVCDataMultiNew, SowingData, VASCOPartner, VASCOprevzemnicaResponse } from 'types/Types';
import { error } from 'console';

export const getVascoPrevzemnica = async (stevilkaPrevzemnice: string, token: string) =>
    new Promise<VASCOprevzemnicaResponse>((resolve, reject) => {
        const url = `FA/prevzemnica?StevilkaOd=${stevilkaPrevzemnice}&StevilkaDo=${stevilkaPrevzemnice}&VrniPostavke=1&DodatnaPoljaPostavke=STOPNJA_DDV`;
        axios
            .get(`${API_ADDRESS}/vasco/${url}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                // console.log(res.data);
                //console.log(res);
                resolve(res.data[0]);
            })
            .catch((err) => reject(err));
    });

export const getUserName = async (vatNumber: string, token: string) =>
    new Promise<VASCOPartner>((resolve, reject) => {
        const url = `SkupniSifranti/partner?Davcna=${vatNumber}`;
        axios
            .get(`${API_ADDRESS}/vasco/${url}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
                // console.log(res.data);
                let sifra = '0';
                if (res.data.length == 0) {
                    sifra = '64';
                } else {
                    sifra = res.data[0].sifra;
                }
                localStorage.setItem('vascoUserID', sifra); //user vasco ID used to get user defined values
                console.log('Vasco not exists, user sifra set to test user:', localStorage.getItem('vascoUserID'));
                resolve(res.data[0]);
            })
            .catch((err) => reject(err));
    });

export const getUserCrops = async () =>
    new Promise<string[]>((resolve, reject) => {
        const url = `FASifranti/artikel?Dobavitelj=${localStorage.getItem('vascoUserID')}`;
        const userCropCodes: string[] = [];
        axios
            .get(`${API_ADDRESS}/vasco/${url}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            })
            .then((res) => {
                for (const item of res.data) {
                    userCropCodes.push(item.sifra);
                }
                resolve(userCropCodes);
            })
            .catch((err) => reject(err));
    });
