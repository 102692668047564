import React, { useState } from 'react';

type useStepReturnType = {
    step: number;
    stepInc: () => void;
    stepDec: () => void;
};

const useStep = (): useStepReturnType => {
    const [step, setStep] = useState(0);

    const stepInc = (): void => {
        setStep((prevStep) => prevStep + 1);
    };

    const stepDec = (): void => {
        setStep((prevStep) => prevStep - 1);
    };

    return { step, stepInc, stepDec };
};

export default useStep;
