import { formatDateToString, redirectToBCExplorer } from 'helpers/helper-functions';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { VCMulti } from 'types/Types';
import { Button } from 'ui/atoms/Button';
import { Title } from 'ui/atoms/Title';
import { Generator } from 'ui/organisms/QRCode';

export type ListItemPickupPreparedProps = {
    data: VCMulti;
    ipfsAddress: string;
    BCtxHash: string;
};

const ListItemPickupPrepared = ({ data, ipfsAddress, BCtxHash }: ListItemPickupPreparedProps): ReactElement => {
    const [showQR, setShowQR] = useState<boolean>(false);

    const toggleQR = () => {
        setShowQR(!showQR);
    };

    const redirect = () => {
        redirectToBCExplorer(BCtxHash);
    };

    return (
        <ListItemWrapper>
            <ListSection>
                <div>
                    <strong>Id: </strong>
                    {data.timestamp}
                </div>
                <div>
                    <strong>Datum: </strong>
                    {formatDateToString(new Date(data.timestamp), true)}
                </div>
                <div>
                    <strong>Partner: </strong>
                    {data.recipient.name} <br></br>
                    <strong>DŠ: </strong>
                    {data.recipient.username}
                    <br></br>
                </div>
                {data.subject.length !== 0 && (
                    <Table>
                        <tr>
                            <TableHead>ID Pridelka:</TableHead>
                            <TableHead>Naziv:</TableHead>
                            <TableHead>Količina:</TableHead>
                        </tr>
                        {data.subject.map((subjectData) => (
                            <tr key={subjectData.crop._id}>
                                <TableItem>{subjectData.crop._id}</TableItem>
                                <TableItem>{subjectData.crop.name}</TableItem>
                                <TableItem>
                                    {subjectData.quantity} {subjectData.unit}
                                </TableItem>
                            </tr>
                        ))}
                    </Table>
                )}
                {showQR && (
                    <QRWrapper>
                        <Title text="Skenirajte QR kodo za podpis transakcije" />
                        <Generator ipfsPath={ipfsAddress} />
                    </QRWrapper>
                )}
                <ContainerBottom>
                    <Button text="Prikaži QR" btnType="primary" onClick={toggleQR} />
                    <VerticalDelimiter />
                    <Button text="BlockChainExplorer" btnType="primary" onClick={redirect} />
                </ContainerBottom>
            </ListSection>
        </ListItemWrapper>
    );
};

const ListItemWrapper = styled.div`
    flex: 1 1 auto;
    padding: 1rem;
`;

const VerticalDelimiter = styled.div`
    margin-right: 1em;
`;

const ListItem = styled.div``;

const ListSection = styled.div`
    padding: 4px;
    margin-bottom: 6px;
`;
const QRWrapper = styled.div`
    font-size: 9pt;
    font-weight: bold;
    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        width: 50%;
    }
`;

const Table = styled.table`
    margin: 10px 0 10px 0;
    border: 1px solid #d8dbe0;
    border-collapse: collapse;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        width: 50%;
    }
`;

const TableHead = styled.th`
    border: 1px solid #d8dbe0;
    border-collapse: collapse;
`;

const TableItem = styled.td`
    border: 1px solid #d8dbe0;
    border-collapse: collapse;
`;

const ContainerBottom = styled.div`
    display: flex;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        width: 50%;
    }
`;

export default ListItemPickupPrepared;
