import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

export type TitleProps = {
    text?: string;
    subtext?: string;
};

const Title = ({ text, subtext, ...props }: TitleProps): ReactElement => {
    return (
        <TitleMainWrapper {...props}>
            <div>
                <TitleWrapper>{text}</TitleWrapper>
                <SubTitleWrapper>{subtext}</SubTitleWrapper>
            </div>
        </TitleMainWrapper>
    );
};

const TitleMainWrapper = styled.title`
    justify-content: center;
    display: flex;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
`;

const TitleWrapper = styled.title`
    justify-content: center;
    display: flex;
    font-size: 1.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
`;

const SubTitleWrapper = styled.title`
    justify-content: center;
    display: flex;
    font-size: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
`;

export default Title;
