import React, { CSSProperties } from 'react';
import { Crop } from 'types/Types';
import ListItemCrop from 'ui/atoms/ListItem/Crop/ListItemCrop';
import SelectableItem from 'ui/atoms/SelectableItem/SelectableItem';

export type SelectableListItemCropMultiProps = {
    crop: Crop;
    onClick: () => void;
    selected: boolean; // TODO: Change to boolean if possible ?
    style?: CSSProperties;
};

const SelectableListItemCropMulti = ({
    crop,
    onClick,
    selected,
    style,
}: SelectableListItemCropMultiProps): JSX.Element => {
    return (
        <SelectableItem style={style} onClick={onClick} selected={selected}>
            <ListItemCrop crop={crop} />
        </SelectableItem>
    );
};

export default SelectableListItemCropMulti;
