import React, { ReactElement } from 'react';
import { SowingData } from 'types/Types';
import styled from 'styled-components';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { SelectableListItemSowingMulti } from 'ui/molecules/SelectableListItem/SowingMulti';

export type ListSowingsMultiProps = {
    sowings: SowingData[];
    selected: SowingData[];
    onSelectionChanged: React.Dispatch<React.SetStateAction<SowingData[]>>;
};

const ItemRenderer = ({ style, index, data }: ListChildComponentProps) => {
    const item = data.items[index];
    const active = data.selected.includes(item);
    //console.log('List Sowings. Index:', index);
    //console.log('List Sowings 2. Data:', data);
    return (
        <>
            <SelectableListItemSowingMulti
                key={index}
                sowing={item}
                selected={active}
                onClick={() => data.handleSelectionChanged(item, active)}
                style={style}
            />
        </>
    );
};

const ListSowingsMulti = ({ sowings, selected, onSelectionChanged }: ListSowingsMultiProps): ReactElement => {
    const handleSelectionChanged = (item: SowingData, active: boolean) => {
        //console.log('Selected Sowing from the list: ', item);
        //console.log('Selected to this moment', selected);

        const tempArray: SowingData[] = [];
        let isSelected = false;
        selected.forEach((sowing) => {
            if (sowing.ipfsAddress == item.ipfsAddress) {
                isSelected = true;
            } else {
                tempArray.push(sowing);
            }
        });
        if (!isSelected) {
            tempArray.push(item);
        }
        selected = tempArray;
        //console.log('Selected after active true', selected);
        onSelectionChanged(tempArray);
    };
    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    itemData={{ items: sowings, selected: selected, handleSelectionChanged: handleSelectionChanged }}
                    itemSize={60}
                    height={height}
                    width={width}
                    itemCount={sowings.length}
                >
                    {ItemRenderer}
                </List>
            )}
        </AutoSizer>
    );
};

export default ListSowingsMulti;

const List = styled(FixedSizeList)`
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #d8dbe0;
    border-top: 1px solid #d8dbe0;
`;
