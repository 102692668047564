import React, { CSSProperties } from 'react';
import { SowingData } from 'types/Types';
import ListItemSowing from 'ui/atoms/ListItem/Sowing/ListItemSowing';
import SelectableItem from 'ui/atoms/SelectableItem/SelectableItem';

export type SelectableListItemSowingMultiProps = {
    sowing: SowingData;
    onClick: () => void;
    selected: boolean; // TODO: Change to boolean if possible ?
    style?: CSSProperties;
};

const SelectableListItemSowingMulti = ({
    sowing,
    onClick,
    selected,
    style,
}: SelectableListItemSowingMultiProps): JSX.Element => {
    //console.log('Selectable List Item Sowings. Sowing object from selected', sowing);
    //console.log('Selectable List Item Sowings 2. selected Object', selected);
    return (
        <SelectableItem style={style} onClick={onClick} selected={selected}>
            <ListItemSowing sowing={sowing} />
        </SelectableItem>
    );
};

export default SelectableListItemSowingMulti;
