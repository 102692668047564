import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'ui/molecules/Input';
import { ListPartners } from '../List/Partners';

type SearchPartnersProps = {
    partners: any[];
    selected: number | null;
    onSelectionChanged: React.Dispatch<React.SetStateAction<number | null>>;
};

const SearchPartners = ({ partners, selected, onSelectionChanged }: SearchPartnersProps): ReactElement => {
    const [search, setSearch] = useState('');
    const [filteredPartners, setFilteredPartners] = useState<any[]>([]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        setFilteredPartners(partners);
    }, [partners]);

    useEffect(() => {
        setFilteredPartners(
            partners.filter((partner) => {
                if (partner.naziv) return partner.naziv.toLowerCase().includes(search.toLowerCase());
            }),
        );
    }, [search]);

    return (
        <SearchCropsWrapper>
            <SearchInput type="text" value={search} label="Search" onChange={handleInputChange} />
            <ListWrapper>
                <ListPartners partners={filteredPartners} selected={selected} onSelectionChanged={onSelectionChanged} />
            </ListWrapper>
        </SearchCropsWrapper>
    );
};

export default SearchPartners;

const SearchCropsWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    margin: 10px;
    flex-direction: column;
`;

const SearchInput = styled(Input)`
    margin-bottom: 15px;
`;

const ListWrapper = styled.div`
    flex: 1 1 auto;
`;
