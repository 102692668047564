import axios from 'axios';
import { Button } from 'ui/atoms/Button';
import React, { ReactElement } from 'react';
import { User, DataVC2, FetchedVCData } from 'types/Types';
import { API_ADDRESS } from 'config/config';
import { useAuth } from 'contexts/AuthContext';
import styled from 'styled-components';
import { showToast } from 'ui/atoms/Toast/Toast';
import { useHistory } from 'react-router-dom';
import { TextConstant } from '../../../config/text-constant';
import { CUSTOM_SHOPS_ARRAY } from '../../../config/customShopsConstants';

type ConfirmPickupCreationProps = {
    harvestData: FetchedVCData[];
    proofData: FetchedVCData[];
    user: User;
    setSuccess: (data: boolean) => void;
    setLoading: (data: boolean) => void;
    setError: (data: string) => void;
    setTxLink: React.Dispatch<React.SetStateAction<string | null>>;
};

const confirmPickupCreation = ({
    harvestData,
    proofData,
    user,
    setSuccess,
    setLoading,
    setError,
    setTxLink,
}: ConfirmPickupCreationProps): ReactElement => {
    const { currentUser } = useAuth();
    const history = useHistory();

    const mapSubjects = () =>
        harvestData.map((data) => ({
            _id: data.data.subject.crop._id,
            description: data.data.subject.description,
            quantity: data.data.subject.quantity,
            unit: data.data.subject.unit,
            date: data.data.subject.date,
        }));

    const mapProofs = () => proofData.map((data) => data.ipfsAddress);

    const confirmPickupCreationHandler = () => {
        // TODO: Validate data before sending
        let testZadruga = '';
        console.log('User Sifra: ', localStorage.getItem('vascoUserID'));
        CUSTOM_SHOPS_ARRAY.forEach((c) => {
            if (c.userId.toString() == localStorage.getItem('vascoUserID')) {
                console.log('user found');
                testZadruga = c.groupId + '|' + c.shopId;
            }
        });
        if (testZadruga == '') {
            //remains the same from version before zadruga
            const vcData = {
                recipient: user.ethWallet.address,
                subject: mapSubjects(),
                proof: mapProofs(),
            };
            console.log('Brez zadruge', vcData);
            history.push('/home');
            confirmPickupCreation(vcData);
            //confirmPickupMultiCreation(vcData);
        } else {
            const vcData = {
                recipient: user.ethWallet.address,
                subject: mapSubjects(),
                proof: mapProofs(),
                zadruga: testZadruga,
            };
            console.log('Z zadrugo', vcData);
            history.push('/home');
            confirmPickupCreation(vcData);
            //confirmPickupMultiCreation(vcData);
        }
        /*const vcData = {
            recipient: user.ethWallet.address,
            subject: mapSubjects(),
            proof: mapProofs(),
        };
        history.push('/home');
        confirmPickupCreation(vcData);*/
    };

    const confirmPickupCreation = (data: DataVC2) => {
        //setLoading(true);
        axios
            .post(
                `${API_ADDRESS}/vc/create/vc2`,
                {
                    data: data,
                },
                { headers: { Authorization: `Bearer ${currentUser.token}` } },
            )
            .then((res) => {
                setTxLink(res.data.txLink);
                showToast({
                    position: 'top-right',
                    message: 'Uspešno dodana dobavnica: ',
                    type: 'success',
                    BCtxHash: res.data.txLink,
                });
                setSuccess(true);
            })
            .catch((err) => {
                console.error(err);
                showToast({
                    position: 'top-right',
                    message: 'Neuspešno dodana dobavnica: ',
                    type: 'danger',
                });
            });
        //.finally(() => setLoading(false));
    };

    return (
        <ConfirmPickupWrapper>
            <h3>Informacije o prevzemniku:</h3>
            <ListSection>
                <div>
                    <strong>Uporabniško ime:</strong> {user.username}
                </div>
                <div>
                    <strong>Tip podjetja:</strong> {user.userType}
                </div>
                <div>
                    <strong>Email:</strong> {user.email}
                </div>
            </ListSection>
            <h3>Seznam pridelkov:</h3>
            {harvestData.map((data, i) => {
                // TODO: Check if we need proof here ? If not remove proofData from props.
                const proof = proofData[i];
                return (
                    <ListSection key={data.ipfsAddress}>
                        <div>
                            <div>
                                <strong>Naziv:</strong> {data.data.subject.crop.name}
                            </div>
                            <div>
                                <strong>Opis:</strong> {data.data.subject.description}
                            </div>
                            <div>
                                <strong>Količina:</strong> {data.data.subject.quantity}
                            </div>
                            <div>
                                <strong>Enota:</strong> {data.data.subject.unit}
                            </div>
                            <div>
                                <strong>{TextConstant.DATE_OF_SOWING}</strong> {data.data.subject.date}
                            </div>
                        </div>
                    </ListSection>
                );
            })}
            <BottomWrapper>
                <Button onClick={confirmPickupCreationHandler} text="Potrdi" btnType="primary" />
            </BottomWrapper>
        </ConfirmPickupWrapper>
    );
};

export default confirmPickupCreation;

const ConfirmPickupWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
`;

const BottomWrapper = styled.div`
    margin-top: auto;
`;

const ListSection = styled.div`
    border: 1px solid #d8dbe0;
    padding: 4px;
    margin-bottom: 6px;
`;
