import React from 'react';
import { useAuth, UserType } from 'contexts/AuthContext';
import styled from 'styled-components';
import { LinkDescription } from 'types/Types';
import { SidebarNavLinks } from 'ui/organisms/SidebarNavLinks';
import { FaUserPlus, FaUsersCog } from 'react-icons/fa';
import { CgList, CgPlayListCheck } from 'react-icons/cg';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { BiScan } from 'react-icons/bi';
import { GiFarmer, GiGrass } from 'react-icons/gi';
import { Button } from 'ui/atoms/Button';

const headerSowing = { tag: 'Pridelave / Predelave', path: null };
const headerHarvest = { tag: 'Spravilo', path: null };
const headerSupply = { tag: 'Dobavnice', path: null };
const headerPickup = { tag: 'Prevzem', path: null };
const headerAdmin = { tag: 'Admin', path: null };

const linkHarvest = { tag: 'Spravilo', path: 'harvest', icon: <GiFarmer /> };
const linkSowing = { tag: 'Kreiraj', path: 'sowing', icon: <GiGrass /> };
const linkSowingUpdate = { tag: 'Aktivne', path: 'sowingupdate', icon: <GiGrass /> };
const linkSowingFinished = { tag: 'Zaključene', path: 'sowingfinished', icon: <GiGrass /> };
const linkSupplyCreate = { tag: 'Kreiraj', path: 'pickup/create', icon: <AiOutlineFileAdd /> };
const linkSupplyCreateMulti = { tag: 'Kreiraj', path: 'pickup/createMulti', icon: <AiOutlineFileAdd /> };
const linkSupplyGet = { tag: 'Pripravljene', path: 'pickup/get', icon: <CgList /> };
const linkSupplyEnd = { tag: 'Izvedene', path: 'pickup/overview', icon: <CgList /> };
const linkPickupScan = { tag: 'Prevzami', path: 'pickup/scan', icon: <BiScan /> };
const linkPickupOverview = { tag: 'Opravljeni', path: 'pickup/overview', icon: <CgPlayListCheck /> };
const linkUserDashboardAdd = { tag: 'Dodajanje uporabnikov', path: 'admin/user_dashboard_add', icon: <FaUserPlus /> };
const linkUserDashnoardManage = {
    tag: 'Urejanje uporabnikov',
    path: 'admin/user_dashboard_manage',
    icon: <FaUsersCog />,
};
const linkUserDashboardActivityLogs = {
    tag: 'Aktivnosti uporabnikov',
    path: 'admin/user_dashboard_activitylog',
    icon: <FaUsersCog />,
};

const mapUserTypeToLinks = new Map<UserType, LinkDescription[]>([
    [
        'PRIDELOVALEC',
        [
            headerSowing,
            linkSowing,
            linkSowingUpdate,
            linkSowingFinished,
            headerHarvest,
            linkHarvest,
            headerSupply,
            linkSupplyCreate,
            linkSupplyGet,
            linkSupplyEnd,
            // headerPickup,
            // linkPickupOverview,
        ],
    ],
    [
        'PREVOZNIK',
        [headerSupply, linkSupplyCreateMulti, linkSupplyGet, headerPickup, linkPickupScan, linkPickupOverview],
    ],
    ['KOV', [headerPickup, linkPickupScan, linkPickupOverview]],
    ['ADMIN', [headerAdmin, linkUserDashboardAdd, linkUserDashnoardManage, linkUserDashboardActivityLogs]],
]);

export type SidebarProps = {
    toggleSidebar: () => void;
};

const Sidebar = ({ toggleSidebar }: SidebarProps): JSX.Element => {
    const { currentUser } = useAuth();

    function getLinks(): LinkDescription[] {
        const links = mapUserTypeToLinks.get(currentUser.userType);
        return links ? links : [];
    }

    return (
        <SidebarWrapper>
            <SidebarLogoContainer>
                <SidebarLogo src={process.env.PUBLIC_URL + '/ztocka.jpg'} />
            </SidebarLogoContainer>
            <Ul>
                <SidebarNavLinks links={getLinks()} />
            </Ul>
            <StyledButton text="Zapri" btnType="secondary" onClick={toggleSidebar} />
        </SidebarWrapper>
    );
};

export default Sidebar;

const SidebarWrapper = styled.div`
    background: var(--color);
    position: relative;
    display: flex;
    flex: 0 0 256px;
    flex-direction: column;
    width: 256px;
    padding: 0;
    box-shadow: none;
    height: 100%;
`;

const Ul = styled.ul`
    position: relative;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    flex: 1 1;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;

    margin-top: 0;
    overflow: hidden;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
`;

const SidebarLogoContainer = styled.div`
    color: #fff;
    background: rgba(0, 0, 21, 0.2);
    display: flex;
    flex: 0 0 56px;
    align-items: center;
    justify-content: center;
`;

const StyledButton = styled(Button)`
    border-radius: 0;

    @media screen and (min-width: 768px) {
        display: none;
        margin-top: auto;
    }
`;

const SidebarLogo = styled.img`
    width: 100%;
    margin-right: 1px;
    border-right: 1px solid #3c4b64;
`;
