import React, { ReactElement } from 'react';
import { SowingData } from 'types/Types';
import styled from 'styled-components';
import SelectableListItemSowing from 'ui/molecules/SelectableListItem/Sowing/SelectableListItemSowing';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

export type ListSowingsProps = {
    sowings: SowingData[];
    selected: SowingData | null;
    onSelectionChanged: React.Dispatch<React.SetStateAction<SowingData | null>>;
};

const ItemRenderer = ({ style, index, data }: ListChildComponentProps) => {
    const item = data.items[index];
    console.log('List Sowings. Index:', index);
    console.log('List Sowings 2. Data:', data);
    return (
        <>
            <SelectableListItemSowing
                key={index}
                sowing={item}
                selected={data.selected}
                onSelectionChanged={data.onSelectionChanged}
                style={style}
            />
        </>
    );
};

const ListSowings = ({ sowings, selected, onSelectionChanged }: ListSowingsProps): ReactElement => {
    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    itemData={{ items: sowings, selected: selected, onSelectionChanged: onSelectionChanged }}
                    itemSize={60}
                    height={height}
                    width={width}
                    itemCount={sowings.length}
                >
                    {ItemRenderer}
                </List>
            )}
        </AutoSizer>
    );
};

export default ListSowings;

const List = styled(FixedSizeList)`
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #d8dbe0;
    border-top: 1px solid #d8dbe0;
`;
