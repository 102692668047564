import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth, UserType } from '../contexts/AuthContext';

type PrivateRouteProps = RouteProps & {
    userTypes: UserType[];
};

const PrivateRoute = ({ userTypes, children, ...rest }: PrivateRouteProps): JSX.Element => {
    const { isAuthenticated, currentUser } = useAuth();

    return (
        <Route
            {...rest}
            render={() => {
                return isAuthenticated && userTypes.includes(currentUser.userType) ? (
                    children
                ) : (
                    <Redirect to={{ pathname: '/' }} />
                );
            }}
        />
    );
};

export default PrivateRoute;
