import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from 'contexts/AuthContext';
import { Button } from 'ui/atoms/Button';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import styled from 'styled-components';
import ChangePassword from 'ui/organisms/ChangePassword/ChangePassword';
import { API_ADDRESS } from 'config/config';

export type ChangePasswordFunctionProps = {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
};

const Profile = (): JSX.Element => {
    const { currentUser } = useAuth();
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();
    const [currentAction, setCurrentAction] = useState(0);

    const changePassword = ({ oldPassword, newPassword, newPasswordRepeat }: ChangePasswordFunctionProps) => {
        setLoading(true);

        const data = {
            oldPassword: oldPassword,
            newPassword: newPassword,
        };

        axios
            .post(
                `${API_ADDRESS}/user/update/password`,
                { data: data },
                {
                    headers: { Authorization: `Bearer ${currentUser.token}` },
                },
            )
            .then((res) => {
                console.log(res);
            })
            .catch((err) => setError(err))
            .finally(() => {
                setLoading(false);
                setCurrentAction(0);
            });
    };

    const cancel = () => {
        setCurrentAction(0);
    };

    return (
        <LoadingAnimationWrapper loading={loading}>
            <ProfileWrapper>
                {currentAction === 0 && (
                    <div>
                        <div>Uporabniško ime: {currentUser.username}</div>
                        <div>Elektronski naslov: {currentUser.email}</div>
                        <div>Tip uporabnika: {currentUser.userType}</div>
                        {Object.keys(currentUser.ethWallet).length !== 0 && (
                            <div>ETH naslov: {currentUser.ethWallet.address}</div>
                        )}
                        <Button text="Spremeni geslo" btnType="primary" onClick={() => setCurrentAction(1)} />
                    </div>
                )}
                {currentAction === 1 && <ChangePassword changePassword={changePassword} cancel={cancel} />}
            </ProfileWrapper>
        </LoadingAnimationWrapper>
    );
};

export default Profile;

const ProfileWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;
