import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { User } from 'types/Types';

export type ListItemUserProps = {
    user: User;
};

const ListItemUser = ({ user }: ListItemUserProps): ReactElement => {
    return (
        <ListItem>
            <div>Naziv: {user.name}</div>
            <div>Uporabniško ime: {user.username}</div>
            <div>Tip podjetja: {user.userType}</div>
            <div>Email: {user.email}</div>
        </ListItem>
    );
};

const ListItem = styled.div`
    padding: 1rem;
    align-self: center;
`;

export default ListItemUser;
