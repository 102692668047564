import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from 'ui/atoms/Button';
import styled, { css } from 'styled-components';
import { FetchedVCDataMulti, FetchedVCDataMultiNew, Komercialist } from 'types/Types';
import { API_ADDRESS } from 'config/config';
import axios from 'axios';
import { useAuth } from 'contexts/AuthContext';
import { Input } from 'ui/molecules/Input';
import Select from 'react-select';
import { useApiState } from 'hooks/useApiState';

type ConfirmPickupProps = {
    fetchedVCData: FetchedVCDataMultiNew;
    setResult: (result: string | null) => void;
    setSuccess: (data: boolean) => void;
    setLoading: (data: boolean) => void;
    setError: (data: string) => void;
    setTxLink: React.Dispatch<React.SetStateAction<string | null>>;
};

type DataVC3 = {
    creator: string;
    recipient: string;
    subject: {
        _id: string;
        description: string;
        quantity: number;
        unit: string;
        date: string;
    }[];
    proof: string;
    ipfsAddressPickup: string | string[]; // TODO: Check if this is ok -> Maybe we only need string ?
    rabat?: string;
    komercialist?: string;
    lots?: string[];
    zadruga?: string;
};

type OptionsKomercialisti = {
    value: string;
    label: string;
};

const ConfirmPickup = ({
    fetchedVCData,
    setResult,
    setSuccess,
    setLoading,
    setError,
    setTxLink,
}: ConfirmPickupProps): ReactElement => {
    const { currentUser } = useAuth();
    const { loading, setLoading: setLocalLoading } = useApiState();
    const [komercialisti, setKomercialisti] = useState<OptionsKomercialisti[]>([]);
    const [selectedKomercialist, setSelectedKomercialist] = useState<string>('');
    const [rabat, setRabat] = useState<string>('0');
    const [lots, setLots] = useState(new Map<string, string>());
    const [validatedLot, setValidatedLot] = useState<boolean>(false);
    const [insertedlot, setInsertedLot] = useState<string>('');
    const [cropQuantities, setCropQuantities] = useState(new Map<string, number>());
    const [oldCropQuantities, setOldCropQuantities] = useState(new Map<string, number>());

    const handleCancel = () => {
        setResult(null);
    };

    const mapSubjects = () =>
        fetchedVCData.data.subject.map((data) => ({
            _id: data.crop._id,
            description: data.description,
            quantity: data.quantity,
            unit: data.unit,
            date: data.date,
        }));

    const mapLots = (): string[] =>
        fetchedVCData.data.subject.map((data) => {
            const lot = lots.get(data.crop._id);
            return lot ? lot : '';
        });

    const handleConfirm = () => {
        console.log('Fetched data:', fetchedVCData.data);
        let vcData: DataVC3 = {
            creator: fetchedVCData.data.creator.ethWallet.address, // RABIMO CREATOR ? LAHKO DOBIMO IZ TOKENA
            recipient: fetchedVCData.data.recipient,
            subject: mapSubjects(),
            proof: fetchedVCData.ipfsAddress,
            ipfsAddressPickup: fetchedVCData.data.proof,
            zadruga: fetchedVCData.data.zadruga,
        };

        if (currentUser.userType === 'KOV') {
            vcData = { ...vcData, rabat: rabat, komercialist: selectedKomercialist, lots: mapLots() };
        }

        confirmPickup(vcData);
        setResult(null);
    };

    const handleReject = () => {
        setLoading(true);
        axios
            .post(
                `${API_ADDRESS}/vc/reject`,
                {
                    data: {
                        ipfsPath: fetchedVCData.ipfsAddress,
                    },
                },
                { headers: { Authorization: `Bearer ${currentUser.token}` } },
            )
            .then((res) => {
                setTxLink(res.data.txLink);
                setSuccess(true);
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    };

    const confirmPickup = (data: DataVC3) => {
        setLoading(true);
        console.log('Confirm PICKUP DATA in: ', data);
        console.log('Confirm Pickup Zadruga split: ', data.zadruga?.split('|')[1]);
        axios
            .post(
                `${API_ADDRESS}/vc/create/vc3`,
                {
                    data: data,
                },
                { headers: { Authorization: `Bearer ${currentUser.token}` } },
            )
            .then((res) => {
                console.log('Confirm response: ', res);
                setSuccess(true);
            })
            .catch((err) => {
                console.error(err);
                console.error('VC3 Create response error');
            })
            .finally(() => setLoading(false));
    };

    const getKomercialisti = () => {
        setLocalLoading(true);
        axios
            .get(`${API_ADDRESS}/user/vasco/komercialisti`, {
                headers: { Authorization: `Bearer ${currentUser.token}` },
            })
            .then((res) => {
                res.data.komercialisti.forEach((komercialist: Komercialist) => {
                    setKomercialisti((prevState) => [
                        ...prevState,
                        { value: komercialist.sifra, label: `${komercialist.ime} ${komercialist.priimek}` },
                    ]);
                });
            })
            .catch((err) => console.error(err))
            .finally(() => setLocalLoading(false));
    };

    const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>, cropId: string) => {
        console.log('Handle Quantity change action trigered');
        const newMap = new Map(cropQuantities);
        newMap.set(cropId, Number.parseFloat(e.target.value));
        setCropQuantities(newMap);
        fetchedVCData.data.subject.map((a) => {
            if (a.crop._id === cropId) {
                a.quantity = Number.parseFloat(e.target.value);
            }
        });
        console.log('Dodajanje količin k posamezni kulturi: ', cropId);
        console.log('napoljnjen object: ', cropQuantities);
        //setHarvestData({ ...harvestData, quantity: Number.parseFloat(e.target.value) });
    };

    const handleLotInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInsertedLot(e.target.value);
        if (e.target.validity.valid) {
            setValidatedLot(true);
            mapLotChange(e.target.value);
        } else {
            setValidatedLot(false);
        }
    };

    const mapLotChange = (lot: string) => {
        const newMap = new Map(lots);
        for (const subjectdata of fetchedVCData.data.subject) {
            newMap.set(subjectdata.crop._id, lot);
            setLots(newMap);
        }
        console.log('Mapped lots', newMap);
    };

    const mapCropsQuantities = () => {
        const newMap = new Map(cropQuantities);
        for (const subjectdata of fetchedVCData.data.subject) {
            newMap.set(subjectdata.crop._id, subjectdata.quantity);
            setCropQuantities(newMap);
            setOldCropQuantities(newMap);
        }
        console.log('Mapped crop quantities', newMap);
    };

    useEffect(() => {
        console.log('Fetched data at beggining:', fetchedVCData.data);
        mapCropsQuantities();
        if (currentUser.userType === 'KOV') getKomercialisti();
    }, []);

    return (
        <div>
            <h3>Pripravil uporabnik:</h3>
            <ListSection>
                <div>Uporabniško ime: {fetchedVCData.data.creator.username}</div>
                <div>Tip podjetja: {fetchedVCData.data.creator.userType}</div>
                <div>Email: {fetchedVCData.data.creator.email}</div>
            </ListSection>
            <h3>Seznam pridelkov:</h3>
            {fetchedVCData.data.subject.map((subjectData) => {
                return (
                    <ListItem key={subjectData.crop._id}>
                        <ListSection>
                            <div>Naziv: {subjectData.crop.name}</div>
                            <div>Datum: {subjectData.date}</div>
                            <div>
                                Količina: {oldCropQuantities.get(subjectData.crop._id)} {subjectData.unit}
                            </div>
                            <div>Opis: {subjectData.description}</div>
                        </ListSection>
                        <Delimiter></Delimiter>
                        <HorizontalWrapper>
                            <StyledInput
                                label="Količina"
                                type="number"
                                step="0.1"
                                value={cropQuantities.get(subjectData.crop._id)}
                                className="halfpart"
                                onChange={(e) => handleQuantityChange(e, subjectData.crop._id)}
                            />
                            <StyledInput
                                label=""
                                type="text"
                                value={subjectData.crop.enota}
                                className="halfpart"
                                disabled
                            />
                        </HorizontalWrapper>
                    </ListItem>
                );
            })}
            <Delimiter></Delimiter>
            {currentUser.userType === 'KOV' && (
                <StyledInput
                    type="text"
                    name="lot"
                    label="lot (format: YYMMDD)"
                    maxLength={6}
                    pattern="\d{6}"
                    value={insertedlot}
                    onChange={(e) => handleLotInputChange(e)}
                    className={validatedLot ? 'valid' : 'invalid'}
                />
            )}
            {currentUser.userType === 'KOV' && (
                <>
                    <h3>Potrebni podatki za ustvarjanje prevzemnice:</h3>
                    <StyledSelect
                        options={komercialisti}
                        isLoading={loading}
                        isDisabled={loading}
                        onChange={(opt: OptionsKomercialisti) => {
                            setSelectedKomercialist(opt.value);
                        }}
                    />
                </>
            )}
            <ContainerButtons>
                <ContainerButton pos="left">
                    <Button text="Prekliči" onClick={handleCancel} btnType="primary" />
                </ContainerButton>
                <ContainerButton pos="middle">
                    <Button text="Zavrni" onClick={handleReject} btnType="primary" />
                </ContainerButton>
                <ContainerButton pos="right">
                    <Button text="Potrdi" onClick={handleConfirm} btnType="primary" disabled={!validatedLot} />
                </ContainerButton>
            </ContainerButtons>
        </div>
    );
};

export default ConfirmPickup;

const ListItem = styled.div``;

const ContainerButtons = styled.div`
    display: flex;
    margin: 20px 0px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' | 'middle' }>`
    width: 100%;
    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `) ||
        (pos === 'middle' &&
            css`
                margin-left: 5px;
                margin-right: 5px;
            `)}
`;

const ListSection = styled.div`
    border: 1px solid #d8dbe0;
    padding: 4px;
    margin-bottom: 6px;
`;

const StyledSelect = styled(Select)`
    z-index: 1000;
    margin-top: 20px;
    margin-bottom: 21px;
`;

const HorizontalWrapper = styled.div`
    display: flex;
`;

const StyledInput = styled(Input)`
    margin-bottom: 10px;
    &.valid {
        background-color: white;
    }
    &.invalid {
        background-color: #ffaaaa;
    }
    &.halfpart {
        display: inline-flex;
    }
`;
const Delimiter = styled.div`
    margin: 20px;
`;
