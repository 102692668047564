import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Crop, SowingData } from 'types/Types';
import { getCropsNamesFromSowing } from '../../../../helpers/helper-functions';

export type ListItemSowingProps = {
    sowing: SowingData;
};

const ListItemSowing = ({ sowing }: ListItemSowingProps): ReactElement => {
    return (
        <ListItem>
            <div>
                {sowing.date} - GERK: {sowing.GERK} - {getCropsNamesFromSowing(sowing)}
            </div>
        </ListItem>
    );
};

export default ListItemSowing;

const ListItem = styled.div`
    padding: 1rem;
    align-self: center;
`;
