import React, { ReactElement, useEffect, useState } from 'react';
import axios from 'axios';
import { API_ADDRESS } from 'config/config';
import { Button } from '../../atoms/Button';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry.js');
import { Document, Page } from 'react-pdf';
import { useAuth } from 'contexts/AuthContext';

export type PDFViewerProps = {
    stevilkaPrevzemnice: string;
};

const PDFViewer = ({ stevilkaPrevzemnice }: PDFViewerProps): ReactElement => {
    const { currentUser } = useAuth();
    const [file, setFile] = useState<Blob | null>(null);
    const [base64File, setBase64File] = useState<string | null>(null);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset: number) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const getPDFData = () => {
        axios
            .post(
                `${API_ADDRESS}/vc/deklaracija`,
                { stevilkaPrevzemnice: stevilkaPrevzemnice },
                {
                    headers: { Authorization: `Bearer ${currentUser.token}` },
                },
            )
            .then((res) => {
                const base64 = res.data;
                setBase64File(base64);
                fetch(base64)
                    .then(async (res) => setFile(await res.blob()))
                    .catch((err) => {
                        console.error(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getPDFData();
    }, [stevilkaPrevzemnice]);

    return (
        <div>
            {file !== null && (
                <div>
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <div>
                        <p>
                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                        </p>
                        <Button text="Nazaj" disabled={pageNumber <= 1} onClick={previousPage} />
                        <Button
                            text="Naprej"
                            type="button"
                            disabled={numPages !== null && pageNumber >= numPages}
                            onClick={nextPage}
                        />
                    </div>
                    {base64File !== null && (
                        <div>
                            <a href={base64File} download="deklaracija.pdf">
                                Prenesi PDF
                            </a>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PDFViewer;
