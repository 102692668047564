import React from 'react';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

type TemplateAppProps = {
    sidebar: React.ReactNode;
    header: React.ReactNode;
    body: React.ReactNode;
    footer: React.ReactNode;
    isSidebarVisible: boolean;
};

const TemplateApp = ({ sidebar, header, body, footer, isSidebarVisible }: TemplateAppProps): JSX.Element => {
    const location = useLocation();

    if (location.pathname === '/' || location.pathname === '/404') {
        return <Layout center>{body}</Layout>;
    }

    return (
        <Layout>
            <Sidebar isSidebarVisible={isSidebarVisible}>{sidebar}</Sidebar>
            <Main isSidebarVisible={isSidebarVisible}>
                <Header>{header}</Header>
                <Body>{body}</Body>
                <Footer>{footer}</Footer>
            </Main>
        </Layout>
    );
};

export default TemplateApp;

type SharedProps = {
    isSidebarVisible: boolean;
};

const Sidebar = styled.div<SharedProps>`
    color: #fff;
    background: var(--color);
    margin-left: -256px;
    left: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1030;

    /* ANIMATION */
    transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s, margin-right 0.3s, width 0.3s,
        z-index 0s ease 0.3s;
    transition-property: box-shadow, transform, margin-left, margin-right, width, z-index;
    transition-duration: 0.3s, 0.3s, 0.3s, 0.3s, 0.3s, 0s;
    transition-timing-function: ease, ease, ease, ease, ease, ease;
    transition-delay: 0.15s, 0s, 0s, 0s, 0s, 0.3s;

    ${({ isSidebarVisible }) =>
        isSidebarVisible &&
        css`
            margin-left: 0;
        `}
`;
const Header = styled.div`
    background-color: #fff;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1029;
`;
const Body = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex: 1;

    margin: 20px;

    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    margin-bottom: 1.5rem;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid;
    border-radius: 0.25rem;
    background-color: #fff;
    border-color: #d8dbe0;

    @media screen and (max-width: 768px) {
        margin: 0 0 0 0;
    }
`;
const Footer = styled.div`
    display: flex;
    flex: 0 0 50px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 50px;

    border-top: 1px solid #d8dbe0;
`;
const Main = styled.div<SharedProps>`
    margin-left: 0px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-width: 0;
    min-height: 100vh;

    /* ANIMATION */
    transition: margin 0.3s;
    transition-property: margin;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;

    ${({ isSidebarVisible }) =>
        isSidebarVisible &&
        css`
            margin-left: 256px;
            @media screen and (max-width: 768px) {
                margin-left: 0px;
            }
        `}
`;

// React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
type LayoutProps = {
    center?: boolean;
};

const Layout = styled.div<LayoutProps>`
    display: flex;
    flex-direction: row;
    min-height: 100vh;

    ${({ center }) =>
        center &&
        css`
            align-items: center;
            justify-content: center;
        `}
`;
