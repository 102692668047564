import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../Button';

export type AlertPopupProps = {
    // icon?: JSX.Element;
    // iconPosition?: 'right' | 'left';
    title: string;
    message: string;
    isShown: boolean;
    alertType?: 'success' | 'info' | 'warning' | 'error';
    confirmed: boolean;
    declineButtonText: string;
    confirmeButtonText: string;
    onConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    onDeclined: React.Dispatch<React.SetStateAction<boolean>>;
};

const Alert = ({
    title,
    message,
    isShown,
    alertType,
    confirmed,
    declineButtonText,
    confirmeButtonText,
    onConfirmed,
    onDeclined,
}: AlertPopupProps): ReactElement => {
    const divClass = alertType + ' ' + (isShown ? '' : 'hide');

    const setConfirmed = (stat: boolean) => {
        console.log('Click event works:', stat);
        confirmed = stat;
        onConfirmed(confirmed);
        //confirmed = stat;
    };
    return (
        <AlertPopup className={divClass}>
            <AlertTitle>{title}</AlertTitle>
            <span>{message}</span>

            <ContainerBottom>
                <ContainerButton pos="left">
                    <Button text={declineButtonText} btnType="primary" onClick={() => onDeclined(false)} />
                </ContainerButton>
                <ContainerButton pos="right">
                    <Button text={confirmeButtonText} btnType="primary" onClick={() => setConfirmed(true)} />
                </ContainerButton>
            </ContainerBottom>
        </AlertPopup>
    );
};

export default Alert;

const AlertPopup = styled.div`
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);

    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    z-index: 1000;

    &.success {
        color: #0f5132;
        background-color: #d1e7dd;
        border-color: #badbcc;
    }
    &.info {
        color: black;
        background-color: #648dbb;
        border-color: #6433af;
    }
    &.warning {
        color: #664d03;
        background-color: #fff3cd;
        border-color: #ffecb5;
    }
    &.error {
        color: #664d03;
        background-color: #fff3cd;
        border-color: #ffecb5;
    }
    &.hide {
        display: none;
    }
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;
    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;

const AlertTitle = styled.title`
    display: flex;
    justify-content: center;
    font-size: 12pt;
    font-weight: 500;
`;
