import React, { ReactElement, useState, useEffect } from 'react';
import { FetchedVCDataMulti, FetchedVCDataMultiNew } from 'types/Types';
import { API_ADDRESS } from 'config/config';
import styled from 'styled-components';
import { PDFViewer } from 'ui/organisms/PDFViewer';
import { Button } from 'ui/atoms/Button';
import { ListPickupOverview } from 'ui/organisms/List/PickupOverview';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { useApiState } from 'hooks/useApiState';
import { useAuth } from 'contexts/AuthContext';
import { getVCDataByIpfsAddress } from 'services/blockis-api';
import { Title } from 'ui/atoms/Title';
//import { vascoAuth, vascoAuth1 } from 'services/vasco-api';
import { TblFunc } from 'Tbl2';
import { formatDateToString } from 'helpers/helper-functions';
import { DataTableDataRow, DataTableDataSet, PICKUP_NOTE_DATATABLE_COLUMNS } from 'types/DataTablesTypes';
import { getVascoPrevzemnica } from 'services/vasco-api';

const PickupOverview = (): ReactElement => {
    const { currentUser } = useAuth();
    const [fetchedVCData, setFetchedVCData] = useState<FetchedVCDataMulti[] | null>(null);
    const [selectedFetchedVCData, setSelectedFetchedVCData] = useState<string | null>(null);
    const [stevilkaPrevzemnice, setStevilkaPrevzemnice] = useState<string | null>(null);
    const { loading, error, setLoading, setError } = useApiState();
    const [dataTableDataSet, setDataTableDataSet] = useState<DataTableDataSet>([]);
    const [dataTableFetchedData, setDataTableFetchedData] = useState<FetchedVCDataMulti[] | null>([]);

    const getVCData = async () => {
        setLoading(true);
        const response = await fetch(`${API_ADDRESS}/vc/fetch/vc3`, {
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });
        const json = await response.json();
        if (response.ok === true) {
            //console.log('Response from await:', response);
            //console.log('Json from await:', json);
            const fetchedData: FetchedVCDataMulti[] = json;
            fetchedData?.sort((a, b) => b.data.timestamp.toString().localeCompare(a.data.timestamp.toString()));
            await getVascoPrevzemnica('TEST.2022', currentUser.token);
            prepareDataForTheTable(fetchedData);
            getOldQuantityFromTheChain(fetchedData);
            setLoading(false);
        }
    };

    const prepareDataForTheTable = (fetchedVCData: FetchedVCDataMulti[]) => {
        const dataset: DataTableDataSet = [];
        //console.log('FETCHED:', fetchedVCData);
        fetchedVCData.forEach((item) => {
            const data: DataTableDataRow = [
                '', // prazen string za prazno mesto v tabeli expander or select checkbox
                item.stevilkaPrevzemnice != undefined ? item.stevilkaPrevzemnice : '',
                formatDateToString(new Date(item.data.timestamp), true),
                item.data.creator.name,
                item.data.recipient.name,
                item.txHash,
                //item.data.proof.toString(), //vrednost prevzemnice
            ];

            dataset.push(data);
            //console.log('DATA: ', data);
        });
        setDataTableFetchedData(fetchedVCData);
        setDataTableDataSet(dataset);
        console.log('DATASET: ', dataset);
    };

    const getOldQuantityFromTheChain = async (fetchedVCData: FetchedVCDataMulti[]) => {
        console.log('getOldQuantityFromTheChain Started', fetchedVCData);
        if (loading !== true) {
            setLoading(true);
        }
        if (fetchedVCData !== null) {
            //console.log('getOldQuantityFromTheChain Started not null');
            for (const fetchedItem of fetchedVCData) {
                const response: FetchedVCDataMultiNew | null = await getVCDataByIpfsAddress(
                    fetchedItem.data.proof.toString(),
                    currentUser.token,
                );
                //console.log('Response from ipfs', response);
                if (response !== null) {
                    //console.log('Response from getVCDataByIpfsAddress', response);
                    //console.log('Data before: ', fetchedItem.data);
                    fetchedItem.data.oldTimestamp = response.data.timestamp;
                    for (const subject of response.data.subject) {
                        for (const mainSubject of fetchedItem.data.subject) {
                            if (mainSubject.crop._id === subject.crop._id) {
                                mainSubject.oldQuantity = subject.quantity;
                            }
                        }
                    }
                    //console.log('Data after: ', fetchedItem.data);
                }
            }
            //console.log('Activate after all for calls');

            setFetchedVCData(fetchedVCData);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        getVCData();
    }, []);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <PickupOverviewWrapper>
                <Title text="Pregled izvedenih dobav"></Title>
                {dataTableDataSet.length != 0 && (
                    <TblFunc
                        key="TBLFunc"
                        dataSet={dataTableDataSet}
                        columns={PICKUP_NOTE_DATATABLE_COLUMNS}
                        fetchedVCData={dataTableFetchedData}
                    ></TblFunc>
                )}
            </PickupOverviewWrapper>
        </LoadingAnimationWrapper>
    );
};

export default PickupOverview;

const PickupOverviewWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const PDFViewWrapper = styled.div`
    flex: 1 1 auto;
`;

const ContainerMain = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
`;
