import { useState } from 'react';

type ApiState = {
    loading: boolean;
    error: string;
    success: boolean;

    setLoading: (value: boolean) => void;
    setError: (error: string) => void;
    setSuccess: (sucess: boolean) => void;
};

export function useApiState(): ApiState {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<string>('');

    return { loading, error, success, setLoading, setError, setSuccess };
}
