import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { LinkDescription } from 'types/Types';
import { SidebarNavLink } from 'ui/atoms/SidebarNavLink';

export type SidebarNavLinksProps = {
    links: LinkDescription[];
};

const SidebarNavLinks = ({ links }: SidebarNavLinksProps): JSX.Element => {
    return (
        <>
            {links.map(({ tag, path, icon }, index) => {
                if (path === null) return <SidebarNavLinkGroupHeader key={index}>{tag}</SidebarNavLinkGroupHeader>;

                return (
                    <Li key={index}>
                        <SidebarNavLink to={`/${path}`}>
                            <SidebarNavIcon>{icon}</SidebarNavIcon>
                            <>{tag}</>
                        </SidebarNavLink>
                    </Li>
                );
            })}
        </>
    );
};

export default SidebarNavLinks;

const Li = styled.li`
    width: inherit;
    display: list-item;
    text-align: -webkit-match-parent;
`;

const SidebarNavLinkGroupHeader = styled.li`
    color: rgba(255, 255, 255, 0.6);
    padding: 0.75rem 1rem;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    transition: 0.3s;
`;

const SidebarNavIcon = styled.div`
    margin-left: -1rem;
    flex: 0 0 56px;
    height: 1.09375rem;
    font-size: 1.09375rem;
    text-align: center;
    transition: 0.3s;
    fill: currentColor;
`;
