import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FetchedVCDataMulti } from 'types/Types';
import SelectableListItemPickupPrepared from 'ui/molecules/SelectableListItem/PickupPrepared/SelectableListItemPickupPrepared';

export type ListPickupPreparedProps = {
    fetchedPickupData: FetchedVCDataMulti[];
    selected: string | null;
    onSelectionChanged: (ipfsAddress: string) => void;
};

export const ListPickupPrepared = ({
    fetchedPickupData,
    selected,
    onSelectionChanged,
}: ListPickupPreparedProps): ReactElement => {
    return (
        <List>
            {fetchedPickupData.map((pickupData) => (
                <SelectableListItemPickupPrepared
                    key={pickupData.ipfsAddress}
                    fetchedPickupData={pickupData}
                    selected={pickupData.ipfsAddress === selected}
                    onClick={() => onSelectionChanged(pickupData.ipfsAddress)}
                />
            ))}
        </List>
    );
};

export default ListPickupPrepared;

const List = styled.ul`
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #d8dbe0;
    border-top: 1px solid #d8dbe0;
    user-select: none;
`;
