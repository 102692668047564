import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { InputLabel } from 'ui/atoms/InputLabel';
import { StyledInput } from 'ui/atoms/StyledInput';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    label: string;
};

const Input = ({ label, ...prop }: InputProps): ReactElement => {
    const [focused, setFocused] = useState(false);

    const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true);
        console.log('Input Props', prop);
        prop.onFocus && prop.onFocus(e);
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        prop.onBlur && prop.onBlur(e);
    };

    return (
        <InputWrapper className="input">
            <InputLabel
                focused={focused || prop.type === 'date' || (prop.value !== undefined && String(prop.value).length > 0)}
            >
                {label}
                {prop.required && (
                    <>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                )}
            </InputLabel>
            <StyledInput {...prop} onFocus={handleOnFocus} onBlur={handleOnBlur} />
        </InputWrapper>
    );
};

export default Input;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 px;
    position: relative;
    width: 100%;
`;
