import React, { ReactElement } from 'react';
import { useAuth } from 'contexts/AuthContext';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { Footer } from 'ui/organisms/Footer';
import { Login } from 'ui/organisms/Login';
import { Navbar } from 'ui/organisms/Navbar';
import { UserDashboardAdd } from 'ui/pages/Admin/UserDashboardAdd';
import { Harvest } from 'ui/pages/Harvest';
import { Sowing } from 'ui/pages/Sowing';
import { PickupNew } from 'ui/pages/Pickup/New';
import { PickupOverview } from 'ui/pages/Pickup/Overview';
import { PickupPrepared } from 'ui/pages/Pickup/Prepared';
import { PickupScan } from 'ui/pages/Pickup/Scan';
import PrivateRoute from 'utils/PrivateRoute';
import { TemplateApp } from 'ui/templates/TemplateApp';
import useSidebar from 'hooks/useSidebar';
import { createGlobalStyle } from 'styled-components';
import Sidebar from 'ui/organisms/Sidebar/Sidebar';
import Error404 from 'ui/pages/Error404/Error404';
import { PickupNewMulti } from 'ui/pages/Pickup/NewMulti';
import { Profile } from 'ui/pages/Profile';
import { UserDashboardManage } from 'ui/pages/Admin/UserDashboardManage';
import { SowingUpdate } from 'ui/pages/SowingUpdate';
import { FinishedSowingList } from 'ui/pages/FinishedSowingList';
import { Home } from 'ui/pages/Home';
import { UserDashboardActivityLogs } from 'ui/pages/Admin/UserDashboardActivityLogs';

export const GlobalStyles = createGlobalStyle`
    :root {
        /* FONTS */
        --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Helvetica Neue', 'Arial', 'Noto Sans', 'sans-serif';
        --font-family-monospace: 'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', 'monospace';

        /* COLORS */
        --primary: #321fdb;
        --secondary: #ced2d8;
        --success: #2eb85c;
        --info: #39f;
        --warning: #f9b115;
        --danger: #e55353;
        --light: #ebedef;
        --dark: #636f83;
        --color: #3c4b64;
        --muted-color: #768182;

        /* RGB */
        --primary-rgb: 50 31 219;
        
        /* BREAKPOINTS */
        --breakpoint-xs: 0;
        --breakpoint-sm: 576px;
        --breakpoint-md: 768px;
        --breakpoint-lg: 992px;
        --breakpoint-xl: 1200px;
        --breakpoint-xxl: 1400px;

    }

    * {
        margin: 0;
        padding: 0;
    }

    body {
        overflow-x: hidden;
        font-family: var(--font-family-sans-serif);
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        text-align: left;
        background-color: var(--light);
        color: var(--color);
    }

    code {
        font-family: var(---font-family-monospace)
    }
`;

const App = (): ReactElement => {
    const { isAuthenticated } = useAuth();
    const { isSidebarVisible, toggleSidebar } = useSidebar();

    return (
        <>
            <GlobalStyles />
            <Router basename={'/blockis'}>
                <LoadingAnimationWrapper fullpage loading={isAuthenticated === null}>
                    <TemplateApp
                        sidebar={<Sidebar toggleSidebar={toggleSidebar} />}
                        header={<Navbar toggleSidebar={toggleSidebar} />}
                        body={
                            <Switch>
                                <Route exact path="/">
                                    <Login />
                                </Route>
                                <PrivateRoute path="/home" userTypes={['PRIDELOVALEC', 'PREVOZNIK', 'KOV', 'ADMIN']}>
                                    <Home />
                                </PrivateRoute>
                                <PrivateRoute path="/harvest" userTypes={['PRIDELOVALEC']}>
                                    <Harvest />
                                </PrivateRoute>
                                <PrivateRoute path="/sowing" userTypes={['PRIDELOVALEC']}>
                                    <Sowing />
                                </PrivateRoute>
                                <PrivateRoute path="/sowingupdate" userTypes={['PRIDELOVALEC']}>
                                    <SowingUpdate />
                                </PrivateRoute>
                                <PrivateRoute path="/sowingfinished" userTypes={['PRIDELOVALEC']}>
                                    <FinishedSowingList />
                                </PrivateRoute>
                                <PrivateRoute path="/pickup/create" userTypes={['PRIDELOVALEC']}>
                                    <PickupNew />
                                </PrivateRoute>
                                <PrivateRoute path="/pickup/createMulti" userTypes={['PREVOZNIK']}>
                                    <PickupNewMulti />
                                </PrivateRoute>
                                <PrivateRoute path="/pickup/get" userTypes={['PRIDELOVALEC', 'PREVOZNIK', 'KOV']}>
                                    <PickupPrepared />
                                </PrivateRoute>
                                <PrivateRoute path="/pickup/scan" userTypes={['PREVOZNIK', 'KOV']}>
                                    <PickupScan />
                                </PrivateRoute>
                                <PrivateRoute path="/pickup/overview" userTypes={['PRIDELOVALEC', 'PREVOZNIK', 'KOV']}>
                                    <PickupOverview />
                                </PrivateRoute>
                                <PrivateRoute path="/admin/user_dashboard_add" userTypes={['ADMIN']}>
                                    <UserDashboardAdd />
                                </PrivateRoute>
                                <PrivateRoute path="/admin/user_dashboard_manage" userTypes={['ADMIN']}>
                                    <UserDashboardManage />
                                </PrivateRoute>
                                <PrivateRoute path="/admin/user_dashboard_activitylog" userTypes={['ADMIN']}>
                                    <UserDashboardActivityLogs />
                                </PrivateRoute>
                                <PrivateRoute path="/profile" userTypes={['PRIDELOVALEC', 'PREVOZNIK', 'KOV', 'ADMIN']}>
                                    <Profile />
                                </PrivateRoute>
                                <Route exact path="/404">
                                    <Error404 />
                                </Route>
                                <Redirect to="/404" />
                            </Switch>
                        }
                        footer={<Footer />}
                        isSidebarVisible={isSidebarVisible}
                    />
                </LoadingAnimationWrapper>
            </Router>
            <div id="toast-notification"></div>
        </>
    );
};

export default App;
