import React from 'react';
import styled from 'styled-components';
import { StyledLink } from 'ui/atoms/StyledLink';

export type SuccessMessageProps = {
    message: string;
    txLink?: string | null;
};

const SuccessMessage = ({ message, txLink }: SuccessMessageProps): JSX.Element => {
    return (
        <Div>
            <span>{message}</span>
            {txLink && (
                <StyledLink href={txLink} target="_blank" rel="noopener noreferrer">
                    Blockchain Explorer
                </StyledLink>
            )}
        </Div>
    );
};

export default SuccessMessage;

const Div = styled.div`
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
`;
