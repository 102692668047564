import { redirectToBCExplorer } from 'helpers/helper-functions';
import React, { ReactElement } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import styled, { css } from 'styled-components';
import { Button } from '../Button';

export type ToastProps = {
    position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
    type: 'success' | 'danger';
    message?: string;
    subtext?: string;
    BCtxHash?: string;
};

export const showToast = (props: ToastProps) => {
    const toast = Toast({
        position: props.position,
        message: props.message,
        type: props.type,
        BCtxHash: props.BCtxHash,
    });
    const root = document.getElementById('toast-notification');
    return render(toast, root);
};

export const closeToast = () => {
    const container = document.getElementById('toast-notification');
    return render(<div></div>, container);
};

const Toast = ({ position, message, subtext, BCtxHash, ...props }: ToastProps): ReactElement => {
    const redirect = () => {
        if (BCtxHash != undefined) {
            redirectToBCExplorer(BCtxHash);
        }
    };
    return (
        <NotificationContainer id="toaster" className={position}>
            <NotificationToast className={props.type}>
                <ToastBody>
                    <CloseButtonWrapper>
                        <Button text="X" btnType="primary-outline" onClick={closeToast}></Button>
                    </CloseButtonWrapper>
                    <MessageWrapper>{message}</MessageWrapper>
                    {BCtxHash != undefined && (
                        <BCButtonWrapper>
                            <Button text="BC Explorer" btnType="secondary" onClick={redirect}></Button>
                        </BCButtonWrapper>
                    )}
                </ToastBody>
            </NotificationToast>
        </NotificationContainer>
    );
};

const NotificationContainer = styled.div`
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: 2000;
    height: 50px;

    &&.top-right {
        top: 50px;
        right: 20px;
        transition: transform 0.6s ease-in-out;
        animation: toast-in-right 0.7s;
    }

    &&.bottom-right {
        bottom: 50px;
        right: 20px;
        transition: transform 0.6s ease-in-out;
        animation: toast-in-right 0.7s;
    }

    &&.top-left {
        top: 50px;
        left: 20px;
        transition: transform 0.6s ease-in;
        animation: toast-in-left 0.7s;
    }

    &&.bottom-left {
        bottom: 50px;
        left: 20px;
        transition: transform 0.6s ease-in;
        animation: toast-in-left 0.7s;
    }
`;

const NotificationToast = styled.div`
    display: flex;
    min-width: 100px;
    height: 100%;
    font-weight: bold;
    width: 350px;
    max-width: 100%;
    font-size: 0.875rem;
    color: black;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid black;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 0.375rem;

    &&.danger {
        background-color: #d9534f;
    }

    &&.success {
        background-color: #5cb85c;
    }
`;

const ToastBody = styled.div`
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 100%;
`;
const CloseButtonWrapper = styled.div`
    width: 10%;
`;
const MessageWrapper = styled.div`
    width: 70%;
    padding: 6px;
`;
const BCButtonWrapper = styled.div`
    width: 40%;
`;

export default Toast;
