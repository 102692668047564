import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
    text-decoration: none;
    background-color: transparent;
    color: var(--primary);
`;

export default StyledLink;
