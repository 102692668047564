export class TextConstant {
    static COMPLETED_SOWING_DESCRIPTION =
        'Pregled zaključenih pridelav / predelav, kateri pridelki so bili v celoti spravljeni.';
    static EXISTING_SOWING = 'Obstoječa pridelava / predelava';
    static CHOOSE_SOWING = 'Izberite pridelavo / predelavo';
    static CHOOSE_CROP = 'Izberite pridelek / proizvod';
    static CONFIRM_CROP = 'Potrdi pridelek / proizvod';
    static CROP = 'Pridelek / proizvod';
    static ADD_SOWING_ACT = 'Dodajte stanje pridelave / predelave';
    static DATE_OF_SOWING = 'Datum pridelave / predelave:';
    static PLACE_OF_SOWING = 'Kraj pridelave / predelave:';
    static DRAWER_IDS_SOWING = 'DataIDS pridelave / predelave:';
    static CREATE_SOWING = 'Kreiraj pridelavo / predelavo';
    static CREATE_SOWING_SUCCESS = 'Uspešno ustvarjena pridelava / predelava';
    static INSERT_SOWING_DATA = 'Vnesite podatke o pridelavi';
    static IMAGE_ADD_SOWING_SUCCESS = 'Slike so bile uspešno dodane k pridelavi / predelavi';
    static IMAGE_INFO_ADD_SOWING_SUCCESS = 'Opisi so bile uspešno dodani k pridelavi / predelavi';
    static HISTORY_SOWING = 'Zgodovina pridelave / predelave';
    static COMPLETE_SOWINGS = 'Zaključi pridelavo / predelavo';
    static COMPLETED_SOWING = 'Zaključena pridelava / predelava';
    static COMPLETED_SOWINGS = 'Zaključene pridelave / predelave';
    static UPDATE_SOWING_SUCCESS = 'Uspešno posodobljena pridelava / predelava';
    static CREATE_SOWING_DESCRIPTION = 'Kreiraj novo pridelavo / predelavo.';
    static ADD_SOWING_ACTIVITY = 'Dodaj aktivnost k pridelavi / predelavi';
    static ADD_SOWING_ACTIVITY_DESC = 'Vnesi podatke o aktivnostih obstoječe pridelave / predelave.';
    static FINISH_SOWING_QUESTION = 'Ste prepričani da želite zaključiti pridelavo / predelavo.';
    static FINISH_SOWING_DESC = 'Če zaključite pridelavo / predelavo, za ta pridelek ne bo več na voljo spravilo';
    static ADD_SOWING_IMAGE_DESC = 'Dobro bi bilo opremiti pridelavo / predelavo z kakšno sliko.';
}
