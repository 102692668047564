import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Crop } from 'types/Types';

export type ListItemCropProps = {
    crop: Crop;
};

const ListItemCrop = ({ crop: { _id, name } }: ListItemCropProps): ReactElement => {
    return (
        <ListItem>
            <div>
                {_id} - {name}
            </div>
        </ListItem>
    );
};

export default ListItemCrop;

const ListItem = styled.div`
    padding: 1rem;
    align-self: center;
`;
