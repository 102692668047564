import React from 'react';
import styled, { css } from 'styled-components';

export type SelectableItemProps = {
    selected: boolean;
};

const Item = styled.li`
    display: flex;
    list-style-type: none;
    position: relative;
    border: 1px solid;
    box-sizing: border-box;
    border-top-width: 0;
    background-color: inherit;
    border-color: rgba(0, 0, 21, 0.125);
    text-align: inherit;
    color: var(--muted-color);
`;

const SelectableItem = styled(Item)<SelectableItemProps>`
    &:hover {
        z-index: 1;
        text-decoration: none;
        color: #08100c !important;
        //background-color: #ebedea !important;
        font-weight: 700;
    }

    &:nth-of-type(even) {
        color: #768192;
        background-color: #ebedef;
    }

    ${({ selected }) =>
        selected &&
        css`
            color: #fff !important;
            background-color: var(--dark) !important;
        `}
`;

export default SelectableItem;
