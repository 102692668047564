import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    icon?: JSX.Element;
    iconPosition?: 'right' | 'left';
    text: string;
    btnType?: 'primary' | 'secondary' | 'primary-outline' | 'multiple-choices';
};

const Button = ({ icon, iconPosition, text, ...props }: ButtonProps): ReactElement => {
    return (
        <ButtonWrapper {...props}>
            {iconPosition === 'left' && icon && <>{icon}</>}
            {text}
            {iconPosition === 'right' && icon && <>{icon}</>}
        </ButtonWrapper>
    );
};

export default Button;

interface ExtraButtonProps {
    readonly btnType?: 'primary' | 'secondary' | 'primary-outline' | 'multiple-choices';
}

const primary = css`
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);

    &:hover:enabled {
        background-color: #2a1ab9;
        border-color: #2819ae;
    }

    &:active {
        background-color: #2819ae;
        border-color: #2517a3;
    }

    &:focus {
        background-color: #2a1ab9;
        border-color: #2819ae;
        box-shadow: 0 0 0 0.2rem rgb(81 65 224 / 50%);
    }
`;

const multiplechoices = css`
    color: #fff;
    background-color: var(--info);
    border-color: var(--info);

    &:hover:enabled {
        background-color: #2a1ab9;
        border-color: #2819ae;
    }

    &:active {
        background-color: #2819ae;
        border-color: #2517a3;
    }

    &:focus {
        background-color: #2a1ab9;
        border-color: #2819ae;
        box-shadow: 0 0 0 0.2rem rgb(81 65 224 / 50%);
    }
`;

const primaryOutline = css`
    color: var(--primary);
    border-color: var(--primary);
    background-color: #fff;

    &:hover:enabled {
        color: #fff;
        background-color: var(--primary);
    }

    &:active {
        color: #fff;
        background-color: var(--primary);
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgb(var(--primary-rgb) / 50%);
    }
`;

const secondary = css`
    color: #4f5d73;
    background-color: var(--secondary);
    border-color: var(--secondary);

    &:hover:enabled {
        background-color: #b9bec7;
        border-color: #b2b8c1;
    }

    &:active {
        background-color: #b2b8c1;
        border-color: #abb1bc;
    }

    &:focus {
        background-color: #b9bec7;
        border-color: #b2b8c1;
        box-shadow: 0 0 0 0.2rem rgb(187 192 201 / 50%);
    }
`;
const ButtonWrapper = styled.button<ExtraButtonProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-transform: none;

    &:focus {
        box-shadow: 0 0 0 0.2rem rgb(var(--primary-rgb) / 25%);
        outline: 0;
    }

    &:disabled {
        opacity: 0.65;
    }

    ${({ btnType }) =>
        (btnType === 'primary' && primary) ||
        (btnType === 'secondary' && secondary) ||
        (btnType === 'primary-outline' && primaryOutline) ||
        (btnType === 'multiple-choices' && multiplechoices)};
`;
