import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from 'contexts/AuthContext';
import { Button } from 'ui/atoms/Button';
import { API_ADDRESS } from 'config/config';
import Select from 'react-select';
import styled from 'styled-components';
import { User } from 'types/Types';
import { ChangePasswordAdmin } from '../ChangePasswordAdmin';
import { useApiState } from 'hooks/useApiState';

export type EditUserInfoProps = {
    user: User;
};

const EditUserInfo = ({ user }: EditUserInfoProps): JSX.Element => {
    const { currentUser } = useAuth();
    const [newUser, setNewUser] = useState<any>(null);
    const [currentAction, setCurrentAction] = useState(0);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();

    const cancel = () => {
        setCurrentAction(0);
    };

    const changePassword = (newPassword: string) => {
        const data = {
            userId: user._id,
            newPassword: newPassword,
        };

        axios
            .post(
                `${API_ADDRESS}/user/admin/update/password`,
                { data: data },
                {
                    headers: { Authorization: `Bearer ${currentUser.token}` },
                },
            )
            .then((res) => {
                console.log(res);
            })
            .catch((err) => setError(err))
            .finally(() => {
                setLoading(false);
                setCurrentAction(0);
            });
    };

    return (
        <Wrapper>
            {currentAction === 0 && (
                <div>
                    <div>Uporabniško ime: {user.username}</div>
                    <div>Elektronski naslov: {user.email}</div>
                    <div>Tip uporabnika: {user.userType}</div>
                    {user.ethWallet && <div>ETH naslov: {user.ethWallet.address}</div>}
                    <Button text="Spremeni geslo" btnType="primary" onClick={() => setCurrentAction(1)} />
                </div>
            )}
            {currentAction === 1 && <ChangePasswordAdmin cancel={cancel} changePassword={changePassword} />}
        </Wrapper>
    );
};

export default EditUserInfo;

const Wrapper = styled.div`
    margin: 10px;
    flex: 1 1 auto;
`;
