import React, { CSSProperties } from 'react';
import { User } from 'types/Types';
import { ListItemUser } from 'ui/atoms/ListItem/User';
import SelectableItem from 'ui/atoms/SelectableItem/SelectableItem';

export type SelectableListItemUserProps = {
    user: User;
    selected: string | null; // TODO: Change to boolean ?
    onSelectionChanged: React.Dispatch<React.SetStateAction<string | null>>;
    style: CSSProperties;
};

const SelectableListItemUser = ({
    user,
    onSelectionChanged,
    selected,
    style,
}: SelectableListItemUserProps): JSX.Element => {
    return (
        <SelectableItem
            style={style}
            key={user._id}
            onClick={() => onSelectionChanged(user._id)}
            selected={user._id === selected}
        >
            <ListItemUser user={user} />
        </SelectableItem>
    );
};

export default SelectableListItemUser;
