import React, { useContext, useEffect, useState } from 'react';
import { API_ADDRESS } from 'config/config';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { OptionsKOV, Wallet } from 'types/Types';
import { useApiState } from 'hooks/useApiState';

export type UserType = 'PRIDELOVALEC' | 'PREVOZNIK' | 'KOV' | 'ADMIN';

type AuthProviderProps = {
    children?: React.ReactNode;
};

export type ILogin = {
    username: string;
    password: string;
    kov: OptionsKOV;
};

type IUser = {
    id: string;
    username: string;
    email: string;
    userType: UserType;
    ethWallet: Wallet;
    token: string;
    kov: string;
};

const initialUserInfo: IUser = {
    id: '',
    username: '',
    email: '',
    userType: 'PRIDELOVALEC',
    ethWallet: { address: '' },
    token: '',
    kov: '',
};

export type IAuthContext = {
    isAuthenticated: boolean | null;
    currentUser: IUser;
    loading: boolean;
    error: string;
    login: (data: ILogin) => void;
    logout: () => void;
};

const AuthContext = React.createContext<IAuthContext>({
    isAuthenticated: false,
    currentUser: initialUserInfo,
    loading: true,
    error: '',
    login: () => {
        return;
    },
    logout: () => {
        return;
    },
});

export function useAuth(): IAuthContext {
    return useContext(AuthContext);
}

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [currentUser, setCurrentUser] = useState<IUser>(initialUserInfo);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const { error, loading, setLoading, setError } = useApiState();

    function login({ kov, ...data }: ILogin): void {
        setLoading(true);
        axios({ method: 'post', url: `${API_ADDRESS}/user/login`, data: { ...data, kov: kov.value } })
            .then((res) => {
                const { id, email, userType, token, username, ethWallet } = res.data;
                localStorage.setItem('token', token);
                localStorage.setItem('kov', kov.label);
                setCurrentUser({
                    id: id,
                    email: email,
                    userType: userType,
                    token: token,
                    username: username,
                    ethWallet: ethWallet,
                    kov: kov.label,
                });
                setIsAuthenticated(true);
            })
            .catch((err) => {
                if (isAuthenticated === null) setIsAuthenticated(false);
                if (err.response) {
                    setError(err.response.data.message);
                } else {
                    setError('Unknown error occurred!');
                }
            })
            .then(() => {
                setLoading(false);
            });
    }

    function logout(): void {
        setLoading(true);
        localStorage.removeItem('token');
        localStorage.removeItem('kov');
        localStorage.removeItem('vascoUserID');
        setIsAuthenticated(false);
        setLoading(false);
    }

    function profile(token: string): void {
        setLoading(true);
        axios({
            method: 'get',
            url: `${API_ADDRESS}/user/profile`,
            headers: { Authorization: `Bearer ${token}` },
        })
            .then((res) => {
                const { id, email, userType, username, ethWallet } = res.data;
                const kov = localStorage.getItem('kov');
                if (kov === null || kov === '') {
                    logout();
                    return;
                }
                setCurrentUser({
                    id: id,
                    email: email,
                    userType: userType,
                    token: token,
                    username: username,
                    ethWallet: ethWallet,
                    kov: kov,
                });
                setIsAuthenticated(true);
            })
            .catch((err) => {
                setError(err);
                localStorage.clear();
                setIsAuthenticated(false);
            })
            .then(() => {
                setLoading(false);
            });
    }

    function checkIfTokenValid(token: string): boolean {
        if (new Date().getTime() / 1000 > parseInt(jwt_decode<{ exp: string; iat: string; id: string }>(token).exp))
            return false;
        return true;
    }

    function gotValidToken(): void {
        const token = localStorage.getItem('token');
        if (token && checkIfTokenValid(token)) {
            profile(token);
        } else {
            setIsAuthenticated(false);
        }
    }

    useEffect(() => {
        gotValidToken();
    }, []);

    const value = {
        isAuthenticated: isAuthenticated,
        currentUser: currentUser,
        loading: loading,
        error: error,
        login: login,
        logout: logout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
