import React, { useState, CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { MdKeyboardArrowLeft, MdKeyboardArrowDown } from 'react-icons/md';

export type ExpandableItemProps = {
    header: JSX.Element;
    itemContent: JSX.Element;
    style?: CSSProperties;
};

const ExpandableItem = ({ header, itemContent, style }: ExpandableItemProps): JSX.Element => {
    const [expanded, setExpanded] = useState(false);

    function toggleExpand() {
        setExpanded((prevState) => !prevState);
    }

    return (
        <ExpandableItemWrapper style={style}>
            <ExpandableItemMainWrapper>
                <ExpandableItemHeader>
                    <h6>{header}</h6>
                </ExpandableItemHeader>
                <ExpandCollapseButton onClick={toggleExpand}>
                    {expanded ? <MdKeyboardArrowDown /> : <MdKeyboardArrowLeft />}
                </ExpandCollapseButton>
            </ExpandableItemMainWrapper>
            <ExpandableItemContent hidden={!expanded}>
                <ContentWrapper>{itemContent}</ContentWrapper>
            </ExpandableItemContent>
        </ExpandableItemWrapper>
    );
};

export default ExpandableItem;

const ContentWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 20px;
`;

const ExpandableItemWrapper = styled.li`
    display: flex;
    flex-direction: column;
    border: 1px solid;
    box-sizing: border-box;
    background-color: inherit;
    border-color: rgba(0, 0, 21, 0.125);
    text-align: inherit;
    color: var(--muted-color);
    list-style: none;
`;

const ExpandCollapseButton = styled.button`
    background-color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 44px;
    color: var(--muted-color);
    border-color: var(--muted-color);
    outline: 0;
    min-width: 44px;
    font-size: 1.09375rem;
    border: 0;
    border-radius: 50%;
    margin-left: auto;

    &:hover {
        color: var(--color);
    }
`;

const ExpandableItemContent = styled.div`
    z-index: 1000;
    min-height: 60px;
    background-color: #efefef;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 21, 0.125);
`;

const ExpandableItemHeader = styled.div`
    padding: 0.5rem 1.25rem;
    width: 100%;
    display: flex;
    align-items: center;
`;

const ExpandableItemMainWrapper = styled.div`
    display: flex;
    min-height: 60px;
    flex: 1 1 auto;
    align-items: center;
`;
