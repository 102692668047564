import React, { MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import { BrowserQRCodeReader, IScannerControls } from '@zxing/browser';
import { Button } from '../../atoms/Button';
import styled from 'styled-components';
import Select from 'react-select';

type OptionsCamera = {
    value: string;
    label: string;
};

export type ScannerProps = {
    result: string | null;
    setResult: (result: string | null) => void;
};

const Scanner = ({ result, setResult }: ScannerProps): ReactElement => {
    const refVideo = useRef() as MutableRefObject<HTMLVideoElement>;
    const refControls = useRef<IScannerControls | null>();
    const [controls, setControls] = useState<IScannerControls | null>(null);
    const [availableCameras, setAvailableCameras] = useState<MediaDeviceInfo[]>([]);
    const [selectedCamera, setSelectedCamera] = useState<MediaDeviceInfo | null>(null);
    const [optionsCamera, setOptionsCamera] = useState<OptionsCamera[]>([]);

    const checkVideoInputDevices = async () => {
        const listOfVideoInputDevices = await BrowserQRCodeReader.listVideoInputDevices();
        setAvailableCameras(listOfVideoInputDevices);

        for (let i = 0; i < listOfVideoInputDevices.length; i++) {
            setOptionsCamera((prevState) => [
                ...prevState,
                { value: i.toString(), label: listOfVideoInputDevices[i].label },
            ]);
        }

        if (listOfVideoInputDevices.length > 0) {
            setSelectedCamera(listOfVideoInputDevices[0]);
        }
    };

    async function initScanner() {
        const reader = new BrowserQRCodeReader();
        setControls(
            await reader.decodeFromVideoDevice(selectedCamera?.deviceId, refVideo.current, (res, err) => {
                if (res && result == null) {
                    setResult(JSON.parse(res));
                    setControls(null);
                }
            }),
        );
    }

    useEffect(() => {
        checkVideoInputDevices();
        return () => {
            refControls.current?.stop();
        };
    }, []);

    useEffect(() => {
        refControls.current = controls;
    }, [controls]);

    return (
        <>
            {result === null && (
                <div>
                    <VideoContainer>
                        <Video ref={refVideo} />
                    </VideoContainer>
                    {availableCameras.length > 0 && (
                        <ContainerBottom>
                            <ContainerButton>
                                <Button
                                    text="Start"
                                    onClick={() => {
                                        initScanner();
                                    }}
                                    btnType="primary"
                                />
                            </ContainerButton>
                            <ContainerButton>
                                <Button
                                    text="Stop"
                                    onClick={() => {
                                        controls?.stop();
                                        setControls(null);
                                    }}
                                    btnType="primary"
                                />
                            </ContainerButton>
                        </ContainerBottom>
                    )}
                    {availableCameras.length > 1 && (
                        <ContainerCameraSelector>
                            <StyledSelect
                                options={optionsCamera}
                                onChange={(opt: OptionsCamera) => {
                                    setSelectedCamera(availableCameras[parseInt(opt.value)]);
                                }}
                            />
                            {/* {availableCameras.map((videoInputDevice, i) => (
                                <div
                                    key={videoInputDevice.deviceId}
                                    onClick={() => {
                                        setSelectedCamera(availableCameras[i]);
                                    }}
                                >
                                    {videoInputDevice.label}
                                </div>
                            ))} */}
                        </ContainerCameraSelector>
                    )}
                </div>
            )}
        </>
    );
};

export default Scanner;

const ContainerCameraSelector = styled.div`
    margin: 30px;
`;

const ContainerBottom = styled.div`
    margin: 30px;
`;

const ContainerButton = styled.div`
    margin-bottom: 10px;
`;

const VideoContainer = styled.div`
    display: flex;
    margin-top: 10px;
`;

const Video = styled.video`
    max-width: 100%;
`;

const StyledSelect = styled(Select)`
    z-index: 1000;
    margin-top: 20px;
    margin-bottom: 21px;
`;
