import axios from 'axios';
import { Button } from 'ui/atoms/Button';
import React, { ReactElement } from 'react';
import { HarvestData, LoadedFiles, SowingData } from 'types/Types';
import { API_ADDRESS } from 'config/config';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { useAuth } from 'contexts/AuthContext';
import styled from 'styled-components';
import SuccessMessage from 'ui/molecules/SuccessMessage/SuccessMessage';
import { Title } from 'ui/atoms/Title';
import { getArrayOfCropsIdsForSowing, getCropsNamesFromSowing } from '../../../helpers/helper-functions';
import { TextConstant } from '../../../config/text-constant';

type ConfirmSowingProps = {
    sowingData: SowingData;
    ipfsPaths: string[];
    isUpdate: boolean;
    files: LoadedFiles[];
    setSuccess: (data: boolean) => void;
    setLoading: (data: boolean) => void;
    setError: (data: string) => void;
    setTxLink: React.Dispatch<React.SetStateAction<string | null>>;
    setImageLoading: React.Dispatch<React.SetStateAction<boolean> | false>;
};

type DataVC1 = {
    subject: {
        products: string[];
        place: string;
        date: string;
        GERK: string;
        comment: string;
    };
};

type UpdateSowingData = {
    ipfsPath: string;
    status: number;
};

const prepareFileImagesToRender = (filesIn: LoadedFiles[]) => {
    console.log('Files in confirmation', filesIn);
    const imageArray = [];
    for (const file of filesIn) {
        imageArray.push(<img src={file.preview} alt="" key={file.preview} />);
    }
    return imageArray;
};

const ConfirmSowing = ({
    sowingData,
    ipfsPaths,
    isUpdate,
    files,
    setSuccess,
    setLoading,
    setError,
    setTxLink,
    setImageLoading,
}: ConfirmSowingProps): ReactElement => {
    const { currentUser } = useAuth();
    const fileArray = prepareFileImagesToRender(files);
    const confirmSowingHandler = () => {
        // TODO: Validate data before sending
        if (isUpdate) {
            const updateData: UpdateSowingData = {
                ipfsPath: sowingData.ipfsAddress,
                status: sowingData.status,
            };

            updateSowing(updateData, files);
        } else {
            confirmSowing(
                {
                    subject: {
                        products: getArrayOfCropsIdsForSowing(sowingData),
                        place: sowingData.location,
                        date: sowingData.date,
                        GERK: sowingData.GERK,
                        comment: sowingData.comment,
                    },
                },
                files,
            );
        }
    };

    const updateSowing = (data: UpdateSowingData, files: LoadedFiles[]) => {
        setLoading(true);
        console.log('Update SOWING UPDATE: DATA: ', data);
        axios
            .put(
                `${API_ADDRESS}/sowing/`,
                {
                    data,
                },
                { headers: { Authorization: `Bearer ${currentUser.token}` } },
            )
            .then((res) => {
                setTxLink(res.data.txLink);
                sowingCreateData(data.ipfsPath, files);
                setSuccess(true);
            })
            .catch((err) => setError(err))
            .then(() => {
                setLoading(false);
            })
            .finally(() => {
                console.log('Finishing updating sowing');
            });
    };

    const confirmSowing = (data: DataVC1, files: LoadedFiles[]) => {
        setLoading(true);
        axios
            .post(
                `${API_ADDRESS}/sowing/`,
                {
                    data: data,
                },
                { headers: { Authorization: `Bearer ${currentUser.token}` } },
            )
            .then((res) => {
                console.log('Response from POST sowing: ', res);
                setTxLink(res.data.txLink);
                console.log('Ipfs paths array 1', ipfsPaths);

                console.log('Inside', files.length != 0);
                setImageLoading(true);
                const timer = setTimeout(() => {
                    console.log('Ipfs paths array', files);
                    sowingCreateData(res.data.ipfsAddress, files);
                    console.log('This will run after 10s');
                }, 10000);
                setSuccess(true);
            })
            .catch((err) => setError(err))
            .finally(() => {
                setLoading(false);
                console.log('Finishing confirming sowing');
            });
    };

    const getSowing = (ipfsPath: string) => {
        axios
            .get(`${API_ADDRESS}/sowing/${ipfsPath}`, { headers: { Authorization: `Bearer ${currentUser.token}` } })
            .then((res) => {
                console.log('GET sowing response: ', res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };
    /**
     * Creates additional data placeholder for thesowing
     * @param data
     */
    const sowingCreateData = (ipfsPathIn: string, files: LoadedFiles[]) => {
        //setLoading(true);
        console.log('CREATE SOWING DATA:');
        axios
            .post(
                `${API_ADDRESS}/sowing/createData`,
                {
                    data: { ipfsPath: ipfsPathIn },
                },
                { headers: { Authorization: `Bearer ${currentUser.token}` } },
            )
            .then((res) => {
                console.log('SUCCESSfully created data ', res);
                addImageDescriptionToTransactionDrawer(res.data.id, sowingData)
                    .then((response) => {
                        if (files.length != 0) {
                            addImageToTransactionDrawer(res.data.id, files);
                        }
                        setImageLoading(false);
                        setTxLink(res.data.txLink);
                        //setSuccess(true);
                    })
                    .catch((error) => setError(error));
            })
            .catch((err) => setError(err))
            .finally(() => {
                //setLoading(false);
            });
    };

    /**
     * Creates additional data placeholder for thesowing
     * @param data
     */
    const addImageToTransactionDrawer = (id: string, files: LoadedFiles[]) => {
        //setLoading(true);
        const form = new FormData();
        //setLoading(true);
        form.append('id', id);
        for (let i = 0; i < files.length; i++) {
            form.append('images_ipfs', files[i].data);
        }
        axios
            .post(`${API_ADDRESS}/images`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${currentUser.token}`,
                },
            })
            .then((res) => {
                // console.log('SUCCESSfully added images to transaction drawer ', res);
                setTxLink(res.data.txLink);
                setSuccess(true);
            })
            .catch((err) => setError(err));
        //setLoading(false);
    };

    /**
     * Creates additional data placeholder for thesowing
     * @param drawerId id of the drawer
     */
    const addImageDescriptionToTransactionDrawer = (drawerId: string, sowingDataIn: SowingData) =>
        new Promise((resolve, reject) => {
            console.log('Sowing data for image info: ', sowingDataIn);
            console.log('Sowing data for image info: ', sowingDataIn.date);
            console.log(
                'Sowing date for image info: ',
                Math.floor(new Date(sowingDataIn.date).getTime() / 1000).toString(),
            );
            console.log('Sowing date for image info: ', new Date(sowingDataIn.date));
            axios
                .post(
                    `${API_ADDRESS}/imageInfo`,
                    {
                        data: {
                            id: drawerId,
                            description: sowingDataIn.comment,
                            inputDate: Math.floor(new Date(sowingDataIn.date).getTime() / 1000).toString(),
                            field1: '',
                            field2: '',
                            field3: '',
                        },
                    },
                    { headers: { Authorization: `Bearer ${currentUser.token}` } },
                )
                .then((res) => {
                    resolve(res);
                    // console.log('SUCCESSfully added images to transaction drawer ', res);
                    // setTxLink(res.data.txLink);
                    //setSuccess(true);
                })
                .catch((err) => reject(err));
            //setLoading(false);
        });

    return (
        <ConfirmSowingWrapper>
            {sowingData.crop && (
                <>
                    <div>
                        <strong>Pridelek:</strong> {getCropsNamesFromSowing(sowingData)}
                        <div>
                            <strong>Komentar:</strong> {sowingData.comment}
                        </div>
                        <div>
                            <strong>GERK PID:</strong> {sowingData.GERK}
                        </div>
                        <div>
                            <strong>{TextConstant.PLACE_OF_SOWING}</strong> {sowingData.location}
                        </div>
                        <div>
                            <strong>{TextConstant.DATE_OF_SOWING}</strong> {sowingData.date}
                        </div>
                        {fileArray.length != 0 && (
                            <>
                                <strong>Images:</strong>
                                <Image>{fileArray}</Image>
                            </>
                        )}
                    </div>
                    <BottomWrapper>
                        <Button onClick={confirmSowingHandler} text="Potrdi" btnType="primary-outline" />
                    </BottomWrapper>
                </>
            )}
        </ConfirmSowingWrapper>
    );
};

export default ConfirmSowing;

const ConfirmSowingWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BottomWrapper = styled.div`
    margin-top: auto;
`;

const Image = styled.div`
    display: flex;
    width: 200px;
    height: 200px;
`;
