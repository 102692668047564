import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { LoadedFiles, SowingData } from 'types/Types';
import { Button } from 'ui/atoms/Button';
import ConfirmHarvest from 'ui/organisms/ConfirmHarvest/ConfirmHarvest';
import CreateHarvest from 'ui/organisms/CreateHarvest/CreateHarvest';
import SearchSowings from 'ui/organisms/SearchSowings/SearchSowings';
import axios from 'axios';
import { API_ADDRESS } from 'config/config';
import useStep from 'hooks/useStep';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { GoArrowRight, GoArrowLeft } from 'react-icons/go';
import { useAuth } from 'contexts/AuthContext';
import SuccessMessage from 'ui/molecules/SuccessMessage/SuccessMessage';
import { format, parse } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { StyledLink } from 'ui/atoms/StyledLink';
import CreateSowing from 'ui/organisms/CreateSowing/CreateSowing';
import ConfirmSowing from 'ui/organisms/ConfirmSowing/ConfirmSowing';
import { UpdateSowing } from 'ui/organisms/UpdateSowing';
import { BlockISAPIService } from 'services/blockis-api';
import { Title } from 'ui/atoms/Title';
import { OverviewFinishedSowings } from 'ui/organisms/OverviewFinishedSowings';
import { TextConstant } from '../../../config/text-constant';

let initialStateSowingUpdateData: SowingData = {
    crop: [],
    location: '',
    GERK: '',
    status: 0,
    comment: '',
    date: format(Date.now(), 'yyyy-MM-dd'),
    place: '',
    ipfsAddress: '',
    drawerIDs: [],
};

const FinishedSowingList: React.FC = () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const { step, stepInc, stepDec } = useStep();
    const [sowingDatas, setSowing] = useState<SowingData[]>([]);
    const [selected, setSelected] = useState<SowingData | null>(null);
    //let [selectedSowing, setSelectedSowing] = useState<SowingData>();
    const [sowingData, setSowingUpdateData] = useState<SowingData>(initialStateSowingUpdateData);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();
    const [ipfsPaths, setIpfsPaths] = useState<string[]>([]);
    const [files, setLoadedFiles] = useState<LoadedFiles[]>([]);
    const [txLink, setTxLink] = useState<string | null>(null);
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    //let selectedSowing: SowingData | null = null;

    const setSelectedSowingFromParcel = () => {
        console.log('TEst get sowing from ID:', selected);
        if (selected != null) {
            initialStateSowingUpdateData = selected;
        }
        console.log('SELECTED SOWING DATA:', initialStateSowingUpdateData);
    };

    const getSowing = useCallback(async () => {
        setLoading(true);
        axios
            .get(`${API_ADDRESS}/sowing?status=2`, { headers: { Authorization: `Bearer ${currentUser.token}` } })
            .then((res) => {
                console.log('GET sowing response: ', res);
                const sowingArray: SowingData[] = [];
                for (const data of res.data) {
                    console.log('Sowind data ', data);
                    const sowingObject: SowingData = data.vc.data.subject;
                    sowingObject.ipfsAddress = data.vc.ipfsAddress;
                    sowingObject.status = data.vc.data.status;
                    sowingObject.drawerIDs = data.dataIds;
                    //getSowingByIpfS(data.vc.ipfsAddress);
                    sowingArray.push(sowingObject);
                }

                sowingArray?.sort((a, b) => b.date.localeCompare(a.date));
                console.log('Response from GET sowing', sowingArray);
                setSowing(sowingArray);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }, [sowingDatas]);

    const getSowingByIpfS = (ipfsPath: string) => {
        //setLoading(true);
        axios
            .get(`${API_ADDRESS}/sowing/${ipfsPath}`, { headers: { Authorization: `Bearer ${currentUser.token}` } })
            .then((res) => {
                console.log('GET sowingIPFS RESPONSE: ', res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    /*useEffect(() => {
        setSelectedSowingFromParcel();
    }, [selected]);*/

    useEffect(() => {
        getSowing();
    }, []);

    useEffect(() => {
        setSelectedSowingFromParcel();
        setSowingUpdateData({ ...initialStateSowingUpdateData });
        console.log('Sowing data UPDATED: ', sowingData);
    }, [selected]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <HarvestWrapper>
                {!error && (
                    <>
                        {!success && (
                            <>
                                <ContainerMain>
                                    {step === 0 && (
                                        <StepWrapper>
                                            <Title
                                                text={TextConstant.COMPLETED_SOWING}
                                                subtext={TextConstant.CHOOSE_SOWING}
                                            />
                                            <SearchSowings
                                                onSelectionChanged={setSelected}
                                                sowings={sowingDatas}
                                                selected={selected}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 1 && (
                                        <StepWrapper>
                                            <Title text={TextConstant.COMPLETED_SOWING} subtext="Zgodovina" />
                                            <OverviewFinishedSowings
                                                sowingData={sowingData}
                                                ipfsPaths={ipfsPaths}
                                                isUpdate={true}
                                                files={files}
                                                setSuccess={setSuccess}
                                                setLoading={setLoading}
                                                setError={setError}
                                                setTxLink={setTxLink}
                                                setImageLoading={setImageLoading}
                                            />
                                        </StepWrapper>
                                    )}
                                </ContainerMain>
                                <ContainerBottom>
                                    <ContainerButton pos="left">
                                        <Button
                                            onClick={stepDec}
                                            disabled={step === 0}
                                            iconPosition="left"
                                            icon={<GoArrowLeft />}
                                            text="Nazaj"
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                    {step !== 1 && (
                                        <>
                                            <ContainerButton pos="right">
                                                <Button
                                                    onClick={stepInc}
                                                    disabled={selected === null || step === 1}
                                                    iconPosition="right"
                                                    icon={<GoArrowRight />}
                                                    text="Naprej"
                                                    btnType="primary"
                                                />
                                            </ContainerButton>
                                        </>
                                    )}
                                </ContainerBottom>
                            </>
                        )}
                        {success && (
                            <>
                                <SuccessMessage message={TextConstant.UPDATE_SOWING_SUCCESS} txLink={txLink} />
                                <Button text="Pojdi na začetek" onClick={() => history.push('/')} btnType="primary" />
                            </>
                        )}
                    </>
                )}
            </HarvestWrapper>
        </LoadingAnimationWrapper>
    );
};

export default FinishedSowingList;

const HarvestWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const StepWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;

    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
