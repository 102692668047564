export type CustomShop = {
    userId: number;
    groupId: string;
    groupName: string;
    shopId: string;
    shopName: string;
    vat: string;
};

export const CUSTOM_SHOPS_ARRAY: CustomShop[] = [
    { groupId: '2', groupName: 'Vrtovi Panonski', shopId: '1', shopName: 'Kmetija Wernig', userId: 620, vat: '' },
    { groupId: '2', groupName: 'Vrtovi Panonski', shopId: '2', shopName: 'Kmetija Ratnik Simon', userId: 512, vat: '' },
    { groupId: '2', groupName: 'Vrtovi Panonski', shopId: '3', shopName: 'Kulic Karel', userId: 0, vat: '' },
    {
        groupId: '0001',
        groupName: 'Vrtovi Panonski',
        shopId: '0005',
        shopName: 'Nemet Zvonko',
        userId: 0,
        vat: '98167600',
    },
    {
        groupId: '2',
        groupName: 'Vrtovi Panonski',
        shopId: '1',
        shopName: 'Spaček Test',
        userId: 64,
        vat: '63917661',
    },
];
