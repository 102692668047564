import React from 'react';
import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

const SidebarNavLink = styled(Link)`
    color: hsla(0, 0%, 100%, 0.8);
    background: 0 0;

    cursor: pointer;
    display: flex;
    flex: 1 1;
    align-items: center;
    padding: 0.8445rem 1rem;
    text-decoration: none;
    white-space: nowrap;
    transition: background 0.3s, color 0.3s;
    text-decoration: none;

    &.active {
        color: #fff;
        background: hsla(0, 0%, 100%, 0.05);
    }

    &:hover {
        color: #fff;
        background: #321fdb;
    }

    &:focus {
        outline: none;
    }
`;

export default SidebarNavLink;
