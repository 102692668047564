import React, { ReactElement, useEffect, useState } from 'react';
import axios from 'axios';
import { User, FetchedVCDataMulti } from 'types/Types';
import { API_ADDRESS } from 'config/config';
import styled, { css } from 'styled-components';
import { ListUsers } from 'ui/organisms/List/Users';
import { Button } from 'ui/atoms/Button';
import useStep from 'hooks/useStep';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { useAuth } from 'contexts/AuthContext';
import { GoArrowLeft, GoArrowRight } from 'react-icons/go';
import SuccessMessage from 'ui/molecules/SuccessMessage/SuccessMessage';
import { ListPickupPrepared } from 'ui/organisms/List/PickupPrepared';
import ConfirmPickupMultiCreation from 'ui/organisms/ConfirmPickupMultiCreation/ConfirmPickupMultiCreation';
import { useHistory } from 'react-router-dom';

const PickupNewMulti = (): ReactElement => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const { step, stepInc, stepDec } = useStep();
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [fetchedVCData, setFetchedVCData] = useState<FetchedVCDataMulti[]>([]);
    const [pickupData, setPickupData] = useState<FetchedVCDataMulti | null>(null);
    const [selectedFetchedVCData, setSelectedFetchedVCData] = useState<string | null>(null);
    const [userData, setUserData] = useState<User | null>(null);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();
    const [txLink, setTxLink] = useState<string | null>(null);

    const getUserFromId = () => {
        let data = null;
        if (selectedUser === null) return data;
        for (let i = 0; i < users.length; i++) {
            if (users[i]._id === selectedUser) {
                data = users[i];
                break;
            }
        }
        return data;
    };

    const getPickupDataFromId = () => {
        let data = null;
        if (selectedFetchedVCData === null) return data;
        for (let i = 0; i < fetchedVCData.length; i++) {
            if (fetchedVCData[i].ipfsAddress === selectedFetchedVCData) {
                data = fetchedVCData[i];
                break;
            }
        }
        return data;
    };

    function getVCData(): Promise<any> {
        return axios.get(`${API_ADDRESS}/vc/fetch/vc1`, {
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });
    }

    function getUsers(): Promise<any> {
        return axios.get(`${API_ADDRESS}/user/possible/recipients`, {
            headers: { Authorization: `Bearer ${currentUser.token}` },
        });
    }

    const getData = () => {
        setLoading(true);
        getUsers()
            .then((res) => {
                setUsers(res.data);
            })
            .then(getVCData)
            .then((res) => {
                setFetchedVCData(res.data);
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setUserData(getUserFromId());
    }, [selectedUser]);

    useEffect(() => {
        setPickupData(getPickupDataFromId());
    }, [selectedFetchedVCData]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <PickupWrapper>
                {!error && (
                    <>
                        {!success && (
                            <>
                                <ContainerMain>
                                    {step === 0 && fetchedVCData.length > 0 && (
                                        <ListPickupPrepared // TODO: This is used on 2 places -> should we combine ?
                                            fetchedPickupData={fetchedVCData}
                                            selected={selectedFetchedVCData}
                                            onSelectionChanged={setSelectedFetchedVCData}
                                        />
                                    )}
                                    {step === 1 && (
                                        <ListUsers
                                            users={users}
                                            selected={selectedUser}
                                            onSelectionChanged={setSelectedUser}
                                        />
                                    )}
                                    {step === 2 && userData && pickupData && (
                                        <ConfirmPickupMultiCreation
                                            user={userData}
                                            pickupData={pickupData}
                                            setLoading={setLoading}
                                            setSuccess={setSuccess}
                                            setError={setError}
                                            setTxLink={setTxLink}
                                        />
                                    )}
                                </ContainerMain>
                                <ContainerBottom>
                                    <ContainerButton pos="left">
                                        <Button
                                            onClick={stepDec}
                                            disabled={step === 0}
                                            iconPosition="left"
                                            icon={<GoArrowLeft />}
                                            text="Nazaj"
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            onClick={stepInc}
                                            disabled={
                                                selectedFetchedVCData === null ||
                                                selectedFetchedVCData.length === 0 ||
                                                (step === 1 && selectedUser === null) ||
                                                step === 2
                                            }
                                            iconPosition="right"
                                            icon={<GoArrowRight />}
                                            text="Naprej"
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                </ContainerBottom>
                            </>
                        )}
                        {success && (
                            <>
                                <SuccessMessage message="Uspešno ustvarjena dobavnica" txLink={txLink} />
                                <Button text={'Pojdi na začetek'} onClick={() => history.push('/')} btnType="primary" />
                            </>
                        )}
                    </>
                )}
            </PickupWrapper>
        </LoadingAnimationWrapper>
    );
};

export default PickupNewMulti;

const PickupWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-y: hidden;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;
    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
