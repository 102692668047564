import React, { ReactElement } from 'react';
import { Crop } from 'types/Types';
import styled from 'styled-components';
import SelectableListItemCropMulti from 'ui/molecules/SelectableListItem/CropMulti/SelectableListItemCropMulti';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

export type ListCropsMultiProps = {
    crops: Crop[];
    selected: Crop[];
    onSelectionChanged: React.Dispatch<React.SetStateAction<Crop[]>>;
};

const ItemRenderer = ({ style, index, data }: ListChildComponentProps) => {
    const item = data.items[index];
    const active = data.selected.includes(item);

    return (
        <SelectableListItemCropMulti
            key={index}
            crop={item}
            selected={active}
            onClick={() => data.handleSelectionChanged(item, active)}
            style={style}
        />
    );
};

const ListCropsMulti = ({ crops, selected, onSelectionChanged }: ListCropsMultiProps): ReactElement => {
    const handleSelectionChanged = (item: Crop, active: boolean) => {
        //console.log('Selected Sowing from the list: ', item);
        //console.log('Selected to this moment', selected);

        const tempArray: Crop[] = [];
        let isSelected = false;
        selected.forEach((crop) => {
            if (crop._id == item._id) {
                isSelected = true;
            } else {
                tempArray.push(crop);
            }
        });
        if (!isSelected) {
            tempArray.push(item);
        }
        selected = tempArray;
        //console.log('Selected after active true', selected);
        onSelectionChanged(tempArray);
    };

    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    itemData={{ items: crops, selected: selected, handleSelectionChanged: handleSelectionChanged }}
                    itemSize={60}
                    height={height}
                    width={width}
                    itemCount={crops.length}
                >
                    {ItemRenderer}
                </List>
            )}
        </AutoSizer>
    );
};

export default ListCropsMulti;

const List = styled(FixedSizeList)`
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #d8dbe0;
    border-top: 1px solid #d8dbe0;
`;
