import React, { CSSProperties } from 'react';
import { ExpandableItem } from 'ui/molecules/ExpandableItem';
import { SelectableItem } from 'ui/atoms/SelectableItem';

export type SelectableListItemPartnerProps = {
    partner: any;
    onSelectionChanged: React.Dispatch<React.SetStateAction<number | null>>;
    selected: number | null; // TODO: Change to boolean ?
    style?: CSSProperties;
};

const SelectableListItemPartner = ({
    partner,
    onSelectionChanged,
    selected,
    style,
}: SelectableListItemPartnerProps): JSX.Element => {
    return (
        <ExpandableItem
            header={
                <SelectableItem onClick={() => onSelectionChanged(partner.sifra)} selected={partner.sifra == selected}>
                    {partner.naziv}
                </SelectableItem>
            }
            itemContent={
                <div>
                    <div>{`Davčna: ${partner.davcna}`}</div>
                    <div>{`Email: ${partner.email}`}</div>
                </div>
            }
            style={style}
        />
    );
};

export default SelectableListItemPartner;
