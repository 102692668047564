import React, { useEffect, useState } from 'react';
import { API_ADDRESS } from 'config/config';
import axios from 'axios';
import { useAuth } from 'contexts/AuthContext';
import { Button } from 'ui/atoms/Button';
import CreateUser from 'ui/organisms/CreateUser/CreateUser';
import useStep from 'hooks/useStep';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import styled, { css } from 'styled-components';
import { Partner } from 'types/Types';
import SearchPartners from 'ui/organisms/SearchPartners/SearchPartners';

const UserDashboardAdd = (): JSX.Element => {
    const { currentUser } = useAuth();
    const [partners, setPartners] = useState<Partner[]>([]);
    const [selected, setSelected] = useState<number | null>(null);
    const { step, stepInc, stepDec } = useStep();
    const [selectedPartner, setSelectedPartner] = useState<any>(null);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();

    const getPartners = () => {
        axios
            .get(`${API_ADDRESS}/user/vasco/partnerji`, {
                headers: {
                    Authorization: `Bearer ${currentUser.token}`,
                },
            })
            .then((res) => {
                setPartners(res.data.partners.filter((partner: any) => partner.davcna));
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getPartnerFromId = () => {
        let data = null;
        for (let i = 0; i < partners.length; i++) {
            if (partners[i].sifra === selected) {
                data = partners[i];
                break;
            }
        }
        return data;
    };

    useEffect(() => {
        getPartners();
    }, []);

    useEffect(() => {
        setSelectedPartner(getPartnerFromId());
    }, [selected]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <UserDashboardAddWrapper>
                <ContainerMain>
                    {step === 0 && (
                        <SearchPartners partners={partners} selected={selected} onSelectionChanged={setSelected} />
                    )}
                    {step === 1 && <CreateUser partner={selectedPartner} />}
                </ContainerMain>
                <ContainerBottom>
                    <ContainerButton pos="left">
                        <Button disabled={step === 0} onClick={stepDec} text="Nazaj" btnType="primary" />
                    </ContainerButton>
                    <ContainerButton pos="right">
                        <Button disabled={!selectedPartner} onClick={stepInc} text="Naprej" btnType="primary" />
                    </ContainerButton>
                </ContainerBottom>
            </UserDashboardAddWrapper>
        </LoadingAnimationWrapper>
    );
};

export default UserDashboardAdd;

const UserDashboardAddWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;
    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
        4    `)}
`;
