import axios from 'axios';
import { useAuth } from 'contexts/AuthContext';
import React, { useState } from 'react';
import { Button } from 'ui/atoms/Button';
import { API_ADDRESS } from 'config/config';
import Select from 'react-select';
import styled from 'styled-components';

export type CreateUserProps = {
    partner: any;
};

const options = [
    { value: 'PRIDELOVALEC', label: 'Pridelovalec' },
    { value: 'ADMIN', label: 'Admin' },
    { value: 'KOV', label: 'KOV' },
    { value: 'PREVOZNIK', label: 'Prevoznik' },
];

const CreateUser = ({ partner }: CreateUserProps): JSX.Element => {
    const { currentUser } = useAuth();
    const [newUser, setNewUser] = useState<any>(null);
    const [userType, setUserType] = useState<{ value: string; label: string } | null>(null);

    const createUser = () => {
        const data = {
            username: partner.davcna,
            email: partner.email ? partner.email : null,
            userType: userType?.value,
            sifra: partner.sifra,
            name: partner.naziv,
            idCountry: partner.drzava,
        };
        axios
            .post(`${API_ADDRESS}/user/create`, data, {
                headers: { Authorization: `Bearer ${currentUser.token}` },
            })
            .then((res) => {
                setNewUser({
                    name: partner.naziv,
                    username: partner.davcna,
                    password: res.data.password,
                });
            })
            .catch((err) => console.error(err));
    };

    return (
        <Div>
            {!newUser && (
                <>
                    <Wrapper>
                        <h3>Ustvarjanje računa za:</h3>
                        <div>{partner.naziv}</div>
                    </Wrapper>
                    <StyledSelect value={userType} onChange={setUserType} options={options} />
                </>
            )}
            {newUser && (
                <Wrapper>
                    <h3>Račun uspešno ustvarjen:</h3>
                    <div>Naziv: {newUser.name}</div>
                    <div>Uporabniško ime: {newUser.username}</div>
                    <div>Geslo: {newUser.password}</div>
                </Wrapper>
            )}
            {!newUser && <StyledButton onClick={createUser} text="Ustvari uporabnika" btnType="primary" />}
        </Div>
    );
};

export default CreateUser;

const Wrapper = styled.div`
    margin: 10px;
`;

const StyledButton = styled(Button)`
    margin: 10px;
`;

const StyledSelect = styled(Select)`
    margin: 10px;
`;

const Div = styled.div`
    flex: 1 1 auto;
`;
