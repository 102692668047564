import React, { CSSProperties } from 'react';
import { FetchedVCDataMulti } from 'types/Types';
import ListItemPickupPrepared from 'ui/atoms/ListItem/PickupPrepared/ListItemPickupPrepared';
import SelectableItem from 'ui/atoms/SelectableItem/SelectableItem';

export type SelectableListItemPickupPreparedProps = {
    fetchedPickupData: FetchedVCDataMulti;
    selected: boolean;
    onClick: () => void;
};

const SelectableListItemPickupPrepared = ({
    fetchedPickupData,
    onClick,
    selected,
}: SelectableListItemPickupPreparedProps): JSX.Element => {
    return (
        <SelectableItem onClick={onClick} selected={selected}>
            <ListItemPickupPrepared
                data={fetchedPickupData.data}
                ipfsAddress={fetchedPickupData.ipfsAddress}
                BCtxHash={fetchedPickupData.txHash}
            />
        </SelectableItem>
    );
};

export default SelectableListItemPickupPrepared;
