import React, { CSSProperties } from 'react';
import { FetchedVCData } from 'types/Types';
import ListItemHarvest from 'ui/atoms/ListItem/Harvest/ListItemHarvest';
import SelectableItem from 'ui/atoms/SelectableItem/SelectableItem';

export type SelectableListItemHarvestProps = {
    fetchedHarvestData: FetchedVCData;
    onClick: () => void;
    selected: boolean;
    style?: CSSProperties;
};

const SelectableListItemHarvest = ({
    fetchedHarvestData,
    onClick,
    selected,
    style,
}: SelectableListItemHarvestProps): JSX.Element => {
    return (
        <SelectableItem style={style} onClick={onClick} selected={selected}>
            <ListItemHarvest data={fetchedHarvestData.data} />
        </SelectableItem>
    );
};

export default SelectableListItemHarvest;
