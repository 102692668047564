import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useAuth } from 'contexts/AuthContext';
import { CgProfile, CgLogOut, CgHome } from 'react-icons/cg';
import { useHistory } from 'react-router-dom';
import { getUserName } from 'services/vasco-api';
import { VASCOPartner } from 'types/Types';

export type NavbarProps = {
    toggleSidebar: () => void;
};

const Navbar = ({ toggleSidebar }: NavbarProps): JSX.Element => {
    const { logout, currentUser } = useAuth();
    const history = useHistory();
    const [userName, setUserName] = useState<string>('');

    useEffect(() => {
        getUserName(currentUser.username, currentUser.token).then((response: VASCOPartner) => {
            console.log('User item:', response);
            if (response != undefined && response.naziv != undefined) {
                setUserName(response.naziv);
            }
        });
    }, []);

    return (
        <NavbarWrapper>
            <Header>
                <NavbarButton onClick={toggleSidebar}>
                    <GiHamburgerMenu size="23" />
                    <HeaderUsername>{userName}</HeaderUsername>
                </NavbarButton>
                <HomeButton onClick={() => history.push('/home')}>
                    <CgHome size="23" />
                </HomeButton>
                <ProfileButton onClick={() => history.push('/profile')}>
                    <CgProfile size="23" />
                </ProfileButton>
                <LogoutButton onClick={logout}>
                    <CgLogOut size="23" />
                </LogoutButton>
            </Header>
            <SubHeader>
                Trenutno prijavljen pod točko: <CurrentKOV>{currentUser.kov}</CurrentKOV>
            </SubHeader>
        </NavbarWrapper>
    );
};

const NavbarButton = styled.button`
    background-color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 56px;
    color: var(--muted-color);
    border-color: var(--muted-color);
    outline: 0;
    min-width: 50px;
    font-size: 1.09375rem;
    border: 0;
    border-radius: 0.25rem;

    &:hover {
        color: var(--color);
    }
`;

const LogoutButton = styled(NavbarButton)``;

const ProfileButton = styled(NavbarButton)``;

const HomeButton = styled(NavbarButton)`
    margin-left: auto;
`;

const SubHeader = styled.div`
    position: relative;
    padding-left: 1rem;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-height: 30px;
    border-top: 1px solid #d8dbe0;

    @media screen and (min-width: 768px) {
        display: none;
        margin-top: auto;
    }
`;

const NavbarWrapper = styled.nav`
    background-color: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 0;
    min-height: 56px;
    border-bottom: 1px solid #d8dbe0;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
`;

const HeaderUsername = styled.div`
    margin-left: 10px;
`;

const CurrentKOV = styled.span`
    margin-left: 0.25rem;
    font-weight: bold;
`;

export default Navbar;
