import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { VC } from 'types/Types';

export type ListItemHarvestProps = {
    data: VC;
};

const ListItemHarvest = ({ data }: ListItemHarvestProps): ReactElement => {
    return (
        <ListItem>
            <div>
                {data.subject.date} - {data.subject.crop.name} - {data.subject.quantity} {data.subject.unit}
            </div>
            {/*<div>Opis: {data.subject.description}</div>
            <div>
                Količina: {data.subject.quantity} {data.subject.unit}
            </div>
            <div>Datum: {data.subject.date}</div>*/}
        </ListItem>
    );
};

const ListItem = styled.div`
    padding: 1rem;
    align-self: center;
`;

export default ListItemHarvest;
