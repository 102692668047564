import React, { ReactElement, useEffect, useRef, useState } from 'react';
//import $ from 'jquery';
import 'datatables.net-select-dt/css/select.dataTables.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import { DataTableDataRow, DataTableDataSet, DatatablesColumns } from 'types/DataTablesTypes';
import {
    FetchedVCDataMulti,
    InvoiceProductTableData,
    SelectedDeliveryNoteItems,
    SelectedItemForInvoice,
} from 'types/Types';
import { renderToString } from 'react-dom/server';
import 'ui/block-is-custom.css';
import ReactPDF, { StyleSheet, PDFDownloadLink, PDFViewer, pdf } from '@react-pdf/renderer';
import InvoiceAdditionalData from 'InvoiceAdditionalData';
import { getVascoPrevzemnica } from 'services/vasco-api';
import { useAuth } from 'contexts/AuthContext';
import { VASCOprevzemnicaResponse } from 'types/Types';
import { PDFViewer as PDFViewerOrganisms } from 'ui/organisms/PDFViewer';
import { Button } from 'ui/atoms/Button';
import styled, { css } from 'styled-components';
import { MdSavedSearch } from 'react-icons/md';
import { AlertPopup } from 'ui/atoms/AlertPopup';
import { title } from 'process';
import { Input } from 'ui/molecules/Input';
import { getVCDataByIpfsAddress } from './services/blockis-api';
import DeliveryNoteAdditionalData from './DeliveryNoteAdditionalData';

/*require('datatables.net-dt')();
require('datatables.net-select-dt')();*/
/*eslint-disable @typescript-eslint/no-var-requires*/
//const dt = require('datatables.net');
//const dt = require('datatables.net-select-dt');
const $ = require('jquery');
$.DataTable = require('datatables.net-select-dt');
//dt(window, $);

export type DataTableProps = {
    dataSet: DataTableDataSet;
    columns: DatatablesColumns;
    fetchedVCData: FetchedVCDataMulti[] | null;
};

export function TblFunc(props: DataTableProps) {
    const { currentUser } = useAuth();
    const tableRef = useRef();
    const table = ($('#table') as any).DataTable();
    const [selectedValues, setSelectedValues] = useState<any>();
    const [stPrevzemnic, setStPrevzemnic] = useState<string[]>();
    const [selectedTableItems, setselectedTableItems] = useState<SelectedItemForInvoice[]>([]);
    const [invoiceSelectedData, setInvoiceData] = useState<InvoiceProductTableData[]>([]);
    const [showInvoice, setShowInvoice] = useState<boolean>(false);
    const [showDeliveryNote, setShowDeliveryNote] = useState<boolean>(false);
    const [showDeclaration, setShowDeclaration] = useState<boolean>(false);
    const [selectedFetchedDataForDelivery, setSelectedFetchedDataForDelivery] = useState<SelectedDeliveryNoteItems[]>(
        [],
    );
    const [deliveryNoteProof, setDeliveryNoteProof] = useState<string>('');
    //let column = table.column(0);

    const toggleShowDeclaration = () => {
        setShowDeclaration(!showDeclaration);
    };

    const toggleShowInvoice = () => {
        if (!showInvoice == false) {
            table.rows().deselect();
            //$('tr.selected').removeClass('selected');
        }
        setShowInvoice(!showInvoice);
    };

    const toggleShowDeliveryNote = () => {
        if (!showDeliveryNote == false) {
            table.rows().deselect();
            //$('tr.selected').removeClass('selected');
        }
        setShowDeliveryNote(!showDeliveryNote);
    };

    const showOrHideDeclaration = () => {
        selectedItemsFunction();
        if (selectedTableItems.length != 0) {
            toggleShowDeclaration();
        }
    };

    const showOrHideInvoice = () => {
        selectedItemsFunction();
        if (selectedTableItems.length != 0) {
            toggleShowInvoice();
        }
    };

    const showOrHideDeliveryNote = async () => {
        await selectedItemsDeliveryNotesFunction();
        if (selectedFetchedDataForDelivery.length != 0) {
            toggleShowDeliveryNote();
        }
        console.log('selected: ', selectedFetchedDataForDelivery);
        console.log('Show delivery note: ', showDeliveryNote);
    };
    const selectedItemsDeliveryNotesFunction = async () => {
        const selectedValues1 = table.rows('.selected').data();
        console.log('Selected values LENGHT:', selectedValues1.length);
        console.log('Selected values LENGHT:', selectedValues1);
        if (selectedValues1 != undefined && selectedValues1.length != 0) {
            const selectedItems: SelectedDeliveryNoteItems[] = [];
            for (let i = 0; i < selectedValues1.length; i++) {
                //console.log(selectedValues1[i][1]);

                const fetched: FetchedVCDataMulti[] | undefined = props.fetchedVCData?.filter(
                    (item) => item.stevilkaPrevzemnice == selectedValues1[i][1],
                );
                if (fetched != undefined) {
                    const proof = Array.isArray(fetched[0]?.data.proof)
                        ? fetched[0]?.data.proof[0]
                        : fetched[0]?.data.proof;
                    const fetchDelivery = await getVCDataByIpfsAddress(proof, currentUser.token);
                    if (fetchDelivery != null) {
                        console.log('Fetched item: ', fetchDelivery);
                        const selectedItem: SelectedDeliveryNoteItems = {
                            deliveryNoteData: fetchDelivery,
                            st_prevzemnice: selectedValues1[i][1],
                            txhash: selectedValues1[i][5],
                        };
                        selectedItems.push(selectedItem);
                    }
                }
            }
            console.log('Selected values:', selectedItems);
            setSelectedFetchedDataForDelivery(selectedItems);
        } else {
            alert('Za kreiranje izpisa je potrebno izbrati vsaj en element');
        }
    };
    const selectedItemsFunction = () => {
        const selectedValues1 = table.rows('.selected').data();
        console.log('Selected values lenght:', selectedValues1.length);
        if (selectedValues1 != undefined && selectedValues1.length != 0) {
            const selectedItems: SelectedItemForInvoice[] = [];
            for (let i = 0; i < selectedValues1.length; i++) {
                const temp: DataTableDataRow = selectedValues1[i];
                const selectedItem: SelectedItemForInvoice = {
                    st_prevzemnice: temp[1],
                    txhash: temp[5],
                };
                selectedItems.push(selectedItem);
            }
            setselectedTableItems(selectedItems);
            setSelectedValues(selectedValues1);
            console.log('Selected table items: ', selectedTableItems);
        } else {
            alert('Za kreiranje izpisa je potrebno izbrati vsaj en element');
        }
    };

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#E4E4E4',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
    });

    const tableData = async () => {
        console.log('RUN:');
        let preparingInvoiceData: InvoiceProductTableData[] = [];
        //console.log('Prepared Invoice DATA SELECTED:', selectedTableItems);
        await Promise.all(
            selectedTableItems.map(async (item) => {
                const test2 = await prepareDataForPickupNumber(item.st_prevzemnice, item.txhash);
                //console.log('Prepared Invoice DATA:', test2);
                //console.log(`Index: ${index} lenght: ${selectedTableItems.length}`);
                preparingInvoiceData = preparingInvoiceData.concat(test2);
            }),
        );
        //console.log('Prepared Invoice DATA MAIN:', preparingInvoiceData);
        setInvoiceData(preparingInvoiceData);
    };

    const prepareDataForPickupNumber = async (stevilkaPrevzemnice: string, txHash: string) => {
        //console.log('Številka PREVZEMNICE: ', stevilkaPrevzemnice);
        let vascoPickup: VASCOprevzemnicaResponse;
        const productArray: InvoiceProductTableData[] = [];
        if (stevilkaPrevzemnice != undefined && stevilkaPrevzemnice != '') {
            //map data from VASCO prevzemnica and blockchain
            const fetchedData = props.fetchedVCData?.find((item) => item.stevilkaPrevzemnice === stevilkaPrevzemnice);
            console.log('fetchedData: ', fetchedData);
            const des = Array.isArray(fetchedData?.data.proof) ? fetchedData?.data.proof[0] : fetchedData?.data.proof;
            console.log('PROOF: ', des);
            try {
                let year = new Date().getUTCFullYear().toString();
                if (fetchedData != undefined) {
                    const date = new Date(fetchedData.data.timestamp);
                    year = date.getUTCFullYear().toString();
                }
                if (des != undefined) {
                    setDeliveryNoteProof(des);
                }
                console.log('DELIVERY NOTE PROOF', deliveryNoteProof);
                const stevilkaPrevzemniceForVasco = stevilkaPrevzemnice + '.' + year;
                vascoPickup = await getVascoPrevzemnica(stevilkaPrevzemniceForVasco, currentUser.token);
                //console.log('PREVZEMNICA: ', vascoPickup);
                fetchedData?.data.subject.forEach((BCItem) => {
                    const vascoItem = vascoPickup.postavke?.find((item) => item.sifra === BCItem.crop._id);
                    //console.log('VASCO Item: ', vascoItem);
                    console.log('BCItem: ', BCItem);
                    /*console.log('VascoPickup: ', vascoPickup);
                    console.log('Postavke: ', vascoPickup.postavke);
                    console.log(
                        'Find: ',
                        vascoPickup.postavke?.find((item) => item.sifra === BCItem.crop._id),
                    );*/
                    const itemQuantity = vascoItem?.kolicina != undefined ? vascoItem?.kolicina : BCItem.quantity;
                    const itemPrice = vascoItem?.cena != undefined ? vascoItem.cena : 0;
                    const sumPriceWithoutVat = vascoItem?.vrednost != undefined ? vascoItem?.vrednost : 0;
                    const VAT = Number.parseFloat((sumPriceWithoutVat * 0.22).toFixed(2));
                    //console.log('SumPrice', VAT);
                    const newProductTableItem: InvoiceProductTableData = {
                        productCode: BCItem.crop._id,
                        productName: BCItem.crop.name,
                        uom: BCItem.crop.enota,
                        quantity: itemQuantity,
                        uomPriceWithoutVAT: itemPrice,
                        sumPriceWithoutVAT: sumPriceWithoutVat,
                        discountpercent: 0,
                        discountvalue: 0,
                        VATpercent: 22,
                        VATvalue: VAT,
                        sumPrice: sumPriceWithoutVat + VAT,
                    };
                    productArray.push(newProductTableItem);
                });
            } catch (errror) {
                //console.log(errror);
                return [];
            }
        } else {
            // map data from blockchain
            const fetchedData = props.fetchedVCData?.find((item) => item.txHash === txHash);
            fetchedData?.data.subject.forEach((BCItem) => {
                const newProductTableItem: InvoiceProductTableData = {
                    productCode: BCItem.crop._id,
                    productName: BCItem.crop.name,
                    uom: BCItem.crop.enota,
                    quantity: BCItem.quantity,
                    uomPriceWithoutVAT: 0,
                    sumPriceWithoutVAT: 0,
                    discountpercent: 0,
                    discountvalue: 0,
                    VATpercent: 0,
                    VATvalue: 0,
                    sumPrice: 0,
                };
                productArray.push(newProductTableItem);
            });
        }
        return productArray;
    };

    const getDeliveryNoteData = async () => {
        console.log('getDeliveryNoteData', deliveryNoteProof);
        const deliveryNoteData = await getVCDataByIpfsAddress(deliveryNoteProof, currentUser.token);
        console.log(deliveryNoteData);
    };

    const format = async (d: any) => {
        // `d` is the original data object for the row
        const stevilkaPrevzemnice = d[1];
        const txHash = d[5];
        //const deliveryNoteProof = d[6];
        //console.log('Stevilka prevzemnice: ', stevilkaPrevzemnice);
        //console.log('Block Chain Hash: ', txHash);
        try {
            const tableData = await prepareDataForPickupNumber(stevilkaPrevzemnice, txHash);
            //console.log('TableData: ', tableData);
            const tableId = txHash.slice(txHash.lastIndexOf('/') + 1);
            console.log('TABLE DATA: ', tableData);
            const html: ReactElement = (
                <>
                    <table id="table" key={tableId} className="spec-table">
                        <thead>
                            <tr>
                                <th>Šifra blaga</th>
                                <th>Naziv blaga</th>
                                <th>EM</th>
                                <th>količina</th>
                                <th>cena/EM brez DDV</th>
                                <th>Vrednost brez DDV</th>
                                <th>DDV %</th>
                                <th>DDV znesek</th>
                                <th>Vrednost skupaj</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, i) => (
                                <tr
                                    id={tableId + i.toString() + '_' + row.productCode}
                                    key={tableId + i.toString() + '' + row.productCode}
                                >
                                    <td>{row.productCode}</td>
                                    <td>{row.productName}</td>
                                    <td>{row.uom}</td>
                                    <td>{row.quantity}</td>
                                    <td>
                                        {row.uomPriceWithoutVAT.toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}
                                    </td>
                                    <td>
                                        {row.sumPriceWithoutVAT.toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}
                                    </td>
                                    <td>{row.VATpercent}</td>
                                    <td>
                                        {row.VATvalue.toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}
                                    </td>
                                    <td>
                                        {row.sumPrice.toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            );
            const htmlAsString: string = renderToString(html);
            //console.log(htmlAsString);

            return htmlAsString;
        } catch (err) {
            return 'Something went wrong';
        }
    };

    useEffect(() => {
        console.log(tableRef.current);
        console.log(props.fetchedVCData);
        const table = ($('#table') as any).DataTable({
            data: props.dataSet,
            columns: props.columns,
            columnDefs: [
                {
                    targets: 0,
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {
                    targets: 5,
                    render: function (data: any, type: any, row: any) {
                        return '<a href="' + data + '" target="_blank">BlockChain Explorer</a>';
                    },
                },
            ],

            order: [[1, 'asc']],
            language: {
                search: 'Iskanje',
                paginate: {
                    first: 'Prvi',
                    previous: 'Prejšnji',
                    next: 'Naslednji',
                    last: 'Zadnji',
                },
                lengthMenu: 'Prikaži _MENU_ vrstic',
                info: 'Prikaz _START_ do _END_ od skupno _TOTAL_',
                infoEmpty: 'Prikaz 0 od skupno _TOTAL_',
                infoFiltered: '(Filtrirano izmed _MAX_ vrstic)',
                infoPostFix: '',
                loadingRecords: 'Loading in progress ...',
                zeroRecords: 'Brez podatkov',
                emptyTable: 'Prazna tabela',
            },
            destroy: true, // I think some clean up is happening here
        });

        let minDate;
        let maxDate;

        $.fn.dataTable.ext.search.push(function (
            settings: any,
            searchData: any,
            index: any,
            rowData: any,
            counter: any,
        ) {
            /*if (settings.nTable.id !== 'myTarget') {
                return true;
            }*/
            //min value
            const mintext: string = $('#min').val();
            const min: number = Number.parseInt(mintext.substring(0, 10).split('-').join(''));
            //min value
            const maxtext: string = $('#max').val();
            const max: number = Number.parseInt(maxtext.substring(0, 10).split('-').join(''));
            //min value
            const age: number = Number.parseInt(searchData[2].substring(0, 10).split('-').join('') || 0);
            //const age = parseFloat(searchData[2]) || 0; // using the data from the 4th column
            if (
                (isNaN(min) && isNaN(max)) ||
                (isNaN(min) && age <= max) ||
                (min <= age && isNaN(max)) ||
                (min <= age && age <= max)
            ) {
                return true;
            }
            return false;
        });

        //$(document).ready(function () {
        // Create date inputs
        /*minDate = new DateTime($('#min'), {
            format: 'MMMM Do YYYY'
        });
        maxDate = new DateTime($('#max'), {
            format: 'MMMM Do YYYY'
        });*/

        // DataTables initialisation
        //var table = $('#example').DataTable();

        // Refilter the table
        $('#min, #max').on('change', function () {
            minDate = $('#min').val();
            console.log('On change min max', minDate);
            table.draw();
        });
        //});

        $('#table tbody').on('click', 'tr', function () {
            ($(this) as any).toggleClass('selected');
        });

        $('button.toggle-vis').on('click', function (e: any) {
            e.preventDefault();
            const value = $(this).attr('data-column');
            // Get the column API object
            //console.log($(this).attr('data-column'));
            if (value != undefined) {
                const column = table.column(value);
                // Toggle the visibility
                column.visible(!column.visible());
            }
        });
        // Add event listener for opening and closing details
        $('#table').on('click', 'tbody td.dt-control', async function () {
            const tr = $(this).closest('tr');
            const row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
            } else {
                // Open this row
                const table = await format(row.data());
                (row.child(table) as any).show();
            }
        });

        $('#table').on('requestChild.dt', function (e: any, row: any) {
            row.child(format(row.data())).show();
        });

        // Extra step to do extra clean-up.
        return function () {
            console.log('Table destroyed');
            table.destroy();
        };
    }, []);

    useEffect(() => {
        tableData();
    }, [selectedValues]);

    return (
        <div>
            {/*<div>
                Toggle column:
                {props.columns.map((column, i) => (
                    <button key={i.toString().concat(column.title)} className="toggle-vis" data-column={i}>
                        {column.title}
                    </button>
                ))}
            </div>*/}
            <StyledInput label="Min" type="date" id="min" />
            <StyledInput label="Max" type="date" id="max" />
            <table id="table" className="display" width="100%">
                <thead>
                    <tr>
                        {props.columns.map((column) => (
                            <th key={column.title}>{column.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.dataSet.map((row, i) => (
                        <tr id={i.toString()} key={i.toString()}>
                            {row.map((value) => (
                                <td id={value} key={value}>
                                    {value}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <ButtonGroup>
                {/*currentUser.userType === 'KOV' && (
                    <Button
                        text={showDeclaration ? 'Skrij deklaracije' : 'Prikaži deklaracije'}
                        btnType="primary"
                        onClick={showOrHideDeclaration}
                    ></Button>
                )*/}
                <VerticalDelimiter></VerticalDelimiter>
                <ContainerButton pos={'left'}>
                    <Button
                        text={showInvoice ? 'Zapri zbirni izpis' : 'Kreiraj zbirni izpis'}
                        btnType="primary"
                        onClick={showOrHideInvoice}
                    ></Button>
                </ContainerButton>
                <ContainerButton pos={'right'}>
                    <Button
                        text={showDeliveryNote ? 'Zapri izpis dobavnic' : 'Kreiraj dobavnice'}
                        btnType="primary"
                        onClick={showOrHideDeliveryNote}
                    ></Button>
                </ContainerButton>
            </ButtonGroup>
            {showDeclaration && (
                <>
                    {selectedTableItems != undefined && selectedTableItems.length != 0 && (
                        <PDFViewWrapper>
                            <PDFViewerOrganisms stevilkaPrevzemnice={selectedTableItems[0].st_prevzemnice} />
                        </PDFViewWrapper>
                    )}
                </>
            )}
            {showInvoice != false && invoiceSelectedData != undefined && invoiceSelectedData.length != 0 && (
                <div>
                    <PDFViewer width={'100%'} height={'700px'}>
                        <InvoiceAdditionalData
                            invoiceData={invoiceSelectedData}
                            selectebleItems={selectedTableItems}
                            creator={props.fetchedVCData != null ? props.fetchedVCData[0].data.creator.name : ''}
                        ></InvoiceAdditionalData>
                    </PDFViewer>
                    <br></br>
                    <PDFDownloadLink
                        document={
                            <InvoiceAdditionalData
                                invoiceData={invoiceSelectedData}
                                selectebleItems={selectedTableItems}
                                creator={props.fetchedVCData != null ? props.fetchedVCData[0].data.creator.name : ''}
                            />
                        }
                        fileName={new Date().toISOString().substring(0, 10) + '_Zbirni_Izpis.pdf'}
                    >
                        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                    </PDFDownloadLink>
                </div>
            )}
            {showDeliveryNote != false &&
                selectedFetchedDataForDelivery != undefined &&
                selectedFetchedDataForDelivery.length != 0 && (
                    <div>
                        <PDFViewer width={'100%'} height={'700px'}>
                            <DeliveryNoteAdditionalData
                                selectableItems={selectedFetchedDataForDelivery}
                            ></DeliveryNoteAdditionalData>
                        </PDFViewer>
                        <br></br>
                        <PDFDownloadLink
                            document={
                                <DeliveryNoteAdditionalData
                                    selectableItems={selectedFetchedDataForDelivery}
                                ></DeliveryNoteAdditionalData>
                            }
                            fileName={new Date().toISOString().substring(0, 10) + '_Dobavnice.pdf'}
                        >
                            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                        </PDFDownloadLink>
                    </div>
                )}
        </div>
    );
}

const PDFViewWrapper = styled.div`
    flex: 1 1 auto;
`;
//margin: top right bottom left
const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px 0px 20px 0px;
`;
const VerticalDelimiter = styled.div`
    display: flex;
    margin-right: 10px;
`;
const StyledInput = styled(Input)`
    margin-bottom: 20px;
    margin-left: 4px;
    margin-right: 4px;
    width: 25%;

    &.valid {
        background-color: white;
    }
    &.invalid {
        background-color: #ffaaaa;
    }
`;
const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;
    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
