import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Sowing, SowingData } from 'types/Types';
import { Title } from 'ui/atoms/Title';
import { Input } from 'ui/molecules/Input';
import ListSowings from '../List/Sowings/ListSowings';
import ListSowingsMulti from '../List/SowingsMulti/ListSowingsMulti';

type SearchSowingMultiProps = {
    sowings: SowingData[];
    selected: SowingData[];
    onSelectionChanged: React.Dispatch<React.SetStateAction<SowingData[]>>;
};

const SearchSowingsMulti = ({ sowings, selected, onSelectionChanged }: SearchSowingMultiProps): ReactElement => {
    const [search, setSearch] = useState('');
    const [filteredSowings, setFilteredSowing] = useState<SowingData[]>([]);
    const [selectedSowing, setSelectedSowing] = useState<SowingData[]>([]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };
    useEffect(() => {
        //console.log('Logged selection changed', selectedSowing);
        onSelectionChanged(selectedSowing);
    }, [selectedSowing]);

    useEffect(() => {
        setFilteredSowing(sowings);
    }, [sowings]);

    const cropsNameFromSowing = (sowing: SowingData) => {
        let crops = '';
        for (const it of sowing.crop) {
            crops = crops + it.name;
        }
        return crops;
    };

    useEffect(() => {
        console.log('Sowings array of Data', sowings);
        let response = false;
        if (sowings !== undefined && sowings.length !== 0) {
            setFilteredSowing(
                sowings.filter((sowing) => {
                    //console.log('Sowing item', sowing);
                    if (sowing) {
                        response =
                            sowing.place.toLowerCase().includes(search.toLowerCase()) ||
                            cropsNameFromSowing(sowing).toLowerCase().includes(search.toLowerCase()) ||
                            sowing.GERK.toLowerCase().includes(search.toLowerCase()) ||
                            false;
                    }
                    /*if (!response && sowing.crop?.name) {
                        console.log('Searching by crop_name', response);
                        response = 
                    }*/
                    //console.log('Searching by key', search);
                    //console.log('Searching by key response', response);
                    return response;
                }),
            );
        }
    }, [search]);

    return (
        <SearchSowingsWrapper>
            <SearchInput type="text" value={search} label="Search" onChange={handleInputChange} />
            <ListWrapper>
                <ListSowingsMulti
                    sowings={filteredSowings}
                    onSelectionChanged={setSelectedSowing}
                    selected={selected}
                />
            </ListWrapper>
        </SearchSowingsWrapper>
    );
};

export default SearchSowingsMulti;

const SearchSowingsWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    margin: 10px;
    flex-direction: column;
`;

const SearchInput = styled(Input)`
    margin-bottom: 15px;
    border: 1px solid black;
`;

const ListWrapper = styled.div`
    flex: 1 1 auto;
`;
