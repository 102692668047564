import Input from 'ui/molecules/Input/Input';
import React, { ReactElement, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { HarvestData, HarvestLoadedFiles, LoadedFiles, SowingData } from 'types/Types';
import { FileDropzone } from 'ui/molecules/FileDropzone';
import { Title } from 'ui/atoms/Title';
import DropdownSelect from 'ui/atoms/DropdownSelect/DropdownSelect';
import { HARVEST_CREATE_DESCRIPTION_OPTIONS } from 'config/custom-constants';
import { FileDropzone2 } from 'ui/molecules/FileDropzone2';
import { Button } from 'ui/atoms/Button';
import { ShowActivityDrawer } from '../ShowActivityDrawer';
import { formatDateToString } from 'helpers/helper-functions';
import { TextConstant } from '../../../config/text-constant';

type CreateHarvestProps = {
    harvestDatas: HarvestData[];
    selectedSowings: SowingData[];
    setHarvestDatas: React.Dispatch<React.SetStateAction<HarvestData[]>>;
    setIpfsPaths: React.Dispatch<React.SetStateAction<string[]>>;
};

const CreateHarvest = ({
    harvestDatas,
    selectedSowings,
    setHarvestDatas,
    setIpfsPaths,
}: CreateHarvestProps): ReactElement => {
    const [current, setCurrent] = useState(0);
    const [commentValue, setCommentValue] = useState<string>(harvestDatas[current].description);
    const [loadedFilestemp, setLoadedFilestemp] = useState<LoadedFiles[]>([]);
    const [currentSowing, setCurrentSowing] = useState<SowingData[]>([]);

    const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!Number.isNaN(e.target.value)) {
            setHarvestDatas((prevHarvestDatas) => [
                ...prevHarvestDatas.slice(0, current),
                {
                    ...prevHarvestDatas[current],
                    quantity: Number.parseFloat(e.target.value),
                },
                ...prevHarvestDatas.slice(current + 1),
            ]);
        }
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //setHarvestData({ ...harvestData, date: e.target.value });
        setHarvestDatas((prevHarvestDatas) => [
            ...prevHarvestDatas.slice(0, current),
            {
                ...prevHarvestDatas[current],
                date: e.target.value,
            },
            ...prevHarvestDatas.slice(current + 1),
        ]);
    };

    const handleDescriptionChange = (descriptionIN: string) => {
        //setHarvestData({ ...harvestData, date: e.target.value });
        setHarvestDatas((prevHarvestDatas) => [
            ...prevHarvestDatas.slice(0, current),
            {
                ...prevHarvestDatas[current],
                description: descriptionIN,
            },
            ...prevHarvestDatas.slice(current + 1),
        ]);
    };

    useEffect(() => {
        harvestDatas[current].loadedFiles = loadedFilestemp;
    }, [loadedFilestemp]);

    const handleButtonClickNext = () => {
        setCurrent(current + 1);
    };

    const handleButtonClickPrev = () => {
        setCurrent(current - 1);
    };

    useEffect(() => {
        console.log('test description');
        handleDescriptionChange(commentValue);
    }, [commentValue]);

    useEffect(() => {
        console.log('Effect FOR CURRENT started ', harvestDatas[current].proof);
        const currentSowingSearched = selectedSowings.filter((s) => {
            console.log('test inside filter: ', s.ipfsAddress == harvestDatas[current].proof);
            console.log('test inside filter ipfsAddress: ', s.ipfsAddress);
            return s.ipfsAddress === harvestDatas[current].proof;
        });
        console.log('Setting current sowing ', currentSowingSearched);

        setCurrentSowing(currentSowingSearched);
    }, [current]);
    return (
        <CreateHarvestWrapper>
            {harvestDatas.length > 0 && (
                <>
                    <StyledInput label="Ime" type="text" disabled value={harvestDatas[current].crop?.name} />
                    <HorizontalWrapper>
                        <StyledInput
                            label="Količina"
                            type="number"
                            step="0.1"
                            value={harvestDatas[current].quantity}
                            onChange={handleQuantityChange}
                            className="halfpart"
                        />
                        <StyledInput
                            label=""
                            type="text"
                            value={harvestDatas[current].crop?.enota}
                            className="halfpart"
                            disabled
                        />
                    </HorizontalWrapper>
                    <DropdownSelect
                        value={harvestDatas[current].description}
                        label="Opis"
                        isValid={true}
                        isLoading={false}
                        isDisabled={false}
                        options={HARVEST_CREATE_DESCRIPTION_OPTIONS}
                        onSelectedValue={(s) => handleDescriptionChange(s.toString())}
                    />
                    <StyledInput
                        label="Datum spravila"
                        type="date"
                        value={formatDateToString(new Date(), false)}
                        onChange={handleDateChange}
                        max={new Date().toISOString().split('T')[0]}
                    />
                    <FileDropzone2
                        parentFiles={harvestDatas[current].loadedFiles}
                        setIpfsPaths={setIpfsPaths}
                        setLoadedFiles={setLoadedFilestemp}
                    />
                    {harvestDatas.length > 1 && (
                        <ContainerBottom>
                            <ContainerButton pos="left">
                                <Button
                                    text="Nazaj"
                                    disabled={current === 0}
                                    onClick={handleButtonClickPrev}
                                    btnType="multiple-choices"
                                />
                            </ContainerButton>
                            <ContainerButton pos="left">
                                <Button
                                    text="Naprej"
                                    disabled={current === harvestDatas.length - 1}
                                    onClick={handleButtonClickNext}
                                    btnType="multiple-choices"
                                />
                            </ContainerButton>
                        </ContainerBottom>
                    )}
                    <Title subtext={TextConstant.HISTORY_SOWING}></Title>
                    {currentSowing.length > 0 &&
                        currentSowing[0].drawerIDs?.map((drawerId) => (
                            <ShowActivityDrawer drawerId={drawerId} key={drawerId}></ShowActivityDrawer>
                        ))}
                </>
            )}
        </CreateHarvestWrapper>
    );
};

export default CreateHarvest;

const CreateHarvestWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const StyledInput = styled(Input)`
    margin-bottom: 20px;
    margin-left: 4px;
    margin-right: 4px;
    &.halfpart {
        display: inline-flex;
    }
`;

const HorizontalWrapper = styled.div`
    display: flex;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;
    margin: 4px;
    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
