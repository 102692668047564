import axios from 'axios';
import { Button } from 'ui/atoms/Button';
import React, { ReactElement, useEffect, useState } from 'react';
import { HarvestData, LoadedFiles, SowingData } from 'types/Types';
import { API_ADDRESS } from 'config/config';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { useAuth } from 'contexts/AuthContext';
import styled from 'styled-components';
import { ShowActivityDrawer } from '../ShowActivityDrawer';
import { getCropsNamesFromSowing } from '../../../helpers/helper-functions';
import { TextConstant } from '../../../config/text-constant';

type OverviewFinishedSowingProps = {
    sowingData: SowingData;
    ipfsPaths: string[];
    isUpdate: boolean;
    files: LoadedFiles[];
    setSuccess: (data: boolean) => void;
    setLoading: (data: boolean) => void;
    setError: (data: string) => void;
    setTxLink: React.Dispatch<React.SetStateAction<string | null>>;
    setImageLoading: React.Dispatch<React.SetStateAction<boolean> | false>;
};

type DataVC1 = {
    subject: {
        _id: string;
        place: string;
        date: string;
        GERK: string;
        comment: string;
    };
};

const OverviewFinishedSowing = ({
    sowingData,
    ipfsPaths,
    isUpdate,
    files,
    setSuccess,
    setLoading,
    setError,
    setTxLink,
    setImageLoading,
}: OverviewFinishedSowingProps): ReactElement => {
    const [drawerIds, setDrawerIds] = useState<string[]>([]);

    useEffect(() => {
        if (sowingData.drawerIDs != undefined && sowingData.drawerIDs.length > 0) {
            console.warn('OverviewFinishedSowings DRAWER_IDS', sowingData);
            setDrawerIds(sowingData.drawerIDs);
        }
    }, []);

    return (
        <ConfirmSowingWrapper>
            {sowingData.crop && (
                <>
                    <div>
                        <strong>Pridelek:</strong> {getCropsNamesFromSowing(sowingData)}
                        <div>
                            <strong>Komentar:</strong> {sowingData.comment}
                        </div>
                        <div>
                            <strong>GERK PID:</strong> {sowingData.GERK}
                        </div>
                        <div>
                            <strong>{TextConstant.PLACE_OF_SOWING}</strong> {sowingData.location}
                        </div>
                        <div>
                            <strong>{TextConstant.PLACE_OF_SOWING}</strong> {sowingData.date}
                        </div>
                        <div>
                            <strong>{TextConstant.DRAWER_IDS_SOWING}</strong> {sowingData.drawerIDs || ''}
                        </div>
                        <strong>Stanja:</strong>
                        {drawerIds.map((id) => (
                            <>
                                <ShowActivityDrawer drawerId={id}></ShowActivityDrawer>
                            </>
                        ))}
                        {/*fileArray*/}
                    </div>
                </>
            )}
        </ConfirmSowingWrapper>
    );
};

export default OverviewFinishedSowing;

const ConfirmSowingWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BottomWrapper = styled.div`
    margin-top: auto;
`;

const Image = styled.div`
    display: flex;
    width: 100px;
    height: 100px;
`;
const DrawerCard = styled.div`
    display: block;
    margin: 1em;
`;
