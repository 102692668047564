import React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'ui/atoms/Button';

const Error404 = (): JSX.Element => {
    const history = useHistory();
    return (
        <div>
            <h1>Napaka 404</h1>
            <h6>Stran ni bila najdena</h6>
            <Button btnType="primary" text="Pojdi na prijavo" onClick={() => history.push('/')} />
        </div>
    );
};

export default Error404;
