import Input from 'ui/molecules/Input/Input';
import React, { ReactElement, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FetchedVCData } from 'types/Types';
import { Button } from 'ui/atoms/Button';
import { Title } from 'ui/atoms/Title';
import { parse } from 'date-fns';

type CreatePickupProps = {
    selectedFetchedVCData: string[];
    fetchedVCData: FetchedVCData[];
    harvestData: FetchedVCData[];
    setHarvestData: React.Dispatch<React.SetStateAction<FetchedVCData[]>>;
    setProofData: React.Dispatch<React.SetStateAction<FetchedVCData[]>>;
};

const CreatePickup = ({
    selectedFetchedVCData,
    fetchedVCData,
    harvestData,
    setHarvestData,
    setProofData,
}: CreatePickupProps): ReactElement => {
    const [current, setCurrent] = useState(0);
    const [maxQuantityMap, setMaxQuantity] = useState(new Map<string, number>());

    const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>, maxQuantity?: number) => {
        console.log('Prev harvest data:', harvestData);
        console.log('Current:', current);
        console.log('inserted value:', e.target.value);
        if (maxQuantity !== undefined) {
            if (!(parseFloat(e.target.value) > maxQuantity)) {
                setHarvestData((prevHarvestData) => [
                    ...prevHarvestData.slice(0, current),
                    {
                        ...prevHarvestData[current],
                        data: {
                            ...prevHarvestData[current].data,
                            subject: {
                                ...prevHarvestData[current].data.subject,
                                quantity: Number.parseFloat(e.target.value),
                            },
                        },
                    },
                    ...prevHarvestData.slice(current + 1),
                ]);
            }
        }
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHarvestData((prevHarvestData) => [
            ...prevHarvestData.slice(0, current),
            {
                ...prevHarvestData[current],
                data: {
                    ...prevHarvestData[current].data,
                    subject: {
                        ...prevHarvestData[current].data.subject,
                        description: e.target.value,
                    },
                },
            },
            ...prevHarvestData.slice(current + 1),
        ]);
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHarvestData((prevHarvestData) => [
            ...prevHarvestData.slice(0, current),
            {
                ...prevHarvestData[current],
                data: {
                    ...prevHarvestData[current].data,
                    subject: {
                        ...prevHarvestData[current].data.subject,
                        date: e.target.value,
                    },
                },
            },
            ...prevHarvestData.slice(current + 1),
        ]);
    };

    const handleUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHarvestData((prevHarvestData) => [
            ...prevHarvestData.slice(0, current),
            {
                ...prevHarvestData[current],
                data: {
                    ...prevHarvestData[current].data,
                    subject: {
                        ...prevHarvestData[current].data.subject,
                        unit: e.target.value,
                    },
                },
            },
            ...prevHarvestData.slice(current + 1),
        ]);
    };

    const handleButtonClickNext = () => {
        setCurrent(current + 1);
    };

    const handleButtonClickPrev = () => {
        setCurrent(current - 1);
    };

    useEffect(() => {
        // TODO: Maybe make a better structure for saving the state?
        setHarvestData([]);
        setProofData([]);
        const newMap = new Map(maxQuantityMap);
        for (let i = 0; i < selectedFetchedVCData.length; i++) {
            const VCData = fetchedVCData.find((e) => e.ipfsAddress == selectedFetchedVCData[i]);
            if (VCData !== undefined) {
                setHarvestData((prevHarvestData) => [...prevHarvestData, VCData]);
                setProofData((prevProofData) => [...prevProofData, VCData]);
                console.log('subject', VCData);
                newMap.set(VCData.data.subject.crop._id, VCData.data.subject.quantity);
                console.log('Map quantities: ', newMap);
                setMaxQuantity(newMap);
            }
        }
        setMaxQuantity(newMap);
    }, []);

    return (
        <CreatePickupWrapper>
            {harvestData.length > 0 && (
                <div>
                    <StyledInput label="Ime" type="text" disabled value={harvestData[current].data.subject.crop.name} />
                    <HorizontalWrapper>
                        <StyledInput
                            label={
                                'Količina (Max vrednost=' +
                                maxQuantityMap.get(harvestData[current].data.subject.crop._id) +
                                ')'
                            }
                            type="number"
                            step="0.1"
                            value={harvestData[current].data.subject.quantity}
                            onChange={(e) =>
                                handleQuantityChange(e, maxQuantityMap.get(harvestData[current].data.subject.crop._id))
                            }
                            className="halfpart"
                        />
                        <StyledInput
                            label=""
                            type="text"
                            value={harvestData[current].data.subject.unit}
                            onChange={handleUnitChange}
                            className="halfpart"
                            disabled
                        />
                    </HorizontalWrapper>
                    {/*<StyledInput
                        label="Opis"
                        type="textarea"
                        value={harvestData[current].data.subject.description}
                        onChange={handleDescriptionChange}
                        />*/}
                    <StyledInput
                        label="Datum spravila"
                        type="date"
                        value={harvestData[current].data.subject.date}
                        onChange={handleDateChange}
                        min={harvestData[current].data.subject.date}
                        max={new Date().toISOString().split('T')[0]}
                        disabled
                    />
                    {harvestData.length > 1 && (
                        <ContainerBottom>
                            <ContainerButton pos="left">
                                <Button
                                    text="Nazaj"
                                    disabled={current === 0}
                                    onClick={handleButtonClickPrev}
                                    btnType="primary"
                                />
                            </ContainerButton>
                            <ContainerButton pos="left">
                                <Button
                                    text="Naprej"
                                    disabled={current === selectedFetchedVCData.length - 1}
                                    onClick={handleButtonClickNext}
                                    btnType="primary"
                                />
                            </ContainerButton>
                        </ContainerBottom>
                    )}
                </div>
            )}
        </CreatePickupWrapper>
    );
};

export default CreatePickup;

const HorizontalWrapper = styled.div`
    display: flex;
`;

const StyledInput = styled(Input)`
    margin-bottom: 20px;
    margin-left: 4px;
    margin-right: 4px;

    &.halfpart {
        display: inline-flex;
    }
`;
const CreatePickupWrapper = styled.div`
    margin-top: 10px;
    flex: 1 1 auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;
    margin: 4px;
    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
