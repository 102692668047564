import React, { useState } from 'react';

type useSidebarReturnType = {
    isSidebarVisible: boolean;
    toggleSidebar: () => void;
};

function initSidebarVisible() {
    return window.innerWidth > 768;
}

const useSidebar = (): useSidebarReturnType => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(initSidebarVisible());

    function toggleSidebar() {
        setIsSidebarVisible((prevState) => !prevState);
    }

    return { isSidebarVisible, toggleSidebar };
};

export default useSidebar;
