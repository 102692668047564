import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Crop, LoadedFiles, SowingData } from 'types/Types';
import { Button } from 'ui/atoms/Button';
import ConfirmHarvest from 'ui/organisms/ConfirmHarvest/ConfirmHarvest';
import CreateHarvest from 'ui/organisms/CreateHarvest/CreateHarvest';
import SearchCrops from 'ui/organisms/SearchCrops/SearchCrops';
import axios from 'axios';
import { API_ADDRESS } from 'config/config';
import useStep from 'hooks/useStep';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { GoArrowRight, GoArrowLeft } from 'react-icons/go';
import { useAuth } from 'contexts/AuthContext';
import SuccessMessage from 'ui/molecules/SuccessMessage/SuccessMessage';
import { format, parse } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { StyledLink } from 'ui/atoms/StyledLink';
import CreateSowing from 'ui/organisms/CreateSowing/CreateSowing';
import ConfirmSowing from 'ui/organisms/ConfirmSowing/ConfirmSowing';
import { Title } from 'ui/atoms/Title';
import { AlertPopup } from 'ui/atoms/AlertPopup';
import { getUserCrops } from '../../../services/vasco-api';
import SearchCropsMulti from '../../organisms/SearchCropsMulti/SearchCropsMulti';
import { TextConstant } from '../../../config/text-constant';

const TITLE_TEXT = TextConstant.CREATE_SOWING;

const initialStateSowingData: SowingData = {
    crop: [],
    location: '',
    GERK: '',
    status: 0,
    comment: '',
    date: format(Date.now(), 'yyyy-MM-dd'),
    place: '',
    ipfsAddress: '',
};

const Sowing: React.FC = () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const { step, stepInc, stepDec } = useStep();
    const [crops, setCrops] = useState<Crop[]>([]);
    const [cropsDB, setCropsDB] = useState<Crop[]>([]);
    //const [selected, setSelected] = useState<string | null>(null);
    const [selectedCrops, setSelectedCrops] = useState<Crop[]>([]);
    const [sowingData, setSowingData] = useState<SowingData>(initialStateSowingData);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();
    const [ipfsPaths, setIpfsPaths] = useState<string[]>([]);
    const [files, setLoadedFiles] = useState<LoadedFiles[]>([]);
    const [txLink, setTxLink] = useState<string | null>(null);
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [isValid, isAllInputValid] = useState<boolean>(false);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(true);

    const checkIfImagesNeedToRemoved = () => {
        if (step == 2) {
            setLoadedFiles([]);
        }
        stepDec();
    };

    const getCropFromId = () => {
        let data = null;
        for (let i = 0; i < crops.length; i++) {
            if (selectedCrops.includes(crops[i])) {
                data = crops[i];
                break;
            }
        }
        return data;
    };

    const getCrops = () => {
        setLoading(true);
        axios
            .get(`${API_ADDRESS}/crop`, { headers: { Authorization: `Bearer ${currentUser.token}` } })
            .then((res) => {
                const cropse: Crop[] = res.data;
                //console.log('REsponse crops:', res);
                setCropsDB(cropse);
                //console.log('CROPS SET:', cropsDB);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const showImageAlertOrProceed = () => {
        console.log('showImageAlertOrProceed');
        if (step === 1 && files.length === 0) {
            if (!confirmed) {
                setShowAlert(true);
            } else {
                setConfirmed(false);
                stepInc();
            }
        } else {
            stepInc();
        }
    };

    useEffect(() => {
        getCrops();
    }, []);

    useEffect(() => {
        //setCrops(cropsDB); // TODO uncomment bellow and delete this line
        if (localStorage.getItem('vascoUserID') == null) {
            setCrops(cropsDB);
        } else {
            getUserCrops()
                .then((result) => {
                    console.log('Length of the user crops is:  ', result.length);
                    if (result.length != 0) {
                        const userCropArray: Crop[] = [];
                        //console.log('Result of crops:', result);
                        //console.log('Result all crops:', crops);
                        const stripedCrops: Crop[] = [];
                        result.forEach((c, index) => {
                            //console.log('Result for each', c);

                            const temp = cropsDB.filter((crop) => crop._id == c);
                            //console.log('after filter:', temp);
                            stripedCrops.push(...temp);
                            //console.log('after filter:', stripedCrops);
                        });
                        //console.log('End of user crops:', stripedCrops);
                        setCrops(stripedCrops);
                    } else {
                        setCrops(cropsDB);
                    }
                })
                .catch((error) => {
                    setCrops(cropsDB);
                });
        }
    }, [cropsDB]);

    useEffect(() => {
        console.log('1_Log valid:', isValid);
        console.log('1_step', step);
        console.log('1_gerk_pid', sowingData.GERK);
        console.log('1_gerk_pid', step == 1 && sowingData.GERK == '' && !isValid);
    }, [isValid]);

    useEffect(() => {
        setSowingData({ ...sowingData, crop: selectedCrops });
    }, [selectedCrops]);

    useEffect(() => {
        setShowAlert(false);
        if (confirmed) {
            if (step === 1) {
                setConfirmed(false);
                stepInc();
            }
        }
    }, [confirmed]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <HarvestWrapper>
                {!error && (
                    <>
                        {!success && (
                            <>
                                <ContainerMain>
                                    {step === 0 && (
                                        <StepWrapper>
                                            <Title text={TITLE_TEXT} subtext={TextConstant.CHOOSE_CROP}></Title>
                                            <SearchCropsMulti
                                                onSelectionChanged={setSelectedCrops}
                                                crops={crops}
                                                selected={selectedCrops}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 1 && (
                                        <StepWrapper>
                                            <Title text={TITLE_TEXT} subtext={TextConstant.INSERT_SOWING_DATA} />
                                            <CreateSowing
                                                sowingData={sowingData}
                                                setAllInputIsValid={isAllInputValid}
                                                setSowingData={setSowingData}
                                                setIpfsPaths={setIpfsPaths}
                                                setLoadedFiles={setLoadedFiles}
                                            />
                                            <AlertPopup
                                                title={TextConstant.ADD_SOWING_IMAGE_DESC}
                                                message="Če želiti nadaljevati brez slik pritisnite Nadaljuj"
                                                isShown={showAlert}
                                                confirmed={confirmed}
                                                declineButtonText="Prekliči"
                                                confirmeButtonText="Nadaljuj"
                                                onDeclined={setShowAlert}
                                                onConfirmed={setConfirmed}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 2 && (
                                        <StepWrapper>
                                            <Title text={TITLE_TEXT} subtext={TextConstant.CONFIRM_CROP} />
                                            <ConfirmSowing
                                                sowingData={sowingData}
                                                ipfsPaths={ipfsPaths}
                                                isUpdate={false}
                                                files={files}
                                                setSuccess={setSuccess}
                                                setLoading={setLoading}
                                                setError={setError}
                                                setTxLink={setTxLink}
                                                setImageLoading={setImageLoading}
                                            />
                                        </StepWrapper>
                                    )}
                                </ContainerMain>
                                <ContainerBottom>
                                    <ContainerButton pos="left">
                                        <Button
                                            onClick={checkIfImagesNeedToRemoved}
                                            disabled={step === 0}
                                            iconPosition="left"
                                            icon={<GoArrowLeft />}
                                            text="Nazaj"
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            onClick={showImageAlertOrProceed}
                                            disabled={
                                                selectedCrops === null ||
                                                step === 2 ||
                                                (step == 1 && sowingData.GERK == '' && !isValid)
                                            }
                                            iconPosition="right"
                                            icon={<GoArrowRight />}
                                            text="Naprej"
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                </ContainerBottom>
                            </>
                        )}
                        {success && (
                            <>
                                <SuccessMessage message={TextConstant.CREATE_SOWING_SUCCESS} txLink={txLink} />
                                {files.length != 0 && (
                                    <>
                                        {imageLoading && (
                                            <>
                                                <SuccessMessage message="Prosim počakaj da se dodajo slike" />
                                            </>
                                        )}
                                        <LoadingAnimationWrapper loading={imageLoading}>
                                            <SuccessMessage message={TextConstant.IMAGE_ADD_SOWING_SUCCESS} />
                                        </LoadingAnimationWrapper>
                                    </>
                                )}
                                {files.length == 0 && (
                                    <>
                                        {imageLoading && (
                                            <>
                                                <SuccessMessage message="Prosim počakaj da se dodajo opisi" />
                                            </>
                                        )}
                                        <LoadingAnimationWrapper loading={imageLoading}>
                                            <SuccessMessage message={TextConstant.IMAGE_ADD_SOWING_SUCCESS} />
                                        </LoadingAnimationWrapper>
                                    </>
                                )}
                                <ContainerBottom>
                                    <ContainerButton pos="left">
                                        <Button
                                            text="Dodaj še eno"
                                            onClick={() => window.location.reload()}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            text="Pojdi na seznam"
                                            onClick={() => history.push('/sowingupdate')}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            text="Pojdi na začetek"
                                            onClick={() => history.push('/')}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                </ContainerBottom>
                            </>
                        )}
                    </>
                )}
            </HarvestWrapper>
        </LoadingAnimationWrapper>
    );
};

export default Sowing;

const HarvestWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
    @media screen and (max-width: 768px) {
        margin: 0 0 0 0;
    }
`;

const StepWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;

    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
