import { BlobProvider, Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { formatDateToString } from 'helpers/helper-functions';
import * as React from 'react';
import {
    FetchedVCDataMulti,
    FetchedVCDataMultiNew,
    InvoiceProductTableData,
    SelectedDeliveryNoteItems,
    SelectedItemForInvoice,
    Subject,
} from 'types/Types';

export interface DocumentProps {
    selectableItems: SelectedDeliveryNoteItems[];
}

export const DeliveryNoteAdditionalData: React.FC<DocumentProps> = ({ selectableItems }) => {
    //const member: ApiData = data[0];

    if (selectableItems.length != 0) {
        //console.log('ST PREVZ', selectebleItems[0]);
        //console.log('ST PREVZ 22', invoiceData);
        return (
            <Document title={new Date().toISOString().substring(0, 10) + '_Zbirni_Izpis'}>
                {selectableItems.map((row: SelectedDeliveryNoteItems, p) => (
                    <Page size="A4" style={[styles.font, styles.page]} key={'page' + p}>
                        <View>
                            <Text style={styles.title}>Dobavnica</Text>
                            <Text style={styles.normalText}>
                                Številka dobavnice:
                                {new Date(row.deliveryNoteData.data.timestamp).getFullYear() + '_' + row.st_prevzemnice}
                            </Text>
                            <Text style={styles.normalText}>Sestavil: {row.deliveryNoteData.data.creator.name}</Text>
                            <Text style={styles.normalText}>
                                Prejel:
                                {row.deliveryNoteData.data.kov == '60769e0726acb7ab3e2f4c00'
                                    ? ' ZELENA TOČKA TRANS, Z.O.O.'
                                    : ' '}
                            </Text>
                            <Text style={styles.normalText}>
                                Dne: {new Date(row.deliveryNoteData.data.timestamp).toISOString()}
                            </Text>
                            <Text style={styles.normalText}>Številke prevzemnic: {row.st_prevzemnice}</Text>
                            <Text style={styles.normalText}>{'Poreklo: Slovensko              Razred: 2'}</Text>
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={[styles.tableColHeader, styles.tableCol1Width]}>
                                        <Text style={styles.tableCellHeader}>Šifra</Text>
                                    </View>
                                    <View style={[styles.tableColHeader, styles.tableCol11Width]}>
                                        <Text style={styles.tableCellHeader}>Naziv blaga</Text>
                                    </View>
                                    <View style={[styles.tableColHeader, styles.tableCol2Width]}>
                                        <Text style={styles.tableCellHeader}>EM</Text>
                                    </View>
                                    <View style={[styles.tableColHeader, styles.tableCol4Width]}>
                                        <Text style={styles.tableCellHeader}>Količina</Text>
                                    </View>
                                    {/*<View style={[styles.tableColHeader, styles.tableCol3Width]}>
                                        <Text style={styles.tableCellHeader}>Cena/EM brez DDV</Text>
                                    </View>
                                    <View style={[styles.tableColHeader, styles.tableCol5Width]}>
                                        <Text style={styles.tableCellHeader}>Vrednost brez DDV</Text>
                                    </View>
                                    {/*<View style={[styles.tableColHeader, styles.tableCol6Width]}>
                                        <Text style={[styles.tableCellHeaderHalfTop, styles.halfCell]}>Rabat</Text>
                                        <View style={[styles.tableCellHeaderHalfBottom, styles.halfCell, styles.rowFlex]}>
                                            <Text style={styles.tableTextAlignLeft}>%</Text>
                                            <Text style={styles.tableTextAlignRight}>znesek</Text>
                                        </View>
                                    </View>
                                    <View style={[styles.tableColHeader, styles.tableCol7Width]}>
                                        <Text style={[styles.tableCellHeaderHalfTop, styles.halfCell]}>DDV</Text>
                                        <View style={[styles.tableCellHeaderHalfBottom, styles.halfCell, styles.rowFlex]}>
                                            <Text style={styles.tableTextAlignLeft}>%</Text>
                                            <Text style={styles.tableTextAlignRight}>znesek</Text>
                                        </View>
                                    </View>
                                    <View style={[styles.tableColHeader, styles.tableCol8Width]}>
                                        <Text style={styles.tableCellHeader}>Skupaj</Text>
                                    </View>*/}
                                </View>
                                {row.deliveryNoteData.data.subject.map((row: Subject, subj) => (
                                    <View key={subj}>
                                        <View
                                            style={[
                                                styles.tableRow,
                                                //row.sumPriceWithoutVAT == 0 ? styles.redBackground : styles.normalBack,
                                            ]}
                                        >
                                            <View style={styles.tableCol1Width}>
                                                <Text style={styles.tableCell}>{row.crop._id}</Text>
                                            </View>
                                            <View style={styles.tableCol11Width}>
                                                <Text style={styles.tableCell}>{row.crop.name}</Text>
                                            </View>
                                            <View style={styles.tableCol2Width}>
                                                <Text style={styles.tableCell}>{row.crop.enota}</Text>
                                            </View>
                                            <View style={styles.tableCol3Width}>
                                                <Text style={[styles.tableCell, styles.tableTextAlignOnlyRight]}>
                                                    {row.quantity.toFixed(1)}
                                                </Text>
                                            </View>
                                            {/*<View style={styles.tableCol4Width}>
                                                <Text style={[styles.tableCell, styles.tableTextAlignOnlyRight]}>
                                                    {row.description}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCol5Width}>
                                                <Text style={[styles.tableCell, styles.tableTextAlignOnlyRight]}>
                                                    {row.date}
                                                </Text>
                                            </View>
                                            {/*<View style={[styles.tableCol6Width, styles.rowFlex]}>
                                                <Text style={[styles.tableCell, styles.tableTextAlignLeft]}>
                                                    {Number.parseFloat('0').toFixed(2)}
                                                </Text>
                                                <Text style={[styles.tableCell, styles.tableTextAlignRight]}>
                                                    {Number.parseFloat('0').toFixed(2)}
                                                </Text>
                                            </View>
                                            <View style={[styles.tableCol7Width, styles.rowFlex]}>
                                                <Text style={[styles.tableCell, styles.tableTextAlignLeft]}>
                                                    {row.VATpercent.toFixed(1)}
                                                </Text>
                                                <Text style={[styles.tableCell, styles.tableTextAlignRight]}>
                                                    {row.VATvalue.toFixed(2)}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCol8Width}>
                                                <Text style={[styles.tableCell, styles.tableTextAlignOnlyRight]}>
                                                    {row.sumPrice.toFixed(2)}
                                                </Text>
                                            </View>*/}
                                        </View>
                                    </View>
                                ))}
                                {/*<View style={[styles.tableRow, styles.sumTableRow]}>
                                    <View style={styles.tableCol1Width}>
                                        <Text style={styles.tableCell}></Text>
                                    </View>
                                    <View style={styles.tableCol11Width}>
                                        <Text style={styles.tableCell}></Text>
                                    </View>
                                    <View style={styles.tableCol2Width}>
                                        <Text style={styles.tableCell}></Text>
                                    </View>
                                    <View style={styles.tableCol3Width}>
                                        <Text style={[styles.tableCell, styles.tableTextAlignOnlyRight]}>Skupaj</Text>
                                    </View>
                                    <View style={styles.tableCol4Width}>
                                        <Text style={[styles.tableCell, styles.tableTextAlignOnlyRight]}>
                                            lalajdfksglčjdsfglksfjglkčsdf
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol5Width}>
                                        <Text style={[styles.tableCell, styles.tableTextAlignOnlyRight]}>
                                            testni test
                                        </Text>
                                    </View>
                                    {/*<View style={[styles.tableCol6Width, styles.rowFlex]}>
                                        <Text style={[styles.tableCell, styles.tableTextAlignLeft]}></Text>
                                        <Text style={[styles.tableCell, styles.tableTextAlignRight]}></Text>
                                    </View>
                                    <View style={[styles.tableCol7Width, styles.rowFlex]}>
                                        <Text style={[styles.tableCell, styles.tableTextAlignLeft]}></Text>
                                        <Text style={[styles.tableCell, styles.tableTextAlignRight]}>
                                            {VATvalueSUM.toFixed(2)}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol8Width}>
                                        <Text style={[styles.tableCell, styles.tableTextAlignOnlyRight]}>
                                            {sumPriceSUM.toFixed(2)}
                                        </Text>
                                    </View>
                                </View>*/}
                            </View>
                        </View>
                        <Text
                            style={styles.pageNumber}
                            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                            fixed
                        />
                    </Page>
                ))}
            </Document>
        );
    } else
        return (
            <Document title="test">
                <Page size="A4">
                    <View>
                        <Text>This Pdf could not be generated</Text>
                    </View>
                </Page>
            </Document>
        );
};

export default DeliveryNoteAdditionalData;

const BORDER_COLOR = '#bfbfbf';
const BORDER_STYLE = 'solid';
/*const COL1_WIDTH = 7; // šifra in naziv blaga
const COL11_WIDTH = 28; // šifra in naziv blaga
const COL2_WIDTH = 5; // EM
const COL3_WIDTH = 10; // cena/EM
const COL4_WIDTH = 10; //količina
//second half table 40 %
const COL5_WIDTH = 10; // vrednost brez ddv
const COL6_WIDTH = 11; //Rabat %
const COL7_WIDTH = 11; //DDV %
const COL8_WIDTH = 8; //Skupaj*/

//second option
const COL1_WIDTH = 7; // šifra in naziv blaga
const COL11_WIDTH = 43; // šifra in naziv blaga
const COL2_WIDTH = 10; // EM
const COL3_WIDTH = 40; // cena/EM
const COL4_WIDTH = 40; //količina
//second half table 40 %
const COL5_WIDTH = 20; // vrednost brez ddv
const COL6_WIDTH = 0; //Rabat %
const COL7_WIDTH = 0; //DDV %
const COL8_WIDTH = 0; //Skupaj

//const COLN_WIDTH = (100 - 33) / 4;
export const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    font: { fontFamily: 'Roboto' },
    title: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontWeight: 800,
        marginBottom: 10,
    },
    normalText: {
        fontSize: 12,
        fontFamily: 'Roboto',
        fontWeight: 500,
        marginBottom: 5,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    table: {
        display: 'flex',
        width: '100%',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 1,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row',
    },
    tableColHeader: {
        color: 'black',
        backgroundColor: 'white',
        height: 36,
        borderBottomWidth: 2,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
    },
    tableCol1Width: {
        width: COL1_WIDTH + '%',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
    },
    tableCol11Width: {
        width: COL11_WIDTH + '%',
        borderRightWidth: 1,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
    },
    tableCol2Width: {
        width: COL2_WIDTH + '%',
        borderRightWidth: 1,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
    },
    tableCol3Width: {
        width: COL3_WIDTH + '%',
        borderRightWidth: 1,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
    },
    tableCol4Width: {
        width: COL4_WIDTH + '%',
        borderRightWidth: 1,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
    },
    tableCol5Width: {
        width: COL5_WIDTH + '%',
        borderRightWidth: 1,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
    },
    tableCol6Width: {
        width: COL6_WIDTH + '%',
        borderRightWidth: 1,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
    },
    tableCol7Width: {
        width: COL7_WIDTH + '%',
        borderRightWidth: 1,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
    },
    tableCol8Width: {
        width: COL8_WIDTH + '%',
        borderRightWidth: 1,
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 9,
        fontWeight: 800,
    },
    tableCellHeaderHalfTop: {
        marginTop: 5,
        textAlign: 'center',
        fontSize: 9,
        fontWeight: 800,
    },
    tableCellHeaderHalfBottom: {
        marginBottom: 5,
        marginLeft: 5,
        marginRight: 5,
        fontSize: 8,
        fontWeight: 800,
    },
    rowFlex: {
        flexDirection: 'row',
    },
    tableTextAlignLeft: {
        width: '30%',
        textAlign: 'left',
    },
    tableTextAlignRight: {
        width: '70%',
        textAlign: 'right',
    },
    tableTextAlignOnlyRight: {
        textAlign: 'right',
    },
    tableCell: {
        margin: 2,
        fontSize: 7,
    },
    halfCell: {
        height: '50%',
        //width: '100%',
    },
    sumTableRow: {
        backgroundColor: 'yellow',
        fontWeight: 600,
    },
    redBackground: {
        backgroundColor: '#ffb3b3',
    },
    normalBack: {
        backgroundColor: 'white',
    },
});
const fontSrc = 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf';
Font.register({ family: 'Oswald', src: fontSrc });
Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
            fontWeight: 300,
        },
        {
            src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
            fontWeight: 400,
        },
        {
            src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
            fontWeight: 500,
        },
        {
            src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
            fontWeight: 600,
        },
    ],
});
Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
});
