import { FetchedVCDataMulti } from './Types';

export type DataTableDataRow = string[];

export type DataTableDataSet = DataTableDataRow[];

export type DatatablesColumn = {
    title: string;
    className?: string;
    width?: string;
};

export type DatatablesColumns = DatatablesColumn[];

export const PICKUP_NOTE_DATATABLE_COLUMNS: DatatablesColumns = [
    { title: '', width: '5%' },
    { title: 'VASCO prevzemnica', width: '5%' },
    { title: 'Datum', width: '10%' },
    { title: 'Dobavitelj', width: '30%' },
    { title: 'Prevzemnik', width: '30%' },
    { title: 'Block Chain', width: '10%' },
    //{ title: 'DobavnicaProof', width: '0%' },
];
