import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Crop, HarvestData, HarvestLoadedFiles, LoadedFiles, SowingData } from 'types/Types';
import { Button } from 'ui/atoms/Button';
import ConfirmHarvest from 'ui/organisms/ConfirmHarvest/ConfirmHarvest';
import CreateHarvest from 'ui/organisms/CreateHarvest/CreateHarvest';
import SearchCrops from 'ui/organisms/SearchCrops/SearchCrops';
import axios from 'axios';
import { API_ADDRESS } from 'config/config';
import useStep from 'hooks/useStep';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { GoArrowRight, GoArrowLeft } from 'react-icons/go';
import { useAuth } from 'contexts/AuthContext';
import SuccessMessage from 'ui/molecules/SuccessMessage/SuccessMessage';
import { format, parse } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { StyledLink } from 'ui/atoms/StyledLink';
import SearchSowings from 'ui/organisms/SearchSowings/SearchSowings';
import { Title } from 'ui/atoms/Title';
import { OverviewFinishedSowings } from 'ui/organisms/OverviewFinishedSowings';
import SearchSowingsMulti from 'ui/organisms/SearchSowingsMulti/SearchSowingsMulti';
import { HARVEST_CREATE_DESCRIPTION_OPTIONS } from 'config/custom-constants';
import { TextConstant } from '../../../config/text-constant';

const initialStateSowingUpdateData: SowingData = {
    crop: [],
    location: '',
    GERK: '',
    status: 0,
    comment: '',
    date: format(Date.now(), 'yyyy-MM-dd'),
    place: '',
    ipfsAddress: '',
    drawerIDs: [],
};

const initialStateHarvestData: HarvestData = {
    crop: null,
    quantity: 0.0,
    description: '',
    unit: '',
    date: format(Date.now(), 'yyyy-MM-dd'),
    proof: '',
};

const Harvest: React.FC = () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const { step, stepInc, stepDec } = useStep();
    const [crops, setCrops] = useState<Crop[]>([]);
    const [selected, setSelected] = useState<string | null>(null);
    const [sowingDatas, setSowing] = useState<SowingData[]>([]);
    const [sowingData, setSowingData] = useState<SowingData>(initialStateSowingUpdateData);
    const [selectedSowing, setSelectedSowing] = useState<SowingData[]>([]);
    const [harvestDataArray, setHarvestDataArray] = useState<HarvestData[]>([]);
    const [harvestDataArrayForConfirm, setHarvestDataArrayForConfirm] = useState<HarvestData[]>([]);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();
    const [ipfsPaths, setIpfsPaths] = useState<string[]>([]);
    const [txLinks, setTxLinks] = useState<string[]>([]);
    const [disableStep1, setDisableStep1] = useState<boolean>(true);
    const [imageLoading, setImageLoading] = useState<boolean>(false);

    const getSowing = useCallback(async () => {
        setLoading(true);

        axios
            .get(`${API_ADDRESS}/sowing?status=1`, { headers: { Authorization: `Bearer ${currentUser.token}` } })
            .then((res) => {
                console.log('GET sowing response: ', res);
                const sowingArray: SowingData[] = [];
                for (const data of res.data) {
                    console.log('Sowind data ', data);
                    const sowingObject: SowingData = data.vc.data.subject;
                    sowingObject.ipfsAddress = data.vc.ipfsAddress;
                    sowingObject.status = data.vc.data.status;
                    sowingObject.drawerIDs = data.dataIds;
                    //getSowingByIpfS(data.vc.ipfsAddress);
                    sowingArray.push(sowingObject);
                }
                sowingArray?.sort((a, b) => b.date.localeCompare(a.date));
                console.log('Response from GET sowing', sowingArray);
                setSowing(sowingArray);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }, [sowingDatas]);

    useEffect(() => {
        getSowing();
    }, []);

    useEffect(() => {
        console.log('Effect for change in HarvestDataArray: ', harvestDataArray);
        setDisableStep1(true);
        let disabled = false;
        harvestDataArray.forEach((harvestData) => {
            if (harvestData.description === '') {
                disabled = true;
            }
            if (harvestData.quantity < 0 || isNaN(harvestData.quantity)) {
                disabled = true;
            }
        });
        const harvestDataForConfirm: HarvestData[] = [];
        harvestDataArray.forEach((harvestData) => {
            if (harvestData.description !== '' && harvestData.quantity > 0) {
                harvestDataForConfirm.push(harvestData);
                console.log('HARVEST TO ADD', harvestData);
            }
        });
        console.log('HARVEST TO ADD LENGHT', harvestDataForConfirm.length);
        console.log('HARVEST TO ADD CHECK', step == 1 && harvestDataArrayForConfirm.length >= 1);
        setHarvestDataArrayForConfirm(harvestDataForConfirm);
        console.log('Step is disabled : ', disabled);
        setDisableStep1(disabled);
    }, [harvestDataArray]);

    useEffect(() => {
        if (selectedSowing != undefined) {
            setHarvestDataArray([]);
            for (const sowingItem of selectedSowing) {
                for (const crop of sowingItem.crop) {
                    setHarvestDataArray((oldArray) => [
                        ...oldArray,
                        {
                            crop: crop,
                            quantity: 0.0,
                            description: HARVEST_CREATE_DESCRIPTION_OPTIONS[0].value,
                            unit: '',
                            date: format(Date.now(), 'yyyy-MM-dd'),
                            proof: sowingItem.ipfsAddress,
                            loadedFiles: [],
                        },
                    ]);
                }
            }
        }
    }, [selectedSowing]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <HarvestWrapper>
                {!error && (
                    <>
                        {!success && (
                            <>
                                <ContainerMain>
                                    {step === 0 && (
                                        <StepWrapper>
                                            <Title text="Spravilo" subtext={TextConstant.CHOOSE_SOWING} />
                                            <SearchSowingsMulti
                                                onSelectionChanged={setSelectedSowing}
                                                sowings={sowingDatas}
                                                selected={selectedSowing}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 1 && (
                                        <StepWrapper>
                                            <Title text="Spravilo" subtext="Izpolni podatke o spravilu" />
                                            <CreateHarvest
                                                harvestDatas={harvestDataArray}
                                                selectedSowings={selectedSowing}
                                                setHarvestDatas={setHarvestDataArray}
                                                setIpfsPaths={setIpfsPaths}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 2 && (
                                        <StepWrapper>
                                            <Title text="Spravilo" subtext="Preglej in potrdi" />
                                            <ConfirmHarvest
                                                harvestData={harvestDataArrayForConfirm}
                                                ipfsPaths={ipfsPaths}
                                                setSuccess={setSuccess}
                                                setLoading={setLoading}
                                                setError={setError}
                                                setTxLinks={setTxLinks}
                                            />
                                        </StepWrapper>
                                    )}
                                </ContainerMain>
                                <ContainerBottom>
                                    <ContainerButton pos="left">
                                        <Button
                                            onClick={stepDec}
                                            disabled={step === 0}
                                            iconPosition="left"
                                            icon={<GoArrowLeft />}
                                            text="Nazaj"
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            onClick={stepInc}
                                            disabled={
                                                selectedSowing.length <= 0 ||
                                                step === 2 ||
                                                (step == 1 && harvestDataArrayForConfirm.length < 1)
                                            }
                                            iconPosition="right"
                                            icon={<GoArrowRight />}
                                            text="Naprej"
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                </ContainerBottom>
                            </>
                        )}
                        {success && (
                            <>
                                <Div>
                                    <span>Uspešno ustvarjena spravila </span>
                                    {txLinks.map((link) => (
                                        <StyledLink href={link} target="_blank" rel="noopener noreferrer" key={link}>
                                            Blockchain Explorer
                                        </StyledLink>
                                    ))}
                                </Div>
                                <Button text={'Pojdi na začetek'} onClick={() => history.push('/')} btnType="primary" />
                            </>
                        )}
                    </>
                )}
            </HarvestWrapper>
        </LoadingAnimationWrapper>
    );
};

export default Harvest;

const HarvestWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;

    @media screen and (max-width: 768px) {
        margin: 0px;
    }
`;

const StepWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;

    @media screen and (max-width: 768px) {
        margin: 5px;
    }
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;

    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;

const Div = styled.div`
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
`;
