import axios from 'axios';
import { Button } from 'ui/atoms/Button';
import React, { ReactElement } from 'react';
import { HarvestData, HarvestLoadedFiles, LoadedFiles } from 'types/Types';
import { API_ADDRESS } from 'config/config';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { useAuth } from 'contexts/AuthContext';
import styled from 'styled-components';
import SuccessMessage from 'ui/molecules/SuccessMessage/SuccessMessage';
import { Title } from 'ui/atoms/Title';
import { useCallback } from '@storybook/addons';
import { Toast } from 'ui/atoms/Toast';
import { rootCertificates } from 'tls';
import { render } from 'react-dom';
import { showToast } from 'ui/atoms/Toast/Toast';
import { useHistory } from 'react-router-dom';

type ConfirmHarvestProps = {
    harvestData: HarvestData[];
    ipfsPaths: string[];
    setSuccess: (data: boolean) => void;
    setLoading: (data: boolean) => void;
    setError: (data: string) => void;
    setTxLinks: React.Dispatch<React.SetStateAction<string[]>>;
};

type DataVC1 = {
    _id: string;
    recipient: null;
    description: string;
    quantity: number;
    unit: string;
    date: string;
    images_ipfs: string[];
    proof: string;
};

const ConfirmHarvest = ({
    harvestData,
    ipfsPaths,
    setSuccess,
    setLoading,
    setError,
    setTxLinks,
}: ConfirmHarvestProps): ReactElement => {
    const { currentUser } = useAuth();
    const history = useHistory();

    const confirmHarvestHandler = async () => {
        // TODO: Validate data before sending
        history.push('/home');

        //setLoading(true);
        const txLinkArray: string[] = [];
        for (const item of harvestData) {
            if (item.loadedFiles != undefined && item.loadedFiles.length > 0) {
                try {
                    const imagesAdded = await sendImages(item.loadedFiles);
                    const link = await confirmHarvest(item, imagesAdded);
                    txLinkArray.push(link);
                } catch (error) {
                    console.log(error);
                    showToast({
                        position: 'top-right',
                        message: 'Neuspešno dodajanje spravila: ',
                        type: 'danger',
                    });
                }
            } else {
                try {
                    const link = await confirmHarvest(item, []);
                    txLinkArray.push(link);
                } catch (error) {
                    console.log(error);
                    showToast({
                        position: 'top-right',
                        message: 'Neuspešno dodajanje spravila: ',
                        type: 'danger',
                    });
                }
            }
        }
        showToast({
            position: 'top-right',
            message: 'Uspešno dodano spravilo: ',
            type: 'success',
            BCtxHash: txLinkArray[0],
        });
        console.log('Transaction link array: ', txLinkArray);
        //setTxLinks(txLinkArray);
        //setLoading(false);
        //setSuccess(true);
    };

    const sendImages = async (files: LoadedFiles[]) =>
        new Promise<string[]>((resolve, reject) => {
            const form = new FormData();
            //setLoading(true);

            for (const image of files) {
                form.append('images_ipfs', image.data);
            }

            axios
                .post(`${API_ADDRESS}/vc/images`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    resolve(res.data.ipfsPaths);
                    /* confirmHarvest({
                        _id: harvestData.crop!._id,
                        recipient: null,
                        description: harvestData.description,
                        quantity: harvestData.quantity,
                        unit: harvestData.crop!.enota,
                        date: harvestData.date,
                        images_ipfs: ipfsPaths.length > 0 ? ipfsPaths : [],
                        proof: harvestData.proof,
                    });*/
                    //setSuccess(true);
                })
                .catch((err) => reject(err));
            //.finally(() => setLoading(false));
        });

    const confirmHarvest = async (harvestdataIn: HarvestData, ipfsPaths: string[]) =>
        new Promise<string>((resolve, reject) => {
            const dataT: DataVC1 = {
                _id: harvestdataIn.crop!._id,
                recipient: null,
                description: harvestdataIn.description,
                quantity: harvestdataIn.quantity,
                unit: harvestdataIn.crop!.enota,
                date: harvestdataIn.date,
                images_ipfs: ipfsPaths.length > 0 ? ipfsPaths : [],
                proof: harvestdataIn.proof,
            };
            //setLoading(true);
            axios
                .post(
                    `${API_ADDRESS}/vc/create/vc1`,
                    {
                        data: dataT,
                    },
                    { headers: { Authorization: `Bearer ${currentUser.token}` } },
                )
                .then((res) => {
                    resolve(res.data.txLink);
                    //setTxLink(res.data.txLink);
                    //setSuccess(true);
                })
                .catch((err) => reject(err));
            //.then(() => {
            //setLoading(false);
            //});
        });

    return (
        <ConfirmHarvestWrapper key={'rwa'}>
            {harvestData.map((harvestDataItem) => (
                <div key={harvestDataItem.proof}>
                    <div>
                        <div>
                            <strong>Naziv:</strong> {harvestDataItem.crop?.name}
                        </div>
                        <div>
                            <strong>Opis:</strong> {harvestDataItem.description}
                        </div>
                        <div>
                            <strong>Količina:</strong> {harvestDataItem.quantity} {harvestDataItem.crop?.enota}
                        </div>
                        <div>
                            <strong>Datum spravila:</strong> {harvestDataItem.date}
                        </div>
                        <div>
                            {harvestDataItem.loadedFiles?.map((files) => (
                                <div key={files.preview}>
                                    <strong>Slike:</strong>
                                    <Image>
                                        <img src={files.preview} alt="" key={files.preview} />
                                    </Image>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
            <BottomWrapper>
                <Button onClick={confirmHarvestHandler} text="Potrdi" btnType="primary-outline" />
            </BottomWrapper>
        </ConfirmHarvestWrapper>
    );
};

export default ConfirmHarvest;

const ConfirmHarvestWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const BottomWrapper = styled.div`
    margin-top: auto;
`;

const Image = styled.div`
    display: flex;
    width: 200px;
    height: 200px;
    margin-bottom: 5px;
`;
