import React from 'react';
import styled, { css } from 'styled-components';

export type InputLabelProps = {
    focused: boolean;
};

const InputLabel = styled.label<InputLabelProps>`
    pointer-events: none;
    color: var(--color);
    position: absolute;
    transition: all 0.2s ease;
    z-index: 500;
    left: 14px;
    top: 14px;

    ${({ focused }) =>
        focused &&
        css`
            font-size: 0.8rem;
            transform: translateY(-24px) translateX(-6px);
            z-index: 501;
            background: #fff;
            padding: 0 8px;
        `}
`;

export default InputLabel;
