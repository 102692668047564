import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { User } from 'types/Types';
import SelectableListItemUser from 'ui/molecules/SelectableListItem/User/SelectableListItemUser';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

export type ListUsersProps = {
    users: User[];
    selected: string | null;
    onSelectionChanged: React.Dispatch<React.SetStateAction<string | null>>;
};

const ItemRenderer = ({ style, index, data }: ListChildComponentProps) => {
    const item = data.items[index];

    return (
        <SelectableListItemUser
            key={index}
            user={item}
            selected={data.selected}
            onSelectionChanged={data.onSelectionChanged}
            style={style}
        />
    );
};

export const ListUsers = ({ users, selected, onSelectionChanged }: ListUsersProps): ReactElement => {
    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    itemData={{ items: users, selected: selected, onSelectionChanged: onSelectionChanged }}
                    itemSize={90}
                    height={height}
                    width={width}
                    itemCount={users.length}
                >
                    {ItemRenderer}
                </List>
            )}
        </AutoSizer>
    );
};

export default ListUsers;

const List = styled(FixedSizeList)`
    user-select: none;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #d8dbe0;
    border-top: 1px solid #d8dbe0;
`;
