import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { LoadedFiles, SowingData } from 'types/Types';
import { Button } from 'ui/atoms/Button';
import ConfirmHarvest from 'ui/organisms/ConfirmHarvest/ConfirmHarvest';
import CreateHarvest from 'ui/organisms/CreateHarvest/CreateHarvest';
import SearchSowings from 'ui/organisms/SearchSowings/SearchSowings';
import axios from 'axios';
import { API_ADDRESS } from 'config/config';
import useStep from 'hooks/useStep';
import { useApiState } from 'hooks/useApiState';
import { LoadingAnimationWrapper } from 'ui/molecules/LoadingAnimationWrapper';
import { GoArrowRight, GoArrowLeft } from 'react-icons/go';
import { useAuth } from 'contexts/AuthContext';
import SuccessMessage from 'ui/molecules/SuccessMessage/SuccessMessage';
import { format, parse } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { StyledLink } from 'ui/atoms/StyledLink';
import CreateSowing from 'ui/organisms/CreateSowing/CreateSowing';
import ConfirmSowing from 'ui/organisms/ConfirmSowing/ConfirmSowing';
import { UpdateSowing } from 'ui/organisms/UpdateSowing';
import { BlockISAPIService, updateSowing } from 'services/blockis-api';
import { Title } from 'ui/atoms/Title';
import { GiConfirmed } from 'react-icons/gi';
import { AlertPopup } from 'ui/atoms/AlertPopup';
import { OverviewFinishedSowings } from 'ui/organisms/OverviewFinishedSowings';
import { TextConstant } from '../../../config/text-constant';

let initialStateSowingUpdateData: SowingData = {
    crop: [],
    location: '',
    GERK: '',
    status: 1,
    comment: '',
    date: format(Date.now(), 'yyyy-MM-dd'),
    place: '',
    ipfsAddress: '',
};

const SowingUpdate: React.FC = () => {
    const history = useHistory();
    const { currentUser } = useAuth();
    const { step, stepInc, stepDec } = useStep();
    const [sowingDatas, setSowing] = useState<SowingData[]>([]);
    const [selected, setSelected] = useState<SowingData | null>(null);
    //let [selectedSowing, setSelectedSowing] = useState<SowingData>();
    const [sowingData, setSowingUpdateData] = useState<SowingData>(initialStateSowingUpdateData);
    const { loading, error, success, setLoading, setError, setSuccess } = useApiState();
    const [ipfsPaths, setIpfsPaths] = useState<string[]>([]);
    const [files, setLoadedFiles] = useState<LoadedFiles[]>([]);
    const [txLink, setTxLink] = useState<string | null>(null);
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(true);
    const [showPictureAlert, setshowPictureAlert] = useState<boolean>(true);
    const [isValid, isAllInputValid] = useState<boolean>(false);
    //let selectedSowing: SowingData | null = null;

    const checkIfImagesNeedToRemoved = () => {
        if (step == 3) {
            setLoadedFiles([]);
        }
        stepDec();
    };

    const setSelectedSowingFromParcel = () => {
        console.log('TEst get sowing from ID:', selected);
        if (selected != null) {
            initialStateSowingUpdateData = selected;
        }
        console.log('SELECTED SOWING DATA:', initialStateSowingUpdateData);
    };

    const getSowing = useCallback(async () => {
        setLoading(true);
        axios
            .get(`${API_ADDRESS}/sowing?status=1`, { headers: { Authorization: `Bearer ${currentUser.token}` } })
            .then((res) => {
                console.log('GET sowing response: ', res);
                const sowingArray: SowingData[] = [];
                for (const data of res.data) {
                    console.log('Sowind data ', data);
                    const sowingObject: SowingData = data.vc.data.subject;
                    sowingObject.ipfsAddress = data.vc.ipfsAddress;
                    sowingObject.status = data.vc.data.status;
                    sowingObject.drawerIDs = data.dataIds;
                    //getSowingByIpfS(data.vc.ipfsAddress);
                    sowingArray.push(sowingObject);
                }
                sowingArray?.sort((a, b) => b.date.localeCompare(a.date));
                console.log('Response from GET sowing', sowingArray);
                setSowing(sowingArray);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    }, [sowingDatas]);

    const getSowingByIpfS = (ipfsPath: string) => {
        //setLoading(true);
        axios
            .get(`${API_ADDRESS}/sowing/${ipfsPath}`, { headers: { Authorization: `Bearer ${currentUser.token}` } })
            .then((res) => {
                console.log('GET sowingIPFS RESPONSE: ', res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    const updateSowingToEnd = () => {
        console.log('Update sowing to end');
        if (selected != null) {
            updateSowing({ ipfsPath: selected.ipfsAddress, status: 2 }, currentUser.token)
                .then((response) => {
                    setTxLink(response.txLink);
                    setSuccess(true);
                })
                .catch((error) => {
                    console.log('Error', error);
                });
        }
    };

    const showEndSowingAlertOrProceed = () => {
        console.log('showEndSowingAlertOrProceed');
        if (!confirmed) {
            setShowAlert(true);
        } else {
            updateSowingToEnd();
            setConfirmed(false);
        }
    };

    const showImageAlertOrProceed = () => {
        console.log('showImageAlertOrProceed');
        if (step === 2 && files.length === 0) {
            if (!confirmed) {
                setShowAlert(true);
            } else {
                setConfirmed(false);
                stepInc();
            }
        } else {
            stepInc();
        }
    };

    useEffect(() => {
        setSelectedSowingFromParcel();
    }, [selected]);

    useEffect(() => {
        setShowAlert(false);
        if (step === 0) {
            if (confirmed) {
                updateSowingToEnd();
                setConfirmed(false);
            }
        } else if (step === 2) {
            if (confirmed) {
                setConfirmed(false);
                stepInc();
            }
        }
    }, [confirmed]);

    useEffect(() => {
        getSowing();
    }, []);

    useEffect(() => {
        setSelectedSowingFromParcel();
        setSowingUpdateData({ ...initialStateSowingUpdateData });
        console.log('Sowing data UPDATED: ', sowingData);
    }, [selected]);

    return (
        <LoadingAnimationWrapper loading={loading}>
            <HarvestWrapper>
                {!error && (
                    <>
                        {!success && (
                            <>
                                <ContainerMain>
                                    {step === 0 && (
                                        <StepWrapper>
                                            <Title
                                                text={TextConstant.EXISTING_SOWING}
                                                subtext={TextConstant.CHOOSE_SOWING}
                                            />
                                            <SearchSowings
                                                onSelectionChanged={setSelected}
                                                sowings={sowingDatas}
                                                selected={selected}
                                            />
                                            <AlertPopup
                                                title={TextConstant.FINISH_SOWING_QUESTION}
                                                message={TextConstant.FINISH_SOWING_DESC}
                                                isShown={showAlert}
                                                alertType="info"
                                                confirmed={confirmed}
                                                declineButtonText="Prekliči"
                                                confirmeButtonText="Nadaljuj"
                                                onDeclined={setShowAlert}
                                                onConfirmed={setConfirmed}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 1 && (
                                        <StepWrapper>
                                            <Title text={TextConstant.EXISTING_SOWING} subtext="Zgodovina vnosov" />
                                            <OverviewFinishedSowings
                                                sowingData={sowingData}
                                                ipfsPaths={ipfsPaths}
                                                isUpdate={true}
                                                files={files}
                                                setSuccess={setSuccess}
                                                setLoading={setLoading}
                                                setError={setError}
                                                setTxLink={setTxLink}
                                                setImageLoading={setImageLoading}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 2 && (
                                        <StepWrapper>
                                            <Title
                                                text={TextConstant.EXISTING_SOWING}
                                                subtext={TextConstant.ADD_SOWING_ACT}
                                            />
                                            <UpdateSowing
                                                sowingData={sowingData}
                                                setAllInputIsValid={isAllInputValid}
                                                setSowingData={setSowingUpdateData}
                                                setIpfsPaths={setIpfsPaths}
                                                setLoadedFiles={setLoadedFiles}
                                            />
                                            <AlertPopup
                                                title={TextConstant.ADD_SOWING_IMAGE_DESC}
                                                message="Če želiti nadaljevati brez slik pritisnite Nadaljuj"
                                                isShown={showAlert}
                                                confirmed={confirmed}
                                                declineButtonText="Prekliči"
                                                confirmeButtonText="Nadaljuj"
                                                onDeclined={setShowAlert}
                                                onConfirmed={setConfirmed}
                                            />
                                        </StepWrapper>
                                    )}
                                    {step === 3 && (
                                        <StepWrapper>
                                            <Title
                                                text={TextConstant.EXISTING_SOWING}
                                                subtext="Preglejte in potrdite stanje"
                                            />
                                            <ConfirmSowing
                                                sowingData={sowingData}
                                                ipfsPaths={ipfsPaths}
                                                isUpdate={true}
                                                files={files}
                                                setSuccess={setSuccess}
                                                setLoading={setLoading}
                                                setError={setError}
                                                setTxLink={setTxLink}
                                                setImageLoading={setImageLoading}
                                            />
                                        </StepWrapper>
                                    )}
                                </ContainerMain>
                                <ContainerBottom>
                                    {step !== 0 && (
                                        <ContainerButton pos="left">
                                            <Button
                                                onClick={checkIfImagesNeedToRemoved}
                                                disabled={step === 0}
                                                hidden={step === 0}
                                                iconPosition="left"
                                                icon={<GoArrowLeft />}
                                                text="Nazaj"
                                                btnType="primary"
                                            />
                                        </ContainerButton>
                                    )}
                                    {step === 0 && (
                                        <ContainerButton pos="left">
                                            <Button
                                                onClick={() => showEndSowingAlertOrProceed()}
                                                disabled={selected === null}
                                                hidden={step === 0}
                                                iconPosition="left"
                                                icon={<GiConfirmed />}
                                                text={TextConstant.COMPLETE_SOWINGS}
                                                btnType="primary"
                                            />
                                        </ContainerButton>
                                    )}
                                    <ContainerButton pos="right">
                                        <Button
                                            onClick={() => showImageAlertOrProceed()}
                                            disabled={selected === null || step === 3 || (step === 2 && !isValid)}
                                            iconPosition="right"
                                            icon={<GoArrowRight />}
                                            text={step === 0 ? TextConstant.ADD_SOWING_ACTIVITY : 'Naprej'}
                                            btnType="primary"
                                        />
                                    </ContainerButton>
                                </ContainerBottom>
                            </>
                        )}
                        {success && (
                            <>
                                <SuccessMessage message="Uspešno dodana aktivnost" txLink={txLink} />
                                {files.length != 0 && (
                                    <>
                                        {imageLoading && (
                                            <>
                                                <SuccessMessage message="Prosim počakaj da se dodajo slike" />
                                            </>
                                        )}
                                        <LoadingAnimationWrapper loading={imageLoading}>
                                            <SuccessMessage message={TextConstant.IMAGE_ADD_SOWING_SUCCESS} />
                                        </LoadingAnimationWrapper>
                                    </>
                                )}
                                <ContainerBottom>
                                    <ContainerButton pos="left">
                                        <Button
                                            text="Dodaj še eno aktivnost"
                                            onClick={() => window.location.reload()}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            text="Pojdi na spravilo"
                                            onClick={() => history.push('/harvest')}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                    <ContainerButton pos="right">
                                        <Button
                                            text="Pojdi na začetek"
                                            onClick={() => history.push('/')}
                                            btnType="primary"
                                            disabled={imageLoading}
                                        />
                                    </ContainerButton>
                                </ContainerBottom>
                            </>
                        )}
                    </>
                )}
            </HarvestWrapper>
        </LoadingAnimationWrapper>
    );
};

export default SowingUpdate;

const HarvestWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
    @media screen and (max-width: 768px) {
        margin: 0 0 0 0;
    }
`;

const StepWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
`;

const ContainerMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const ContainerBottom = styled.div`
    display: flex;
    margin-top: 30px;
`;

const ContainerButton = styled.div<{ pos: 'left' | 'right' }>`
    width: 100%;

    ${({ pos }) =>
        (pos === 'left' &&
            css`
                margin-right: 5px;
            `) ||
        (pos === 'right' &&
            css`
                margin-left: 5px;
            `)}
`;
