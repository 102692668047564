import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FetchedVCData } from 'types/Types';
import SelectableListItemHarvest from 'ui/molecules/SelectableListItem/Harvest/SelectableListItemHarvest';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Title } from 'ui/atoms/Title';

export type ListHarvestProps = {
    fetchedHarvestData: FetchedVCData[];
    selected: string[];
    setSelected: (ipfsAddressArray: string[]) => void;
};

const ItemRenderer = ({ style, index, data }: ListChildComponentProps) => {
    const item = data.items[index];
    const active = data.selected.includes(item.ipfsAddress);

    return (
        <SelectableListItemHarvest
            key={index}
            fetchedHarvestData={item}
            selected={active}
            onClick={() => data.handleSelectionChanged(item.ipfsAddress, active)}
            style={style}
        />
    );
};

export const ListHarvest = ({ fetchedHarvestData, selected, setSelected }: ListHarvestProps): ReactElement => {
    const handleSelectionChanged = (ipfsAddress: string, active: boolean) => {
        if (active) {
            setSelected(selected.filter((e) => e !== ipfsAddress));
        } else {
            setSelected([...selected, ipfsAddress]);
        }
    };

    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    itemData={{
                        items: fetchedHarvestData,
                        selected: selected,
                        handleSelectionChanged: handleSelectionChanged,
                    }}
                    itemSize={50}
                    height={height - 50}
                    width={width}
                    itemCount={fetchedHarvestData.length}
                >
                    {ItemRenderer}
                </List>
            )}
        </AutoSizer>
    );
};

export default ListHarvest;

const List = styled(FixedSizeList)`
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #d8dbe0;
    border-top: 1px solid #d8dbe0;
    user-select: none;
`;
