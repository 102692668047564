import React, { ReactElement, useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

export type GeneratorProps = {
    ipfsPath: string;
};

const Generator = ({ ipfsPath }: GeneratorProps): ReactElement => {
    const [qrCodeData, setQrCodeData] = useState<string | null>(null);
    useEffect(() => {
        setQrCodeData(ipfsPath);
    }, [ipfsPath]);

    return (
        <QRCodeWrapper>
            {qrCodeData && <QRCode value={JSON.stringify(qrCodeData)} renderAs="svg" size={200} />}
        </QRCodeWrapper>
    );
};

export default Generator;

const QRCodeWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 10px 0;
`;
