import React, { CSSProperties } from 'react';
import { SowingData } from 'types/Types';
import ListItemSowing from 'ui/atoms/ListItem/Sowing/ListItemSowing';
import SelectableItem from 'ui/atoms/SelectableItem/SelectableItem';

export type SelectableListItemSowingProps = {
    sowing: SowingData;
    onSelectionChanged: (place: SowingData) => void;
    selected: SowingData | null; // TODO: Change to boolean if possible ?
    style?: CSSProperties;
};

const SelectableListItemSowing = ({
    sowing,
    onSelectionChanged,
    selected,
    style,
}: SelectableListItemSowingProps): JSX.Element => {
    //console.log('Selectable List Item Sowings. Sowing object from selected', sowing);
    //console.log('Selectable List Item Sowings 2. selected Object', selected);
    return (
        <SelectableItem style={style} onClick={() => onSelectionChanged(sowing)} selected={sowing === selected}>
            <ListItemSowing sowing={sowing} />
        </SelectableItem>
    );
};

export default SelectableListItemSowing;
